import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from '@lib/common/common.service';
import { ApiService } from '@lib/data/core-api.service';
import { RecaptchaActions } from '@lib/features/recaptcha/recaptcha.models';
import { RecaptchaService } from '@lib/features/recaptcha/recaptcha.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { catchError, switchMap, take } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit {

  @Input()
  title: any = 'Subscription to our newsletter';

  @Input()
  placeholder: any = 'Your email address…';

  @Input()
  typeOfSubscription: 'news-letter' | 'signup' = 'news-letter';

  formGroup!: UntypedFormGroup;

  constructor(
    private commonService: CommonService,
    private router: Router,
    private apiService: ApiService,
    private recaptchaService: RecaptchaService) { }

  ngOnInit(): void {
    this.formGroup = new UntypedFormGroup({
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
      refurl: new UntypedFormControl(''),
      category: new UntypedFormControl(''),
      'g-recaptcha-response': new UntypedFormControl('')
    });
  }

  async onSubmit() {
    if (!this.formGroup.valid) {
      return;
    }

    const subscribeRes = await this.recaptchaService.generateToken(RecaptchaActions.NewsletterSubscribe)
      .pipe((untilDestroyed(this)), (switchMap((token: any) => {
        this.formGroup.patchValue({ 'g-recaptcha-response': token });
        return this.apiService.newsletterSubscribePOST(this.formGroup.value);
      })
      ), catchError((err: any ) => {
        alert($localize`:@@clinicContactFormError:`);
        return err;
      })).toPromise();

    if (subscribeRes) {
      let url = '/mail/subscribe';
      switch (this.typeOfSubscription) {
        case 'news-letter': url = '/mail/subscribe'; break;
        case 'signup': url = '/mail/signup'; break;
        default: break;
      }
      this.router.navigate([url], { queryParams: { token: subscribeRes['email_id'], subscribed: 1 } });
    } else {
      alert($localize`:@@clinicContactFormError:`);
    }
  }


}
