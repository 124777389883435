import { Directive, ElementRef, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { AttachJsScriptsService } from '@lib/shared-core/helper/attach-js-scripts.service';

@Directive({
  selector: 'form, [hiddenInput]'
})
export class HiddenInputDirective implements OnInit {


  constructor(
    private elementRef: ElementRef,
    @Inject(PLATFORM_ID) private platformId: object,
    private attachJsScriptsService: AttachJsScriptsService
  ) {

    attachJsScriptsService.loadFormScripts();
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      const hiddenInput = document.createElement('input');
      hiddenInput.type = 'hidden';
      hiddenInput.name = 'zc_gad';
      hiddenInput.id = 'zc_gad';
      hiddenInput.value = '';

      this.elementRef.nativeElement.prepend(hiddenInput);
    }
  }

}
