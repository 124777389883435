<div class="hotels-wrapper" *ngIf="hotels && hotels.length > 0">
    <h3>{{title}}</h3>
    <div class=" mb-5 mt-3">
        <swiper-container customSwiper navigation="true" class="swiper-containers" fxFlex="auto" [config]="config" [disabled]="disabled">
            <swiper-slide *ngFor="let hotel of hotels">
                <div class="swiper-slide swiper-options-header">
                    <div fxLayout="column">
                        <div class="card container" (click)="navigateUrl(hotel.link)">
                            <div class="img-container" style="background-image: url({{hotel.photo}})"></div>
                            <div class="row">
                                <div class="col-8">
                                    <div class="name"> {{ hotel.name }}</div>
                                </div>
                                <div class="col-4 text-right">
                                    <div class="rating">
                                        <i class="fa fa-star"></i> {{ hotel.rating | number: '1.2-2'}}
                                    </div>
                                </div>
                            </div>
                            <p-scrollPanel [style]="{ width: '100%', height: '169px' }">
                                <div class="description" [innerHTML]="hotel.description_short">
                                </div>
                            </p-scrollPanel>
                            <div class="price">$ {{ hotel.price }}</div>
                        </div>
                    </div>
                </div>
            </swiper-slide>
        </swiper-container>
    </div>
</div>
