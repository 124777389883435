import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { HiddenInputDirective } from '@lib/shared-directives/hidden-input.directive';
import { CustomSeoMenubarPatchDirective } from '@lib/shared-core/directives/custom-menubar.directive';
import { CustomSwiperDirective } from '@lib/shared-directives/custom-swiper.directive';



@NgModule({
  declarations: [
    HiddenInputDirective,
    CustomSeoMenubarPatchDirective,
    CustomSwiperDirective
  ],
  imports: [
    CommonModule,
    NgOptimizedImage
  ],
  exports: [
    HiddenInputDirective,
    CustomSeoMenubarPatchDirective,
    CustomSwiperDirective,
    NgOptimizedImage
  ]
})
export class SharedDirectivesModule { }
