import { Injectable, isDevMode } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  constructor() {}

  log(message: any, obj?: any) {
    if (isDevMode()) {
      if (obj) {
        console.log(message, obj);
      } else {
        console.log('', message);
      }
    }
  }

  warn(message: any) {
    if (isDevMode()) {
      console.warn(message);
    }
  }

  error(message: any, error?: any) {
    if (isDevMode()) {
      if (error) {
        if (error.message) {
          console.error(`${message} -> ${error.message}`);
        }
      } else {
        console.error(message, error);
      }
    }
  }
}
