import { Component, OnInit } from '@angular/core';
import { ServiceWorkerVersionCheckerService } from '@lib/shared-core/helper/service-worker-version-checker.service';

@Component({
  selector: 'app-service-worker-version-alert',
  templateUrl: './service-worker-version-alert.component.html',
  styleUrls: ['./service-worker-version-alert.component.css']
})
export class ServiceWorkerVersionAlertComponent {


  constructor(
    public serviceWorkerVersionCheckerService: ServiceWorkerVersionCheckerService,
  ) {}

  applyUpdate(): void {
    this.serviceWorkerVersionCheckerService.applyUpdate();
  }

}
