import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { Carousel } from 'primeng/carousel';
import SwiperOptions from 'swiper';
import { SwiperContainer } from 'swiper/element';
import Swiper from 'swiper';
import { timer } from 'rxjs';


@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {

  type: 'photo' | any = '';
  items: any[] = [];
  private _dataCarousel!: CarouselModel;
  currentIndex: number = 0;
  clinicName: string = "";

  public config: SwiperOptions = {
    a11y: { enabled: true },
    direction: 'horizontal' as any,
    slidesPerView: 1,
    keyboard: {enabled: false} as any,
    mousewheel: {enabled: false} as any,
    scrollbar: false as any,
    navigation: true as any,
    pagination: false as any
  } as any;

  disabled: boolean = false;
  show: boolean = true;

  @Input()
  customWrapper: string = 'card';

  @Input()
  showIndicators: boolean = false;

  @Input()
  showNavigators: boolean = false;

  @Input()
  autoPlayInterval: number | any = null;

  @Input()
  hasTotalSlide: boolean = false;

  @Input()
  primaryImage: any = '';

  @Input()
  set dataCarousel(carouselData: CarouselModel) {
    if (carouselData) {
      this._dataCarousel = carouselData;
      const { type, data, clinicName } = carouselData;
      this.type = type;
      this.items = [];
      this.items = [...[], ...data];
      const hasImageWidth = carouselData && carouselData.customWidth ? '?width=' + carouselData.customWidth : '';
      if (this.items) {
        this.items = this.items.map((data: any, index) => {
          if (typeof data === 'object' && 'image' in data) {
            return {
              index,
              image: data.image + hasImageWidth,
              clinicName
            };
          } else {
              return {
            index,
            image: data + hasImageWidth,
            clinicName
          };
          }
        });

        /*
        ** Update Swiper when more images are loaded
        * */
        if ((this.items && this.items.length > 3)) {
          this.swiper?.nativeElement?.swiper.update();
        }

      }

    }
  }
  get dataCarousel(): CarouselModel {
    return this._dataCarousel;
  }

  @Output()
  onClickEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  onSwipeOrNext: EventEmitter<any> = new EventEmitter<any>();


  responsiveOptions: any[] = [];

  @ViewChild('swiper') swiper!: ElementRef<SwiperContainer>;
  constructor() { }

  ngOnInit(): void {
    Carousel.prototype.onTouchMove = () => { };
    // this.config.pagination = !this.hasTotalSlide;
  }


  mapDataToGallery() {

  }

  alerts($event: any) {
    alert($event);
  }

  public toggleKeyboardControl(): void {
    this.config.keyboard.enabled = !this.config.keyboard.enabled;
  }

  public toggleMouseWheelControl(): void {
    this.config.mousewheel.enabled = !this.config.mousewheel.enabled;
  }

  public onIndexChange(event: CustomEvent<'activeindexchange'>): void {
    const detail: Array<Swiper> = event.detail as any;

    this.currentIndex = detail[0].activeIndex;
    this.onSwipeOrNext.emit(this.currentIndex);
  }
  public onSwiperEvent(event: string): void {}

}

export interface CarouselModel {
  type: 'photo' | any | 'template';
  data: any[];
  customWidth?: any;
  clinicName?: string;
}
