import { Component, EventEmitter, Inject, Input, OnInit, Output, PLATFORM_ID } from '@angular/core';
import { WindowResizeHelper } from '@lib/shared-core/helper/window.resize.helper';
import { untilDestroyed } from '@ngneat/until-destroy';
import { fromEvent } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-drop-down-content',
  templateUrl: './drop-down-content.component.html',
  styleUrls: ['./drop-down-content.component.scss']
})
export class DropDownContentComponent implements OnInit {

  @Input()
  showPanel:boolean = false;


  @Input()
  allowSticky:boolean = false;


  notSticky: boolean = true;

  @Input()
  dropDownContent: DropDownContent[] = [
    {
      title: 'Mexico Dental Implants',
      id: 1,
      active: true
    },
    {
      title: 'Why choose mexico',
      id: 2,
      active: false
    },
    {
      title: 'Types of Dental Implant Treatment',
      id: 3,
      active: false
    }
  ];

  @Output()
  clickList = new EventEmitter<any>();

  @Output()
  isSticky = new EventEmitter<boolean>();

  isBrowser: boolean;

  constructor(
    private windowsResizeHelper: WindowResizeHelper,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  ngOnInit(): void {
    if (this.allowSticky && this.isBrowser) {
      fromEvent(window, 'scroll').subscribe(e => {
       // let res = this.windowsResizeHelper.isViewPositioned('p-content-general-id', 230);
        const bodyWrapper =  this.windowsResizeHelper.isWindowInViewPort('dropDownBodyWrapper');
        if (!bodyWrapper) {
          this.notSticky = false;
        } else {
          this.notSticky = true;
        }
        this.isSticky.emit(this.notSticky);
     });
  }
  }

  onClick(dropDownContent: DropDownContent, index?: any) {
    this.showPanel = false;
    this.dropDownContent = this.dropDownContent.map((data)=> {
      data.active = false;
      return data;
    });
    dropDownContent.active = true;
    this.clickList.emit({ content: dropDownContent, index});
  }

  togglePanel() {
    this.showPanel = !this.showPanel;
  }

}


export interface DropDownContent {
  title: any;
  id?: any;
  url?: any;
  active?: boolean,
  description?: any;
}
