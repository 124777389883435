import { Component, Input, OnInit } from '@angular/core';
import { Hotel } from '@lib/modules/clinic/clinic.models';
// import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-hotels',
  templateUrl: './hotels.component.html',
  styleUrls: ['./hotels.component.scss']
})
export class HotelsComponent implements OnInit{

  @Input()
  title: any = $localize`:@@hotels:Hotels`;

  @Input()
  hotels!: Hotel[];

  config!: any;
  disabled: boolean = false;
  constructor() {}

  ngOnInit(): void {
    this.config = this.swiperConfig();
  }

  swiperConfig(): any {
    return {
      a11y: { enabled: true },
      updateOnWindowResize: true,
      direction: 'horizontal',
      slidesPerView: 4,
      keyboard: false,
      mousewheel: false,
      scrollbar: false,
      navigation: true,
      pagination: false,
      breakpoints: {
        120: {
          slidesPerView: 1.1,
          spaceBetween: 20
        },
        640: {
          slidesPerView: 2,
          spaceBetween: 20
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 40
        }
      }
    };
  }

  navigateUrl(link: any) {
    if (link) {
      window. open(link, '_blank');
    }
  }

}
