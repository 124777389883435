import { Component, OnInit } from '@angular/core';
// import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-how-to-book',
  templateUrl: './how-to-book.component.html',
  styleUrls: ['./how-to-book.component.scss']
})
export class HowToBookComponent implements OnInit {

  howTos: WidgetHowToBookData[] = [];
  config!: any;
  disabled: boolean = false;
  constructor() {}

  swiperConfig(): any {
    return {
      a11y: { enabled: true },
      updateOnWindowResize: true,
      direction: 'horizontal',
      slidesPerView: 3,
      keyboard: false,
      mousewheel: false,
      scrollbar: false,
      navigation: true,
      pagination: false,
      breakpoints: {
        120: {
          slidesPerView: 1.3,
          spaceBetween: 20
        },
        640: {
          slidesPerView: 2,
          spaceBetween: 20
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 40
        }
      }
    };
  }

  public toggleKeyboardControl(): void {
    this.config.keyboard = !this.config.keyboard;
  }

  public toggleMouseWheelControl(): void {
    this.config.mousewheel = !this.config.mousewheel;
  }

  public onIndexChange(index: number): void { }
  public onSwiperEvent(event: string): void { }

  ngOnInit(): void {
    this.config = this.swiperConfig();
    this.howTos = [
      {
        title: $localize`:@@emailUs:Email Us`,
        info: $localize`:@@emailUsDescription:Click here to email us`,
        icon: 'envelope.svg',
        link: '/contact'
      },
      {
        title: $localize`:@@onlineChat:Use Our Online Chat Services`,
        info: $localize`:@@onlineChatDescription:Please click on 'Chat With Us`,
        icon: 'chat.svg'
      },
      {
        title: $localize`:@@bookOnline:Book Online`,
        info: $localize`:@@bookOnlineDescription: Use our website to find your doctor, compare prices, check out
        clinics and read reviews. Book your appointment with absolute
        confidence with Dental Departures.`,
        icon: 'global.svg'
      }
    ];
  }
}

export interface WidgetHowToBookData {
  title: any;
  info: any;
  icon: any;
  link?: any;
}

