<div class="doctors-wrapper" *ngIf="clinicDoctors">
    <h3>{{title}}</h3>
    <div class=" mb-5 mt-3">
        <swiper-container customSwiper navigation="true" class="swiper-containers" fxFlex="auto" [config]="config" [disabled]="disabled">
            <swiper-slide *ngFor="let clinicDoctor of clinicDoctors">
                <div class="swiper-slide swiper-options-header">
                    <div fxLayout="column">
                        <div class="card">
                            <app-book-image [docInfo]="clinicDoctor" [isBookExpanded]="true" [hasOtherInfo]="true"
                                [userImage]="( clinicDoctor.photo )"></app-book-image>
                        </div>
                    </div>
                </div>
            </swiper-slide>
        </swiper-container>
    </div>
</div>
