<div class="contact-form-wrapper">
    <h1 i18n="@@contactClinic"> Contact Clinic </h1>
    <div class="success-box" *ngIf="this.isSubmittedSuccessfully" i18n="@@clinicContactSuccessfulSubmit">
        <p>
            Thank you. We've received your message.
        </p>
        <a routerLink="/dentist/{{ this.clinic.slug }}" rel="nofollow">Close and go back to the dentist</a>
    </div>
    <div class="" *ngIf="this.isLoading">
        <div class="contact-form">
            <div class="general-form">
                <p-skeleton [height]="'25px'"></p-skeleton>
            </div>
            <div class="general-form">
                <p-skeleton [height]="'25px'"></p-skeleton>
            </div>
            <div class="general-form">
                <p-skeleton [height]="'25px'"></p-skeleton>
            </div>
            <div class="general-form">
                <p-skeleton [height]="'25px'"></p-skeleton>
            </div>
            <div class="row">
                <div class="col-12">
                    <p-skeleton [height]="'25px'"></p-skeleton>
                </div>

            </div>
        </div>
    </div>

    <p-toast position="bottom-center" key="bcContact"></p-toast>
    <div *ngIf="this.clinic && !this.isSubmittedSuccessfully">
        <form class="contact-form" hiddenInput [formGroup]="clinicContactForm" (ngSubmit)="onSubmit()" *ngIf="clinicContactForm">
            <div class="general-form">
                <label for="name" i18n="@@formLabelName">Name</label>
                <div>
                    <input pInputText class="w-100" type="text" name="name" id="name" formControlName="name" />
                    <div class="control__error"
                        *ngIf="this.clinicContactForm.get('name')?.touched && this.clinicContactForm.get('name')?.errors">
                        <span *ngIf="this.clinicContactForm.get('name')?.errors['required']"
                            i18n="@@formInputValidationRequiredField">This value is required</span>
                    </div>
                </div>
            </div>

            <div class="general-form">
                <label for="email" i18n="@@formLabelEmail">Email</label>
                <input pInputText type="text" name="email" id="email" formControlName="email" />
                <div class="control__error"
                    *ngIf="this.clinicContactForm.get('email')?.touched && this.clinicContactForm.get('email')?.errors">
                    <span *ngIf="this.clinicContactForm.get('email')?.errors['required']"
                        i18n="@@formInputValidationRequiredField">This value is required</span>
                    <span *ngIf="this.clinicContactForm.get('email')?.errors['email']"
                        i18n="@@formInputValidationEmailFormat">Invalid e-mail format</span>
                </div>
            </div>
            <div class="general-form">
                <label for="phone" for="name" i18n="@@formLabelOptionalPhone">Phone123
                    (optional)</label>
                <ngx-intl-tel-input [cssClass]="'custom__phone-lib_2'" [preferredCountries]="preferredCountries"
                    [enableAutoCountrySelect]="false" [enablePlaceholder]="true" [searchCountryFlag]="true"
                    [searchCountryField]="[SearchCountryField.DialCode, SearchCountryField.Name]"
                    [selectFirstCountry]="false" [selectedCountryISO]="selectedCountryISO" [maxLength]="15"
                    [numberFormat]="PhoneNumberFormat" [phoneValidation]="true" [inputId]="'phone'"
                    [separateDialCode]="separateDialCode" name="phone" [formControlName]="'phone'">
                </ngx-intl-tel-input>
                <div class="control__error-no_margin" *ngIf="phone.touched && phone.errors">
                    <span *ngIf="phone.errors?.validatePhoneNumber">
                        <ng-container i18n="@@formInputPhoneNumberFormat">Check Phone Number format</ng-container>
                    </span>
                </div>

            </div>

            <div>
                <div class="general-form">
                    <label class="control__label" for="message" i18n="@@formLabelMessage">Your message</label>
                    <textarea pInputTextarea id="message" rows="5" cols="80" formControlName="message"></textarea>
                    <div class="control__error"
                        *ngIf="this.clinicContactForm.get('message')?.touched && this.clinicContactForm.get('message')?.errors">
                        <span *ngIf="this.clinicContactForm.get('message')?.errors['required']"
                            i18n="@@formInputValidationRequiredField">This value is required</span>
                    </div>
                </div>
            </div>
            <div class="agree-terms" i18n="@@buttonPaymentPolicy2">
            <p>By selecting the button below, I agree to the
                <a [routerLink]="'/terms'">Terms & Conditions</a> and I acknowledge the  <a [routerLink]="'/privacy'">Privacy Policy</a></p>
                <br/>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="progressBar" *ngIf="isLoadingSubmit">
                        <p-progressBar mode="indeterminate" [style]="{ height: '3px' }"></p-progressBar>
                    </div>
                    <button class="wb-100" pButton type="submit" i18n="@@formButtonSubmit" [disabled]="isLoadingSubmit">Submit</button>
                </div>

            </div>
        </form>
    </div>
</div>
