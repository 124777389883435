import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@lib/auth/auth.service';
import { FavoriteService } from '@lib/features/favorite/favorite.service';
import { I18nService } from '@lib/i18n/i18n.service';
import { IClinic, IClinicPhoto, IClinicPrice } from '@lib/modules/clinic/clinic.models';
import { TableColumns } from '@lib/shared-core/layouts/dynamic-table/dynamic-table.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PageLayoutService } from '@lib/features/page-layout/page-layout.service';
import { WindowResizeHelper } from '@lib/shared-core/helper/window.resize.helper';

@UntilDestroy()
@Component({
    selector: 'app-clinic-panel',
    templateUrl: './clinic-panel.component.html',
    styleUrls: ['./clinic-panel.component.scss']
})
export class ClinicPanelComponent implements OnInit {


    @Input()
    isLoading: boolean = true;

    private _clinic!: IClinic;
    @Input()
    set clinic(data: IClinic) {
        if (data) {
            this._clinic = data;
            const { photos, prices } = data;
            this.clinicPrices = prices && prices.length > 0 ? prices.filter((data) => data != null) : [];
            this.clinicMainPhotos = photos && photos.clinic_photos && photos.clinic_photos.length > 0 ? photos.clinic_photos : [];
            if (this.clinicPrices) {
                this.clinicPrices = this.clinicPrices.map((data: any) => {
                    data.children = data.children.map((dataChild: any) => { dataChild['quantity'] = 0; return dataChild; });
                    return data;
                });
            }

            if (this._clinic.favorite_id) {
                this.isFavorite = true;
            }

            if (this.clinic.location) {
                this.smallestLocation = this.clinic.location.city
                    ? this.clinic.location.city
                    : this.clinic.location.province.id
                        ? this.clinic.location.province
                        : this.clinic.location.country.id
                            ? this.clinic.location.country
                            : null;

                this.staticSearchURL = this.clinic.location.country.slug;
                if (this.clinic.location.province) {
                    this.staticSearchURL += `/${this.clinic.location.province.slug}`;
                    if (this.clinic.location.city) {
                        this.staticSearchURL += `/${this.clinic.location.city.slug}`;
                    }
                }
            }
            if (this._clinic.doctors) {
                this._clinic.doctors = this._clinic.doctors.map((data) => {
                    data.is_verified = true;
                    return data;
                });
            }

        }
    }
    get clinic() { return this._clinic; }
    clinicMainPhotos: IClinicPhoto[] = [];
    clinicPrices: IClinicPrice[] = [];
    visibleGallery: boolean = false;
    currentGalleryIndex: number = 0;
    showAdditionalInfo: boolean = true;
    isFavorite: boolean = false;
    isMobileNative: boolean = false;
    isShowMore: boolean = false;
    more = $localize`:@@showMore:Show more`;
    less = $localize`:@@showLess:Show less`;

    pricesColumn: TableColumns[] = [
        {
            field: 'name',
            header: $localize`:@@clinicQuoteProcedure:Procedure`,
            type: 'priceName',
            innerStyleCss: 'max-width: 500px; position:relative'
        },
        {
            field: 'id',
            header: $localize`:@@clinicQuotePrice:Price`,
            type: 'price'
        },
        {
            field: 'duration',
            header: $localize`:@@duration:Duration`,
            type: 'text'
        }
    ];

    pricesColumnPopup: TableColumns[] = [
        ...this.pricesColumn,
        {
            field: 'quantity',
            header: $localize`:@@clinicQuoteQuantity:Quantity`,
            type: 'number-input'
        }
    ];

    badgePremiumTitle = $localize`:@@badgePremiumTitle:Dental Departures Premium Clinic`;
    badgePremiumDescription = $localize`:@@badgePremiumDescription:This clinic offers excellent service, quality and offers the best available prices based on patient reviews &amp; feedback`;

    clinicInfoToolTip = `<b><h3 class="tip__header_tooltip" >${this.badgePremiumTitle}</h3></b> <br/> ` +
      `<span class="card-wrapper-tooltip"><p>${this.badgePremiumDescription}</p></span>`;

    badgeVerifiedTitle = $localize`:@@badgeVerifiedTitle:Dental Departures Verified Clinic`;
    badgeVerifiedDescription = `<p> We conduct a 4 part review of our dental clinic* - site visit, quality  survey, online reputation, dental license verification</p> <p>  * varies by country </p>`;

    clinicInfoToolTipMobile = `<b><h3 class="tip__header_tooltip">${this.badgeVerifiedTitle}</h3></b> <br/><span i18n="@@badgeVerifiedDescription">${this.badgeVerifiedDescription}</span>`;

    clinicModalViews = ClinicModalViews;
    currentModalContent: any = '';
    isModalVisible: boolean = false;
    smallestLocation: any;
    staticSearchURL: any;


    constructor(
      private i18nService: I18nService,
      private router: Router,
      public favoriteService: FavoriteService,
      public authService: AuthService,
      private windowResizeHelper: WindowResizeHelper,
      private pageLayoutService: PageLayoutService) { }

    ngOnInit(): void {
        this.showAdditionalInfo = this.i18nService.isGlobalSite() ? true : false;
        this.pageLayoutService.toggleBookingAppointment$.subscribe((data: boolean) => {
            if (data) {
                this.bookClinic();
                this.pageLayoutService.setToggleBooking(false);
            }
        });
        this.pageLayoutService.toggleContactClinic$.subscribe((data: boolean)=> {
            if (data) {
                this.openModal(this.clinicModalViews.contactClinic);
                this.pageLayoutService.setToggleContactClinic(false);
            }
        });

        try {
          this.isMobileNative = this.windowResizeHelper.isMobileNative();
        } catch (e) {

        }
    }

    onClickImageGallery(index: any) {
        this.visibleGallery = true;
        this.currentGalleryIndex = index;
    }

    onChangeVisible($event: any) {
        this.visibleGallery = $event;
    }

    openModal(type: any) {
        this.isModalVisible = true;
        this.currentModalContent = type;
    }

    bookClinic() {
        this.router.navigate(['/book/dentist/', this.clinic.slug]);
    }

    onToggleVisible(isHidden?: boolean) {
        if (this.currentModalContent && this.currentModalContent == this.clinicModalViews.contactClinic) {
            const [chatFloat] = document.getElementsByClassName('zsiq_floatmain') as any;
            if (chatFloat) {
                if (isHidden) {
                    chatFloat.classList.remove('zsiq_floatmain-hidden');
                } else
                    chatFloat.classList.add('zsiq_floatmain-hidden');
            }
        }
        if (this.currentModalContent && this.currentModalContent == this.clinicModalViews.getQuote) {
            const [chatFloat] = document.getElementsByClassName('zsiq_floatmain') as any;
            if (chatFloat) {
                if (isHidden) {
                    chatFloat.classList.remove('zsiq_floatmain-right');
                } else
                    chatFloat.classList.add('zsiq_floatmain-right');
            }
        }
    }


    async navigatorShare() {
        const shareOptions = {
            title: `${this.clinic.name}`,
            url: window.location.href
        };
        if (navigator.share) {
            try {
                navigator.share(shareOptions);
            } catch (err) {
                console.log('Error: ', err);
            }
        } else {
            console.warn('Native Web Sharing not supported');
        }
    }

}


export enum ClinicModalViews {

    contactClinic = 'contact-clinic',
    share = 'share',
    showMore = 'show-more',
    reviewModal = 'review-modal',
    getQuote = 'get-quote',
    commentReviewModal = 'comment-review-modal'

}
