import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, ParamMap, Router } from '@angular/router';
import { SearchService } from '@lib/modules/search/search.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AutoComplete } from 'primeng/autocomplete';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';

@UntilDestroy()
@Component({
  selector: 'app-auto-complete-search',
  templateUrl: './auto-complete-search.component.html',
  styleUrls: ['./auto-complete-search.component.scss']
})
export class AutoCompleteSearchComponent implements OnInit {

  @ViewChild('autoCompleteObject') private autoCompleteObject: AutoComplete | any;
  @Input() preQuery: any | string;
  @Input() isHome?: boolean = false;

  @Input() hasFilter?: boolean = false;

  sortOptions: any[] = [
    {
      value: 'recommended',
      label: $localize`:@@searchSortByRecommended:Recommended`
    },
    {
      value: 'reviews',
      label: $localize`:@@searchSortByMostReviews:Most Reviews (rating high to low)`
    }
  ];

  filterOptions: any[] = [
    {
      value: 'value',
      label: $localize`:@@searchSortByValue:Value`
    },
    {
      value: 'luxury',
      label: $localize`:@@searchSortByLuxury:Luxury`
    },
    {
      value: 'select',
      label: $localize`:@@searchSortBySelect:Select`
    }
  ];

  currentScrollPosition: any;
  selectedSort: any;
  selectedFilter: any;
  stateModal: boolean = false;

  formGroup!: UntypedFormGroup | any;
  suggestedItems: any[] = [];
  isPopup: boolean = false;
  visible!: boolean;
  queryString: any = '';
  isLoading: boolean = false;
  isHomePage: boolean = false;

  ref!: DynamicDialogRef;
  transitionOptions: any = '1150ms cubic-bezier(0, 0, 0.2, 1)';
  searchPlaceholder = $localize`:@@autoSearchPlaceholder:Search for destination, procedure, clinic or dentist`;

  constructor(private searchService: SearchService, private router: Router,
    public dialogService: DialogService,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit() {

    this.isHomePage = this.router.url === '/';
    this.formGroup = new UntypedFormGroup({
      selectedItem: new UntypedFormControl(this.createMockSearch(''))
    });
    this.router.events.subscribe((val) => {
      this.close();
    });

    this.activatedRoute.queryParamMap.subscribe( p => {
      if (p.get('sort') || p.get('clinics')) {
        setTimeout(() => {
          this.selectedSort =  p.get('sort') ?? '';
          this.selectedFilter = p.get('clinics') ?? '';
        }, 200);
      }
    });

    this.searchService.querySortParams.pipe(untilDestroyed(this)).subscribe( (data) => {
      if (!data) {
        this.selectedSort = '';
      }
    });

    this.searchService.queryFilterParams.pipe(untilDestroyed(this)).subscribe( (data) => {
      if (!data) {
        this.selectedFilter = '';
      }
    });


    this.searchService.queryParams.pipe(untilDestroyed(this)).subscribe(async (data) => {
      this.preQuery = data;
      this.formGroup.controls.selectedItem.setValue(this.createMockSearch(data));
      this.isHomePage = this.router.url === '/';
      if (this.isHomePage) return;
      const res = await this.searchService.suggest(this.preQuery).toPromise();
      this.suggestedItems = [];
      if (res) {
        this.suggestedItems = res['res']['suggestion'];
      } else {
        this.close(true);
      }
    });

    this.searchService.query.pipe(untilDestroyed(this)).subscribe(async (data) => {
      if (data) {
        this.preQuery = data;
        this.isHomePage = this.router.url === '/';
        this.formGroup.controls.selectedItem.setValue(this.createMockSearch(data));
        if (this.isHomePage) return;
        const res = await this.searchService.suggest(this.preQuery).toPromise();
        this.suggestedItems = [];
        if (res) {
          this.suggestedItems = res['res']['suggestion'];
        } else {
          this.close();
        }
      } else {
        this.close();
      }
    });
  }

  onAfterShow(event: any) {
    this.autoCompleteObject.inputEL.nativeElement.focus();
  }

  navigateToitem(data: any) {
    this.formGroup.controls.selectedItem.setValue(data.value);
    this.onSubmit();
    this.close();
  }

  createMockSearch(preQuery: any) {
    return {
      keyword: preQuery,
      type: preQuery,
      value: preQuery,
      label: preQuery
    };
  }

  close(excludeVisible?: boolean) {
    if (!excludeVisible) {
      this.visible = false;
    }
    this.formGroup.reset();
    this.suggestedItems = [];
  }

  closeStateModal() {
    this.stateModal = false;
  }

  onFocusKeys(event: any) {
    this.currentScrollPosition = document.documentElement.scrollTop;
    setTimeout(() => {
      if (event) {
        window.scrollTo(0, this.currentScrollPosition);
      }
    }, 500);
  }

  onClick() {
    this.visible = true;
  }

  onClear($event: any) {
    this.suggestedItems = [];
  }

  async searchQuery($event: any) {
    this.isLoading = true;
    this.suggestedItems = [];
    if ($event) {
      const { query } = $event;
      const res = await this.searchService.suggest(query).toPromise();
      if (res) {
        this.suggestedItems = res['res']['suggestion'];
      }
    }
    this.isLoading = false;
  }

  onSubmit() {
    let queryString = '';
    const selectedQuery = this.formGroup.controls.selectedItem.value;
    if (typeof selectedQuery === 'string' || selectedQuery instanceof String) {
      queryString = '' + selectedQuery.trim();
    } else {
      queryString = selectedQuery.value;
    }
    this.searchService.updateQuery(queryString);
    if (this.formGroup.valid && queryString !== '') {

      // This will reset autocomplete values
      this.searchService.updateSort(null);
      this.searchService.updateFilter(null);

      this.router.navigate(['/search', queryString]);
    }
  }

  sortChange($event: any) {
    this.selectedSort = $event ?? '';
    this.searchService.updateSort(JSON.parse(JSON.stringify(this.selectedSort)));
    this.searchService.updateFilter(JSON.parse(JSON.stringify(this.selectedFilter)));
    this.stateModal = false;
  }

  filterChange($event: any) {
    this.selectedFilter = $event ?? '';
    this.searchService.updateSort(JSON.parse(JSON.stringify(this.selectedSort)));
    this.searchService.updateFilter(JSON.parse(JSON.stringify(this.selectedFilter)));
    this.stateModal = false;
  }

}
