import { Injectable } from '@angular/core';
import { AccountAction, RegisterState, ShowState } from '@lib/models/account.models';
import {  Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  private currentAccountStateModal: Subject<AccountAction | any> = new Subject<AccountAction | any>();
  public currentAccountStateModal$: Observable<string> = this.currentAccountStateModal.asObservable();

  private currentAccountState: Subject<AccountAction | any> = new Subject<AccountAction | any>();
  public currentAccountState$: Observable<string> = this.currentAccountState.asObservable();

  private isSocialLoginOnStateModal: Subject<boolean | any> = new Subject<boolean | any>();
  public isSocialLoginOnStateModal$: Observable<boolean> = this.isSocialLoginOnStateModal.asObservable();

  public showState: ShowState = ShowState.NO_SHOW;
  public accountAction: AccountAction = AccountAction.LOGIN;
  public registerState: RegisterState = RegisterState.IDLE;

  constructor() { }

  public toggleShowState(state: ShowState) {
    this.accountAction = AccountAction.LOGIN;
    this.showState = state;
  }

  public toggleRegisterState(state: RegisterState) {
    this.registerState = state;
  }

  public toggleAccountAction(action: AccountAction) {
    this.accountAction = action;
  }
  
  updateCurrentAccountStateModal(accountState: AccountAction) {
    this.currentAccountStateModal.next(accountState);
  }

  updateCurrentAccountState(accountState: AccountAction) {
    this.currentAccountState.next(accountState);
  }

  toggleIsSocialLoginOnModalState(value: Boolean) {
    this.isSocialLoginOnStateModal.next(value);
  }

}
