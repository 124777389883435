import { isPlatformBrowser } from '@angular/common';
import { Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
// import { VERSION } from '../../../../../../../../version';
import { ApiService } from '../data/core-api.service';
import { LogService } from '../features/log/log.service';
import { ICommon } from './common.models';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private commonKey = 'common';

  private commonSubject: BehaviorSubject<ICommon | null> = new BehaviorSubject<ICommon | null>(null);
  public common$ = this.commonSubject.asObservable();

  protected isBrowser: boolean;

  constructor(
    private injector: Injector,
    private apiService: ApiService,
    private logService: LogService) {
    const platformId = this.injector.get(PLATFORM_ID);

    this.logService.log('Common.init()');
    this.isBrowser = isPlatformBrowser(platformId);
    this.logService.log(`** PLATFORM_ID=${platformId}, isBrowser=${this.isBrowser}`);

    this.fetchCommon().subscribe();
  }

  private fetchCommon() {
    this.logService.log('Common.fetchCommon()');
    return this.apiService.commonGET().pipe(
      map((res: ICommon) => {
        // this.logService.log('Common.fetchCommon().res', res);
        const _common: ICommon = res;
        if (_common) {
          // _common.version = VERSION.raw;
          _common.version = "v20.0409.1730-3-g75b2aa6ce-dirty";
        }
        this.saveCommon(_common);
        this.commonSubject.next(_common);
      })
    );
  }

  private saveCommon(common: ICommon) {
    common.last_updated = new Date().getTime();
    localStorage.setItem(this.commonKey, JSON.stringify(common));
  }

  private readFromLocalStorage(): string {
    this.logService.log('Common.readFromLocalStorage()');
    return (localStorage.getItem(this.commonKey))
      ? localStorage.getItem(this.commonKey)!
      : '';
  }

  private getCommon(): ICommon | null {
    this.logService.log('Common.getCommon()');
    const commonString = this.readFromLocalStorage();
    return (commonString !== '')
      ? JSON.parse(commonString) as ICommon
      : null!;
  }
}
