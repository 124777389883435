import { Component, Input, OnInit } from '@angular/core';
import { IDoctor } from '@lib/modules/clinic/clinic.models';
// import { SwiperOptions } from 'swiper';

@Component({
    selector: 'app-doctors',
    templateUrl: './doctors.component.html',
    styleUrls: ['./doctors.component.scss']
})
export class DoctorsComponent implements OnInit {

    @Input()
    title: any = $localize`:@@doctors:Doctors`;

    @Input()
    clinicDoctors: IDoctor[] = [];
    config!: any;
    disabled: boolean = false;
    constructor() {}

    ngOnInit(): void {
      this.config = this.swiperConfig();
    }

    swiperConfig(): any {
      return {
        a11y: { enabled: true },
        updateOnWindowResize: true,
        direction: 'horizontal',
        slidesPerView: 4,
        keyboard: false,
        mousewheel: false,
        scrollbar: false,
        navigation: true,
        pagination: false,
        breakpoints: {
          120: {
            slidesPerView: 1.1,
            spaceBetween: 20
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 40
          },
          1224: {
            slidesPerView: 4,
            spaceBetween: 40
          }
        }
      };
    }


}
