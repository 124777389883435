<section class="thank-you">
  <img src="assets/icons/black-check-icon.svg" alt="check icon">

  <h1>Thank you for your interest in becoming a dental provider with {{siteName | titlecase}} Departures</h1>

  <p>
    Thank you for your interest in becoming a {{siteName}} provider with {{siteName | titlecase}} Departures.
    We will use the information in this questionnaire in part to determine if we select your {{siteName}}
    clinic to partner with and refer patients to. Please answer the following questions as completely
    and accurately as possible. You will receive a reply back from {{siteName | titlecase}} Departures within 7 business days.
    If you have any questions please e-mail: questionnaire@{{siteName}}departures.com or call.
  </p>

  <button (click)="goto()" pButton> Go back to homepage</button>
  <br/><br/>
</section>
