import { Component, Input, OnInit } from '@angular/core';
import { IClinic } from '@lib/modules/clinic/clinic.models';
import { IClinicExtras } from '@lib/modules/search/search.models';
// import { SwiperOptions } from 'swiper';
import { TableColumns } from '../dynamic-table/dynamic-table.component';

interface PaymentMethod {
  label: string;
  photoLink: string;
  isAvailable: boolean;
}

@Component({
  selector: 'app-about-clinic',
  templateUrl: './about-clinic.component.html',
  styleUrls: ['./about-clinic.component.scss']
})
export class AboutClinicComponent implements OnInit {


  _clinic!: IClinic;
  @Input()
  set clinic(clinic: IClinic) {
    this._clinic = clinic;
    if (this._clinic) {
      setTimeout(() =>{
        this.config = this.swiperConfig();
      }, 500);
    }
  };

  get clinic() {
    return this._clinic;
  }

  @Input()
  hasAboutUs:boolean = true;

  @Input()
  title: any = 'About Us';
  config!: any;
  @Input() clinicExtras!: IClinicExtras;

  clinicPaymentMethodLists: PaymentMethod[] = [];
  paymentMethodLists: PaymentMethod[] = [
    {
      label : 'Visa',
      photoLink : 'https://static.medicaldepartures.com/accepted-payment-methods/visa.svg?format=auto',
      isAvailable: false
    },
    {
      label: "Mastercard",
      photoLink: "https://static.medicaldepartures.com/accepted-payment-methods/mastercard.svg?format=auto",
      isAvailable: false
    },
    {
      label: "Cash",
      photoLink: "https://static.medicaldepartures.com/accepted-payment-methods/cash.svg?format=auto",
      isAvailable: false
    },
    {
      label: "Bank Transfer",
      photoLink: "https://static.medicaldepartures.com/accepted-payment-methods/bank-transfer.svg?format=auto",
      isAvailable: false
    },
    {
      label: "Diners Club",
      photoLink: "https://static.medicaldepartures.com/accepted-payment-methods/diners-club.svg?format=auto",
      isAvailable: false
    },
    {
      label: "Travelers Check",
      photoLink: "https://static.medicaldepartures.com/accepted-payment-methods/travelers-check.svg?format=auto",
      isAvailable: false
    },
    {
      label: "Discover",
      photoLink: "https://static.medicaldepartures.com/accepted-payment-methods/discover.svg?format=auto",
      isAvailable: false
    },
    {
      label: "JCB",
      photoLink: "https://static.medicaldepartures.com/accepted-payment-methods/jcb.svg?format=auto",
      isAvailable: false
    },
    {
      label: "PayPal",
      photoLink: "https://static.medicaldepartures.com/accepted-payment-methods/paypal.svg?format=auto",
      isAvailable: false
    },
    {
      label: "Amex",
      photoLink: "https://static.medicaldepartures.com/accepted-payment-methods/amex.svg?format=auto",
      isAvailable: false
    },
    {
      label: "China Union Pay",
      photoLink: "https://static.medicaldepartures.com/accepted-payment-methods/china-union-pay.svg?format=auto",
      isAvailable: false
    },
    {
      label: "Personal Checks",
      photoLink: "https://static.medicaldepartures.com/accepted-payment-methods/personal-checks.svg?format=auto",
      isAvailable: false
    }
  ];


  workingHrsColumn: TableColumns[] = [
    {
      field: 'label',
      header: '',
      type: 'text'
    },
    {
      field: 'value',
      header: '',
      type: 'text'
    }
  ];
  workingHrsList: any[] = [];
  extras: {
    label: string;
    icon: string;
    isAvailable: boolean;
  }[] = [];
  imagesBeforeAndAfter: any[] = [];
  visibleGallery: boolean = false;
  currentGalleryIndex = 0;
  responsiveOptions: any;
  additionalServices = $localize`:@@additionalServices: Additional Services`;

  constructor() {}

  swiperConfig(): any {
    return {
      updateOnWindowResize: true,
      direction: 'horizontal',
      slidesPerView: 2,
      keyboard: false,
      mousewheel: false,
      scrollbar: false,
      navigation: true,
      pagination: false,
      breakpoints: {
        120: {
          slidesPerView: 2,
          spaceBetween: 10
        },
        640: {
          slidesPerView: 2,
          spaceBetween: 10
        },
        1024: {
          slidesPerView: 4.5,
          spaceBetween: 40
        }
      }
    };
  }

  ngOnInit(): void {

    if (this.clinic && this.clinic.info) {
      const { working_hours, extras } = this.clinic.info;
      const { photos } = this.clinic;
      this.workingHrsList = [];
      for (const [key, value] of Object.entries(working_hours)) {
        this.workingHrsList.push({
          label: key.toUpperCase(),
          value: value.from + ' - ' + value.to
        });
      }
      this.clinicExtras = extras;
      this.clinicExtrasMap();
      photos.before_after_photos.forEach(data => {
        this.imagesBeforeAndAfter.push(data.before_image);
        this.imagesBeforeAndAfter.push(data.after_image);
      });
      if (this.imagesBeforeAndAfter && this.imagesBeforeAndAfter.length > 0) {
        this.imagesBeforeAndAfter = this.imagesBeforeAndAfter.map((image: any, index) => { return { image, index } });
        this.config = this.swiperConfig();
      };

      if(this.clinic.info && this.clinic.info.payment_methods) {
        this.clinic.info.payment_methods.forEach((data) => {
            if(data) {
             this.paymentMethodLists.forEach((elem : any)=> {
                if(data.toLowerCase() == elem.label.toLowerCase() || data.toLowerCase().includes(elem) || data == elem.label) {
                  elem.isAvailable = true;
                  this.clinicPaymentMethodLists.push(elem);
                }
              })
            }
        });
      }
    }

  }

  openImageGallery(data: any) {
    this.visibleGallery = true;
    this.currentGalleryIndex = data.index;
  }
  onChangeVisible($event: any) {
    this.visibleGallery = false;
  }

  mapPaymentMethodImages() {

  }

  clinicExtrasMap() {
    if (this.clinicExtras) {
      this.extras = [
        {
          label: $localize`:@@freeExam:Free Exam`,
          icon: 'stethoscope',
          isAvailable: this.clinicExtras.freeExam
        },
        {
          label: $localize`:@@freeWifi:Free Wifi`,
          icon: 'wifi',
          isAvailable: this.clinicExtras.freeWifi
        },
        {
          label: $localize`:@@freeWater:Free Water`,
          icon: 'tint',
          isAvailable: this.clinicExtras.freeWater
        },
        {
          label: $localize`:@@freeCoffee:Free Coffee`,
          icon: 'coffee',
          isAvailable: this.clinicExtras.freeCoffee
        },
        {
          label: $localize`:@@freeParking:Free Parking`,
          icon: 'car',
          isAvailable: this.clinicExtras.freeParking
        },
        {
          label: $localize`:@@freeHotelTransportation:Free Ground Transportation to Hotel`,
          icon: 'taxi',
          isAvailable: this.clinicExtras.freeHotelTransportation
        },
        {
          label: $localize`:@@freeAirportTransportation:Free Ground Transportation to Airport`,
          icon: 'plane',
          isAvailable: this.clinicExtras.freeAirportTransportation
        },
        {
          label: $localize`:@@freePhoneCalls:Free Phone Call`,
          icon: 'phone',
          isAvailable: this.clinicExtras.freePhoneCalls
        },
        {
          label: $localize`:@@freeXrays:Free X-rays`,
          icon: 'heartbeat',
          isAvailable: this.clinicExtras.freeXrays
        },
        {
          label: $localize`:@@freeWheelChair:Wheelchair Accessible`,
          icon: 'wheelchair',
          isAvailable: this.clinicExtras.freeWheelChair
        }
      ].filter(c => c.isAvailable === true);
    }
  }

}
