import { environment } from "../../environments/environment";


export const NEW_LOCATIONS = [
    {
            url: `${environment.host}/info/treatments/mexico`,
            country: 'Mexico',
            continent: 'Latin America'
    },
    {
            url: `${environment.host}/info/treatments/costa-rica`,
            country: 'Costa Rica',
            continent: 'Latin America'
    },
    {
            url: `${environment.host}/info/treatments/colombia`,
            country: 'Colombia',
            continent: 'Latin America'
    },
    {
            url: `${environment.host}/dentists/treatments/turkey`,
            country: 'Turkey',
            continent: 'Europe'
    },
    {
            url: `${environment.host}/info/treatments/spain`,
            country: 'Spain',
            continent: 'Europe'
    },
    {
            url: `${environment.host}/info/treatments/hungary`,
            country: 'Hungary',
            continent: 'Europe'
    },
    {
            url: `${environment.host}/info/treatments/croatia`,
            country: 'Croatia',
            continent: 'Europe'
    },
    {
            url: `${environment.host}/dentists/treatments/albania`,
            country: 'Albania',
            continent: 'Europe'
    },
    {
            url: `${environment.host}/info/treatments/thailand`,
            country: 'Thailand',
            continent: 'Asia'
    },
    {
            url: `${environment.host}/info/treatments/vietnam`,
            country: 'Vietnam',
            continent: 'Asia'
    },
    {
            url: `${environment.host}/dentists/treatments/indonesia`,
            country: 'Indonesia',
            continent: 'Asia'
    }
]; 


export const LATIN_AMERICA = [
    {
        city: 'Los Algodones',
        url: `${environment.host}/info/treatments/mexico/baja-california/los-algodones`,
        country: 'Mexico',
        continent: 'Latin America'
    },
    {
        city: 'Playa Del Carmen',
        url: `${environment.host}/info/treatments/mexico/quintana-roo/playa-del-carmen`,
        country: 'Mexico',
        continent: 'Latin America'
    },
    {
        city: 'Tijuana',
        url: `${environment.host}/info/treatments/mexico/baja-california/tijuana`,
        country: 'Mexico',
        continent: 'Latin America'
    },
    {
        city: 'Cancun',
        url: `${environment.host}/info/treatments/mexico/quintana-roo/cancun`,
        country: 'Mexico',
        continent: 'Latin America'
    },
    {
        city: 'Nogales',
        url: `${environment.host}/info/treatments/mexico/sonora/nogales`,
        country: 'Mexico',
        continent: 'Latin America'
    },
    {
        city: 'Nuevo Laredo',
        url: `${environment.host}/dentists/treatments/mexico/tamaulipas/nuevo-laredo`,
        country: 'Mexico',
        continent: 'Latin America'
    },
    {
        city: 'Cuernavaca',
        url: `${environment.host}/info/treatments/mexico/morelos/Cuernavaca`,
        country: 'Mexico',
        continent: 'Latin America'
    },
    {
        city: 'Matamoros',
        url: `${environment.host}/dentists/treatments/mexico/Tamaulipas/Matamoros`,
        country: 'Mexico',
        continent: 'Latin America'
    },
    {
        city: 'Guadalajara',
        url: `${environment.host}/info/treatments/mexico/jalisco/guadalajara`,
        country: 'Mexico',
        continent: 'Latin America'
    },
    {
        city: 'Monterrey',
        url: `${environment.host}/info/treatments/mexico/nuevo-leon/monterrey`,
        country: 'Mexico',
        continent: 'Latin America'
    },
    {
        city: 'San Jose Del Cabo',
        url: `${environment.host}/info/treatments/mexico/baja-california-sur/san-jose-del-cabo`,
        country: 'Mexico',
        continent: 'Latin America'
    },
    {
        city: 'San Jose',
        url: `${environment.host}/info/treatments/costa-rica/san-jose/san-jose`,
        country: 'Mexico',
        continent: 'Latin America'
    },
    {
        city: 'Escazu',
        url: `${environment.host}/info/treatments/costa-rica/san-jose/escazu`,
        country: 'Costa Rica',
        continent: 'Latin America'
    },
    {
        city: 'Alajuela',
        url: `${environment.host}/info/treatments/costa-rica/alajuela/alajuela`,
        country: 'Costa Rica',
        continent: 'Latin America'
    },
    {
        city: 'Bogota',
        url: `${environment.host}/info/treatments/colombia/cundinamarca/bogota`,
        country: 'Colombia',
        continent: 'Latin America'
    },
    {
        city: 'Medellin',
        url: `${environment.host}/info/treatments/colombia/antioquia/medellin`,
        country: 'Colombia',
        continent: 'Latin America'
    },
    {
        city: 'Cartagena De Indias',
        url: `${environment.host}/info/treatments/colombia/bolivar/cartagena-de-indias`,
        country: 'Colombia',
        continent: 'Latin America'
    }
];

export const EUROPE = [
    {
        city: 'Istanbul',
        url: `${environment.host}/dentists/treatments/turkey/istanbul/istanbul`,
        country: 'Turkey',
        continent: 'Europe'
    },
    {
        city: 'Antalya',
        url: `${environment.host}/info/treatments/turkey/antalya-province/antalya`,
        country: 'Turkey',
        continent: 'Europe'
    },
    {
        city: 'Izmir',
        url: `${environment.host}/info/treatments/turkey/izmir-province/izmir`,
        country: 'Turkey',
        continent: 'Europe'
    },
    {
        city: 'Fethiye',
        url: `${environment.host}/dentists/treatments/turkey/mugla/fethiye`,
        country: 'Turkey',
        continent: 'Europe'
    },
    {
        city: 'Bursa',
        url: `${environment.host}/dentists/treatments/turkey/bursa-province/bursa`,
        country: 'Turkey',
        continent: 'Europe'
    },
    {
        city: 'Bodrum',
        url: `${environment.host}/dentists/treatments/turkey/mugla/bodrum`,
        country: 'Turkey',
        continent: 'Europe'
    },
    {
        city: 'Kusadasi',
        url: `${environment.host}/dentists/treatments/turkey/izmir-province/kusadasi`,
        country: 'Turkey',
        continent: 'Europe'
    },
    {
        city: 'Mugla',
        url: `${environment.host}/dentists/treatments/turkey/marmaris/mugla`,
        country: 'Turkey',
        continent: 'Europe'
    },
    {
        city: 'Valencia',
        url: `${environment.host}/dentists/treatments/spain/valencia/valencia`,
        country: 'Spain',
        continent: 'Europe'
    },
    {
        city: 'Almeria',
        url: `${environment.host}/dentists/treatments/spain/almeria`,
        country: 'Spain',
        continent: 'Europe'
    },
    {
        city: 'Alicante',
        url: `${environment.host}/info/treatments/spain/alicante/alicante`,
        country: 'Spain',
        continent: 'Europe'
    },
    {
        city: 'Barcelona',
        url: `${environment.host}/dentists/treatments/spain/barcelona`,
        country: 'Spain',
        continent: 'Europe'
    },
    {
        city: 'Las Palmas',
        url: `${environment.host}/dentists/treatments/spain/las-palmas/las-palmas`,
        country: 'Spain',
        continent: 'Europe'
    },
    {
        city: 'Madrid',
        url: `${environment.host}/dentists/treatments/spain/madrid/madrid`,
        country: 'Spain',
        continent: 'Europe'
    },
    {
        city: 'Budapest',
        url: `${environment.host}/info/treatments/hungary/central-hungary/budapest`,
        country: 'Hungary',
        continent: 'Europe'
    },
    {
        city: 'Zagreb',
        url: `${environment.host}/info/treatments/croatia/zagreb/zagreb`,
        country: 'Croatia',
        continent: 'Europe'
    },
    {
        city: 'Rijeka',
        url: `${environment.host}/info/treatments/croatia/kvarner-bay/rijeka`,
        country: 'Croatia',
        continent: 'Europe'
    },
    {
        city: 'Split',
        url: `${environment.host}/info/treatments/croatia/dalmatia/split`,
        country: 'Croatia',
        continent: 'Europe'
    }
];

export const ASIA = [
    {
        city: 'Bangkok',
        url: `${environment.host}/info/treatments/thailand/bangkok-province/bangkok`,
        country: 'Thailand',
        continent: 'Asia'
    },
    {
        city: 'Banglamung',
        url: `${environment.host}/dentists/treatments/thailand/chonburi-province/banglamung`,
        country: 'Thailand',
        continent: 'Asia'
    },
    {
        city: 'Chiang Mai',
        url: `${environment.host}/info/treatments/thailand/chiang-mai-province/chiang-mai`,
        country: 'Thailand',
        continent: 'Asia'
    },
    {
        city: 'Karon',
        url: `${environment.host}/info/treatments/thailand/phuket-province/karon`,
        country: 'Thailand',
        continent: 'Asia'
    },
    {
        city: 'Koh Samui',
        url: `${environment.host}/dentists/treatments/thailand/surat-thani-province/koh-samui`,
        country: 'Thailand',
        continent: 'Asia'
    },
    {
        city: 'Patong',
        url: `${environment.host}/info/treatments/thailand/phuket-province/patong`,
        country: 'Thailand',
        continent: 'Asia'
    },
    {
        city: 'Pattaya',
        url: `${environment.host}/info/treatments/thailand/chonburi-province/pattaya`,
        country: 'Thailand',
        continent: 'Asia'
    },
    {
        city: 'Phuket',
        url: `${environment.host}/info/treatments/thailand/phuket-province/phuket`,
        country: 'Thailand',
        continent: 'Asia'
    },
    {
        city: 'Ho Chi Minh City',
        url: `${environment.host}/info/treatments/vietnam/ho-chi-minh-municipality/ho-chi-minh-city`,
        country: 'Vietnam',
        continent: 'Asia'
    },
    {
        city: 'Nha Trang',
        url: `${environment.host}/dentists/treatments/vietnam/khanh-hoa-province/nha-trang`,
        country: 'Vietnam',
        continent: 'Asia'
    },
    {
        city: 'Vung Tau',
        url: `${environment.host}/dentists/treatments/vietnam/ba-ria-vung-tau/vung-tau`,
        country: 'Vietnam',
        continent: 'Asia'
    },
    {
        city: 'Da Nang',
        url: `${environment.host}/dentists/treatments/vietnam/da-nang-municipality/da-nang`,
        country: 'Vietnam',
        continent: 'Asia'
    },
    {
        city: 'Hai Phong',
        url: `${environment.host}/dentists/treatments/vietnam/hai-phong-municipality/hai-phong`,
        country: 'Vietnam',
        continent: 'Asia'
    },
    {
        city: 'Hanoi',
        url: `${environment.host}/info/treatments/vietnam/hanoi-municipality/hanoi`,
        country: 'Vietnam',
        continent: 'Asia'
    },
    {
        city: 'Denpasar',
        url: `${environment.host}/info/treatments/indonesia/bali/denpasar`,
        country: 'Indonesia',
        continent: 'Asia'
    },
    {
        city: 'Kuta',
        url: `${environment.host}/info/treatments/indonesia/bali/kuta`,
        country: 'Indonesia',
        continent: 'Asia'
    },
    {
        city: 'Ubud',
        url: `${environment.host}/info/treatments/indonesia/bali/ubud`,
        country: 'Indonesia',
        continent: 'Asia'
    }
];
