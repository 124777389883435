import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClinicService } from '@lib/modules/clinic/clinic.service';
import { IReview } from '@lib/shared-core/models/review.model';
// import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-latest-reviews-card',
  templateUrl: './latest-reviews-card.component.html',
  styleUrls: ['./latest-reviews-card.component.scss']
})
export class LatestReviewsCardComponent implements OnInit {

  @Input()
  isReviewArray: boolean = false;

  @Input()
  isFooter: boolean = false;

  private _latestReview : IReview[] | any = [];
  @Input()
  set latestReview(latestReview: IReview[] | any) {
    this._latestReview= latestReview;
  };

  get latestReview() {
    return this._latestReview;
  }

  currentReview: IReview | any;
  summaryReport: { label: any, value: number, percentageValue: number }[] = [];

  config!: any;
  disabled: boolean = false;

  @Output()
  onClickShowMore = new EventEmitter<boolean>();

  @Output()
  onClickReviewMore = new EventEmitter<any>();

  constructor(private clinicService: ClinicService) { }

  ngOnInit(): void {
    this.config = this.swiperConfig();
    if (this.latestReview && this.latestReview.length > 0 ) {
      this.latestReview = this.latestReview.map((data: any)=>{
        if (data && data.rating) {
          const { rating } = data;
          const score: any[] = [];
          Object.keys(rating).forEach(function (key) {
            score.push(rating[key]);
          });
          data.averageScore = ((score.reduce((a, b) => a + b, 0)) / score.length) || 0;
        }
        return data;
      });
    }
  }

  swiperConfig(): any {
    return {
      a11y: { enabled: true },
      updateOnWindowResize: true,
      direction: 'horizontal',
      slidesPerView: 4,
      keyboard: false,
      mousewheel: false,
      scrollbar: false,
      navigation: true,
      pagination: false,
      breakpoints: {
        120: {
          slidesPerView: this.isReviewArray ? 1.3 : 1,
          spaceBetween: 20
        },
        640: {
          slidesPerView: 2,
          spaceBetween: 20
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 40
        }
      }
    };
  }

  public choosenReview(review: any) {
    this.currentReview = review;
    this.summaryReport = this.clinicService.mapSummaryReport(review.rating);
    this.onClickReviewMore.emit({currentReview: this.currentReview, summaryReport: this.summaryReport});
  }

  public toggleKeyboardControl(): void {
    this.config.keyboard = !this.config.keyboard;
  }

  public toggleMouseWheelControl(): void {
    this.config.mousewheel = !this.config.mousewheel;
  }

  public onIndexChange(index: number): void { }
  public onSwiperEvent(event: string): void { }

}
