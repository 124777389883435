import {
  AfterViewInit,
  Component,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { MenuItem } from '@lib/models/menu-item.model';
import { MenuItem as MenuItemPrime } from 'primeng/api/menuitem';
import { IUserProfile } from '@lib/auth/auth.model';
import { ILanguage } from '@lib/common/common.models';
import { NavigationStart, Router } from '@angular/router';
import { I18nService } from '@lib/i18n/i18n.service';
import { environment } from 'projects/dental/src/environments/environment';
import { WindowResizeHelper } from '@lib/shared-core/helper/window.resize.helper';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { HEADER_DISPLAY_STATE, PageLayoutService } from '@lib/features/page-layout/page-layout.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { AccountService } from '@lib/features/account/account.service';
import { AccountAction } from '@lib/models/account.models';
import { AuthService } from '@lib/auth/auth.service';
import { LanguageService } from '@lib/features/language/language.service';
import { CurrencyService } from '@lib/features/currency/currency.service';
import { IpService } from '@lib/features/geolocation/ip.service';
import { CountryISO } from 'ngx-intl-tel-input';
import { IIpInfo } from '@lib/features/geolocation/ip.models';

@UntilDestroy()
@Component({
  selector: 'app-header-v2',
  templateUrl: './header-v2.component.html',
  styleUrls: ['./header-v2.component.scss']
})
export class HeaderV2Component implements OnInit, AfterViewInit {

  menuPrimeNgLists: MenuItemPrime[] = [];
  menuPrimengMobileLists: MenuItemPrime[] = [];
  menuPrimeNgClonedLists: MenuItemPrime[] = [];
  menuTreatmentLists: MenuItemPrime[] | any[] = [];
  otherMenuLists: MenuItemPrime[] = [];
  freeQuoteButton!: MenuItem;
  phoneNumber!: string;
  isMobile: boolean = false;
  hasOtherHeaders: boolean = false;
  hasFilter: boolean = true;
  accountActions = AccountAction;
  selectedCountryISO: CountryISO = CountryISO.UnitedStates;

  private _headerDataItems: HeaderDataItems | undefined;
  platformWindow = isPlatformBrowser(this.platformId) ? window : this.dom;


  @Input()
  logoImages: HeaderLogo = {
    desktopUrl: 'https://img.dentaldepartures.com/logo/dd_logo.svg',
    mobileUrl: 'https://img.dentaldepartures.com/logo-mini.png'
  };

  @Input()
  set headerDataItems(headerDataItems: HeaderDataItems | undefined) {
    if (headerDataItems) {
      this._headerDataItems = headerDataItems;
      const { menuPrimeNgLists } = headerDataItems;
      this.menuPrimeNgLists = menuPrimeNgLists.filter(data => data.visible);
      this.menuPrimeNgLists = this.menuPrimeNgLists.map((data) => {
        if (data.routerLink == '/contacts') {
          data.styleClass = 'contact-wrapper';
        }
        if (data.routerLink == '/about') {
          data.visible = false;
        }
        return data;
      });

      this.menuPrimeNgClonedLists = [...this.menuPrimeNgLists];
      const [menuTreatmentLists] = menuPrimeNgLists.filter(data => data.routerLink == '/treatments');
      const [freeQuoteButton] = menuPrimeNgLists.filter(data => data.routerLink == '/free-quote');
      this.menuTreatmentLists = menuTreatmentLists.items as any;
      this.otherMenuLists = [...[], ...menuPrimeNgLists.filter(data => !data.visible && data.routerLink != '/about')];
      this.otherMenuLists = [...[], ...this.otherMenuLists.map((data) => { data.visible = true; return data; })];
      this.menuPrimengMobileLists = JSON.parse(JSON.stringify(menuPrimeNgLists));
      this.menuPrimengMobileLists = this.updateOrderMenuMobileList([...[], ...this.menuPrimengMobileLists]);
      if (freeQuoteButton) {
        freeQuoteButton.visible = true;
        this.menuPrimeNgLists = [...[], ...this.menuPrimeNgLists];
      }
      if (freeQuoteButton) {
        this.freeQuoteButton = freeQuoteButton as any;
      }
      if (isPlatformBrowser(this.platformId)) {
        this.isMobile = window.innerWidth < this.windowResizeHelper.mobileBreakpoint;
        this.checkWindowSize(this.isMobile);
      }
    }
  }

  get headerDataItems(): HeaderDataItems | undefined | any {
    return this._headerDataItems;
  }

  toggleHeader$!: Observable<boolean>;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(DOCUMENT) private dom: Document,
    private router: Router,
    private i18nService: I18nService,
    private windowResizeHelper: WindowResizeHelper,
    private pageLayoutService: PageLayoutService,
    private accountService: AccountService,
    private authService: AuthService,
    private languageService: LanguageService,
    private currencyService: CurrencyService,
    private ipService: IpService,
  ) {
    this.toggleHeader$ = this.pageLayoutService.toggleHeader$.pipe(map(s => s === HEADER_DISPLAY_STATE.SHOW_HEADER));
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.removeZohoClass();
    }, 1000);
  }


  resetDesktop() {
    const user = this.headerDataItems && this.headerDataItems.currentUser ? this.headerDataItems.currentUser : null;
    this.menuPrimeNgLists = [...[], ...this.menuPrimeNgLists.map((data) => {
      if (data.routerLink == '/contacts') {
        data.styleClass = 'contact-wrapper';
      }
      if (data.routerLink == '/about') {
        data.visible = false;
      }
      if (data.styleClass == 'user-wrapper') {
        data.icon = 'ri-user-fill';
        data.label = user ? `${user.trim() ? user : user.email}` : '';
      }
      return data;
    })];
  }

  ngOnInit(): void {
    // Get user's current location and pass the value to
    // "setTollFreeNumberByLocale" to set toll-free number base on user's location
    // or language selected on the website
    this.ipService.ipGeoInfo$.pipe(untilDestroyed(this)).subscribe((data: IIpInfo | null) => {
      if (data && data !== null) {
        this.selectedCountryISO = data?.countryCode ? data?.countryCode.toLocaleLowerCase() as any : this.selectedCountryISO;
      } else {
        this.selectedCountryISO = CountryISO.UnitedStates;
      }

      this.setTollFreeNumberByLocale(this.selectedCountryISO);
    });

    this.windowResizeHelper.isMobile().pipe(untilDestroyed(this)).subscribe(isMobile => {
      this.isMobile = isMobile;
      this.checkWindowSize(isMobile);
    });
    this.hasOtherHeadersAvailable();
    this.router.events.pipe(untilDestroyed(this)).subscribe(event => {
      if (event instanceof NavigationStart) {
        this.hasOtherHeadersAvailable(event ? event.url : '');
      }
    });
  }

  // If the user has selected Deutsch, display the German number,
  // if Francais, display the French number, if Espaol, display the spanish number,
  // else wait 3 secs to ensure "selectedCountryISO" has been updated and then call
  // "setTollFreeNumberByUserLocation" to display toll-free base on user's location.
  setTollFreeNumberByLocale(selectedCountryISO?: string) {
    switch (this.i18nService.getCurrentLocale()) {
      case 'de':
        this.phoneNumber = environment.phones.de; break;
      case 'fr':
        this.phoneNumber = environment.phones.fr; break;
      case 'es':
        this.phoneNumber = environment.phones.es; break;
      default:
        // Wait 3 sec to ensure "selectedCountryISO" value has been updated
        setTimeout(() => {
          this.setTollFreeNumberByUserLocation(selectedCountryISO);
        }, 3000);
    }
  }

  setTollFreeNumberByUserLocation(selectedCountryISO?: string) {
      switch (selectedCountryISO) {
        // Thailand
        case 'th':
          this.phoneNumber = environment.phones.th;
          break;
        // France, Spain, Germany, Turkey, Ireland, Netherlands, Italy, Croatia, Switzerland
        case 'fr':
        case 'es':
        case 'de':
        case 'tr':
        case 'ie':
        case 'nl':
        case 'it':
        case 'hr':
        case 'ch':
          this.phoneNumber = environment.phones.gb;
          break;
        case 'id':
          this.phoneNumber = environment.phones.au;
          break;
        default:
          this.phoneNumber = environment.phones.ctm;
          this.setTollFreeNumberWithCTM();
      }
  }

  setTollFreeNumberWithCTM() {
    // Scan the DOM element to change the Call Tracking Metric phone number base on user's location.
    // It is added in the ts and html file to ensure the script work

    if (isPlatformBrowser(this.platformId)) {
      const script1 = document.createElement('script');
      script1.textContent = `
      window.__ctm_loaded = window.__ctm_loaded || [];
      window.__ctm_loaded.push(function() {
        __ctm.main.start(document.querySelector(".callus"));
      });
    `;
      document.head.appendChild(script1);

      const script2 = document.createElement('script');
      script2.src = '//4433.tctm.co/f.js';
      script2.async = true;
      document.head.appendChild(script2);
    }

  }

  removeZohoClass() {
    const bodyElement = this.dom.querySelector('body');
    if (bodyElement) {
      const classList = bodyElement.classList;
      if (classList.contains('zsiq_fixedlayout')) {
        classList.remove('zsiq_fixedlayout');
      }
    }
  }

  hasOtherHeadersAvailable(url?: any) {
    const hrefLocation = url ? url : this.platformWindow.location.href;
    this.hasOtherHeaders = true;
    this.hasFilter = true;

    if ((!hrefLocation.includes('/dentists/treatments/')
      && (hrefLocation.includes('dentist/') || hrefLocation.includes('clinic')))) {
        this.hasOtherHeaders = false;
        this.hasFilter = false;

        if (hrefLocation.includes('/dentists/') || hrefLocation.includes('/search/')) {
          this.hasFilter = true;
          this.hasOtherHeaders = true;
        }

    } else if (hrefLocation && ( hrefLocation.includes('/fr/') ||
        hrefLocation.includes('/de/') ||
        hrefLocation.includes('/th/') ||
        hrefLocation.includes('/es/'))) {
        this.hasFilter = false;
    } else {

      this.hasFilter = false;

      if (this.platformWindow.location.pathname === '/' || hrefLocation === '/'
        || (this.platformWindow.location.href === this.platformWindow.location.origin + '/')) {
        this.hasFilter = false;
      }

      if (hrefLocation.includes('/dentists/') || hrefLocation.includes('/search/')) {
        this.hasFilter = true;
        this.hasOtherHeaders = true;
      }

      if (hrefLocation.includes('/top-clinics') || hrefLocation.includes('/info/')
        || hrefLocation.includes('/contact')) {
        this.hasOtherHeaders = true;
      }

      if (hrefLocation.includes('/book/confirm/')) {
        this.hasOtherHeaders = false;
      }
    }
}


  checkWindowSize(isMobile: boolean) {
    if (isMobile && this.menuPrimeNgLists.length === this.menuPrimeNgClonedLists.length) {
      const otherMenuLists = [...[], ...this.otherMenuLists];
      otherMenuLists.map((data) => {
        if (data.url?.includes('external-login')) data.label = 'Sign In';
        return data;
      });
      this.menuPrimeNgLists = [...otherMenuLists.reverse(), ...this.menuPrimeNgLists];
    } else if (!isMobile) {
      this.menuPrimeNgLists = [...[], ...this.menuPrimeNgClonedLists];
      this.otherMenuLists.map((data) => {
        if (data.url?.includes('external-login')) data.label = '';
        return data;
      });
    }
  }

  clickFreeQuote(data: any) {
    this.router.navigate([data.routerLink])
  }

  clickRoute(data: any) {
    const newUrl = `/search/${data.label.toLowerCase()}`;
    this.router.navigate([newUrl]);
  }


  /** Logic on Mobile Menu
   * Separate logic since behaviour are not the same with desktop
  */
   updateOrderMenuMobileList(menuLists: MenuItemPrime[]) {
    let newMenuList: MenuItemPrime[] = [];
    const languageMenu = menuLists.filter((data) => data.styleClass === 'language-wrapper').map((data) => {
      data.label = '';
      if (data.items) {
        data.items.map((item: any) => {
          item.command = () => {
            this.languageService.changeLanguage(item.path);
          }
          return item;
        });
      }
      return data;
    });

    const currency = menuLists.filter((data) => data.styleClass === 'currency-wrapper').map((data) => {
      if (data.items) {
        data.items.map((res: any) => {
          res.command = () => {
            this.currencyService.changeCurrency(res.code);
          }
        });
      }
      return data;
    });
    const user = menuLists.filter((data) => data.styleClass === 'user-wrapper').map((data) => {
      if (data.label === '') {
        data.label = 'Login';
        data.icon = '';
      }
      if (data.label === 'Login') {
        data.command = () => {
          this.openLoginModal();
        };
      }
      if (data.items && data.items.length) {
        data.icon = 'fas fa-caret-down ';
        data.items.map((res) => {
          if (res.label == 'Logout') {
            res.command = () => {
              this.authService.logout();
            };
          }
        });
      }

      return data;
    });
    const treatments = menuLists.filter((data) => data.routerLink == '/treatments')
      .map((data) => {
        data.fragment = JSON.parse(JSON.stringify(data.routerLink));
        data.queryParams = JSON.parse(JSON.stringify(data.routerLink));
        data.command = ($event: any) => {
          if ($event.originalEvent?.target?.classList?.contains('fa-caret-down')) {
            data.expanded = true;
          } else {
            this.router.navigate([data.queryParams]);
          }
        };
        data.routerLink = '';
        return data;
      });
    const locations = menuLists.filter((data) => data.routerLink == '/locations')
      .map((data) => {
        data.fragment = JSON.parse(JSON.stringify(data.routerLink));
        data.queryParams = JSON.parse(JSON.stringify(data.routerLink));
        data.command = ($event: any) => {
          if ($event.originalEvent?.target?.classList?.contains('fa-caret-down')) {
            data.expanded = true;
          } else {
            this.router.navigate([data.queryParams]);
          }
        };
        data.routerLink = '';
        data.items?.map((dataItems) => {
          if (dataItems.items && dataItems.items.length > 0) {
            dataItems.icon = 'p-menuitem-icon fas fa-caret-down';
            dataItems.styleClass = 'locations-item';
          }
          dataItems.fragment = JSON.parse(JSON.stringify(dataItems.routerLink));
          dataItems.queryParams = JSON.parse(JSON.stringify(dataItems.routerLink));
          dataItems.command = ($event: any) => {
            if (!$event.originalEvent?.target?.classList?.contains('p-menuitem-text')) {
              dataItems.expanded = true;
            } else {
              this.router.navigate([dataItems.queryParams]);
            }
          };
          dataItems.routerLink = '';
          dataItems.icon = '';
          return dataItems;
        });
        return data;
      });
    const aboutUs = menuLists.filter((data) => data.routerLink == '/about').map((data) => { data.visible = true; return data; });
    const contact = menuLists.filter((data) => data.routerLink == '/contact');
    let freeQuote: any[] = [];
    let articles: any[] | any = [];
    const [more] = menuLists.filter((data) => data.icon?.includes('more-wrapper')).map((res) => {
      if (res.items) {
        freeQuote = JSON.parse(JSON.stringify(res.items.filter((data) => data.routerLink === '/free-quote')));
        articles = JSON.parse(JSON.stringify(res.items.filter((data) => data.routerLink === '/articles')));
        if (articles.length > 0) {
          articles[0].icon = 'fas fa-caret-down';
          articles[0].fragment = JSON.parse(JSON.stringify( articles[0].routerLink));
          articles[0].queryParams = JSON.parse(JSON.stringify( articles[0].routerLink));
          articles[0].routerLink = '';
        }
        res.items = [...[], ...res.items.map((dataRes: any) => {
          if (dataRes.items && dataRes.items.length > 0) {
            dataRes.icon = '';
            dataRes.fragment = JSON.parse(JSON.stringify(dataRes.routerLink));
            dataRes.queryParams = JSON.parse(JSON.stringify(dataRes.routerLink));
            dataRes.command = ($event: any) => {
              if ($event.originalEvent.target.classList.contains('p-submenu-icon')) {
                dataRes.expanded = true;
              } else {
                this.router.navigate([dataRes.queryParams]);
              }
            };
            dataRes.routerLink = '';
          }
          if (dataRes.routerLink == '/free-quote') {
            dataRes.visible = false;
            dataRes.classList = 'free-quote-hidden';
          }

          if (dataRes.queryParams == '/articles') {
            dataRes.visible = false;
            dataRes.classList = 'free-quote-hidden';
          }

          return dataRes;
        })];

      }
      return res;
    });
    if (articles && articles.length > 0) {
      articles.map((data: any) => {
        data.command = ($event: any) => {
              if ($event.originalEvent.target.className.includes('p-menuitem-icon')) {
                data.expanded = true;
              } else {
                this.router.navigate([data.queryParams]);
              }
            };
        return data;
      });
    }

    const homepage: MenuItemPrime[] = [{
      label: $localize`:@@home:Home`,
      routerLink: '/',
      visible: true
    }];

    newMenuList = [
      ...user,
      ...languageMenu,
      ...currency,
      ...homepage,
      ...freeQuote,
      ...locations,
      ...treatments,
      ...articles,
      ...aboutUs,
      ...contact,
      ...[more],
    ];
    return newMenuList.map((data) => {
      return data;
    });
  }

  openLoginModal() {
    this.accountService.updateCurrentAccountStateModal(this.accountActions.LOGIN)
  }

}


export interface HeaderDataItems {
  menuPrimeNgLists: MenuItemPrime[];
  languageLists: ILanguage[];
  currentLanguage?: ILanguage;
  currentUser?: IUserProfile;
  platform?: 'dental' | 'medical';
}

export interface HeaderLogo {
  mobileUrl?: any;
  desktopUrl?: any;
}
