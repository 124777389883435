import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Params } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from '../../data/core-api.service';
import { LogService } from '../../features/log/log.service';
import { PageService } from '../../features/page/page.service';
import { SchemaService } from '../../features/schema/schema.service';
import { IIdSlugName } from '../../models/generic.models';
import { BreadcrumbItem } from '../../shared/components/breadcrumb/breadcrumb.models';
import { BreadcrumbService } from '../../shared/components/breadcrumb/breadcrumb.service';
import { PagingService } from '../../shared/components/paging/paging.service';
import { PremiumContentService } from '../premium-content/premium-content.service';
import { IClinicSearchResultModel, IClinicStaticSearchResultModel, ISearchResult, ISearchResultLocation, ISearchResultResponse } from './search.models';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  private staglKeendexApi: string;

  private query$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public query: Observable<string> = this.query$.asObservable();

  public queryParams$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public queryParams: Observable<string> = this.queryParams$.asObservable();

  private querySortParams$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public querySortParams: Observable<any> = this.querySortParams$.asObservable();

  private queryFilterParams$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public queryFilterParams: Observable<any> = this.queryFilterParams$.asObservable();

  private searchResult$!: BehaviorSubject<ISearchResult>;
  public searchResult!: Observable<ISearchResult>;
  private isBrowser!: boolean;
  private readonly filterValues: Array<string> = ['value', 'select', 'luxury'];

  constructor(
    @Inject('env') private env: any,
    @Inject(PLATFORM_ID) platformId: any,
    private http: HttpClient,
    private apiService: ApiService,
    private breadcrumbService: BreadcrumbService,
    private premiumContentService: PremiumContentService,
    private pageService: PageService,
    private pagingService: PagingService,
    private schemaService: SchemaService,
    private logService: LogService) {
    this.logService.log(`Search service.init()`);
    this.staglKeendexApi = this.env.api.staglKeendex;
    this.isBrowser = isPlatformBrowser(platformId);
  }

  updateQuery(query: string) {
    this.query$.next(query);
  }

  updateSort(sort: any) {
    this.querySortParams$.next(sort);
  }
  updateFilter(filter: any) {
    this.queryFilterParams$.next(filter);
  }

  // search(query: string, sort: string = 'recommended'): Observable<any> {
  //   const _data = {
  //     meta: {
  //       user_agent: navigator.userAgent,
  //       accept_languages: 'en-US',
  //       server_ip: '0.0.0.0'
  //     },
  //     req: {
  //       query
  //     }
  //   };
  //   return this.http.post(this.staglKeendexApi + 'search?page=1&size=15&sort=' + sort, _data, {
  //     headers: { 'X-SITE-ID': this.env.site_id.toString() }
  //   });
  // }

  // searchPaging(query: string, url: string): Observable<any> {
  //   // TODO: This should be remove
  //   url = url.replace('/keendex/v1/', '');

  //   const _data = {
  //     meta: {
  //       user_agent: navigator.userAgent,
  //       accept_languages: 'en-US',
  //       server_ip: '0.0.0.0'
  //     },
  //     req: {
  //       query
  //     }
  //   };
  //   return this.http.post(this.staglKeendexApi + url, _data, {
  //     headers: { 'X-SITE-ID': this.env.site_id.toString() }
  //   });
  // }

  searchStaticClinicGET(
    treatment: string,
    country: string,
    province: string,
    city: string,
    sort: string = 'recommended',
    page: number = 1,
    size: number = 15,
    filter: string): Observable<ISearchResultResponse> {

    let device = '';
    if (size === 8) { device = '&device=desktop'; }
    if (size === 4) { device = '&device=mobile'; }

    return this
      .http.get<ISearchResultResponse>(`${this.staglKeendexApi}static-clinic-search?treatment=${treatment}&country=${country}&province=${province}&city=${city}&page=${page}&size=${size}&sort=${sort}${device}&filter=${filter}`, {
      headers: { 'X-SITE-ID': this.env.site_id.toString() }
    });
  }

  searchStaticAliasGET(
    alias: string,
    sort: string = 'recommended',
    page: number = 1,
    size: number = 15): Observable<ISearchResultResponse> {
    return this.http.get<ISearchResultResponse>(`${this.staglKeendexApi}static-alias-search?alias=${alias}&page=${page}&size=${size}&sort=${sort}`, {
      headers: { 'X-SITE-ID': this.env.site_id.toString() }
    });
  }

  // prepare(query: string): Observable<any> {
  //   const _data = {
  //     meta: {
  //       user_agent: navigator ? navigator.userAgent : '',
  //       accept_languages: 'en-US',
  //       server_ip: '0.0.0.0'
  //     },
  //     req: {
  //       ss_token: '',
  //       query: query
  //     }
  //   };

  //   return this.http.post(`${this.staglKeendexApi}searchprep`, _data, {
  //     headers: { 'X-SITE-ID': this.env.site_id.toString() }
  //   });
  // }

  suggest(query: string): Observable<any> {
    const _data = {
      meta: {
        user_agent: this.isBrowser ? navigator.userAgent : 'Server',
        accept_languages: 'en-US',
        server_ip: '0.0.0.0'
      },
      req: {
        ss_token: '',
        query: query,
        excludeTypes: [],
        start: 0
      }
    };

    return this.http.post(this.staglKeendexApi + 'suggest?page=1&size=10', _data, {
      headers: { 'X-SITE-ID': this.env.site_id.toString() }
    });
  }

  // last: "/search?page[size]=15&page[number]=13"
  // next: "/search?page[size]=15&page[number]=2"
  // self: "/search?page[size]=15&page[number]=1"

  // https://apidev.thenextdepartures.com/keendex/v1/search?page[number]=1&page[size]=15&sort=recommended
  // https://apidev.thenextdepartures.com/keendex/v1/search?page=&sort=recommended&page[size]=15&page[number]=2

  // last: "/keendex/v1/search?page=&sort=recommended&page%5Bsize%5D=15&page%5Bnumber%5D=8"
  // next: "/keendex/v1/search?page=&sort=recommended&page%5Bsize%5D=15&page%5Bnumber%5D=2"
  // self: "/keendex/v1/search?page%5Bnumber%5D=1&page%5Bsize%5D=15&sort=recommended"

  generateSearchQuery(treatment: string, country: string, province: string, city: string): string {
    let params = '';

    params = this.appendSearchQuery(params, city);
    params = this.appendSearchQuery(params, province);
    params = this.appendSearchQuery(params, country);

    if (treatment !== '') {
      params = `${treatment} ${params}`;
    }

    return params;
  }

  private appendSearchQuery(param: string, value: string) {
    param = param.toLowerCase().trim();
    value = value.toLowerCase().trim();

    if (value.trim() !== '') {
      param =
        param !== ''
          ? param + ', ' + value.replace('-', ' ')
          : value.replace('-', ' ');
    }

    return param;
  }

  buildQuery(
    freeText: string,
    treatment?: IIdSlugName | null,
    country?: IIdSlugName,
    province?: IIdSlugName,
    city?: IIdSlugName,
    district?: IIdSlugName | null,
    subdistrict?: IIdSlugName | null,
    landmark?: IIdSlugName | null,
    clinic?: IIdSlugName | null,
    doctor?: IIdSlugName | null) {
    if (freeText) {
      return freeText.trim().toLowerCase();
    } else {
      let query = '';
      if (doctor) {
        query = doctor.name?.trim().toLowerCase() || '';
      } else if (clinic) {
        query = clinic.name?.trim().toLowerCase() || '';
      } else {
        query = this.appendQuery(query, treatment, ' ');

        // location
        let location = '';
        location = this.appendQuery(location, landmark, ', ');
        location = this.appendQuery(location, subdistrict, ', ');
        location = this.appendQuery(location, district, ', ');
        location = this.appendQuery(location, city, ', ');
        location = this.appendQuery(location, province, ', ');
        location = this.appendQuery(location, country, ', ');
        query = query + ' ' + location;
      }

      return query.trim();
    }
  }

  private appendQuery = (query: string, item?: IIdSlugName | null, separator?: string): string => {
    return (item && item.name && item.name?.trim().toLowerCase() !== 'treatments')
      ? (query !== '')
        ? `${query}${separator}${item.name.trim().toLowerCase()}`
        : item.name.trim().toLowerCase()
      : query;
  }

  private getTreatmentFromUrlParams = (params: Params): IIdSlugName => {
    if (!params) {
      throw new Error('Error in getTreatmentFromUrlParams(): missing params argument.');
    }

    return {
      id: null,
      slug: params['treatment'] ? params['treatment'] : '',
      name: ''
    };
  }

  private getLocationFromUrlParams = (params: Params): ISearchResultLocation => {
    if (!params) {
      throw new Error('Error in getLocationFromUrlParams(): missing params argument.');
    }

    return {
      country: {
        id: null,
        slug: params['countryOrPaging'] && isNaN(params['countryOrPaging']) ? params['countryOrPaging'] : '',
        name: null,
      },
      province: {
        id: null,
        slug: params['provinceOrPaging'] && isNaN(params['provinceOrPaging']) ? params['provinceOrPaging'] : '',
        name: null,
      },
      city: {
        id: null,
        slug: params['cityOrPaging'] && isNaN(params['cityOrPaging']) ? params['cityOrPaging'] : '',
        name: null
      },
      district: null,
      subDistrict: null,
      landmark: null,
      smallest: null,
      fullName: ''
    };
  }

  getStaticAliasSearchResult = async (params: Params, sort?: string, size?: number): Promise<ISearchResult> => {
    const aliasSlug = params.alias;
    const page = this.pagingService.getSearchPageNumberFromUrlParams(params);
    const pageSize = size ? size : 10;
    const sortBy = sort ? sort : 'recommended';

    let result!: ISearchResult;

    await this.searchStaticAliasGET(aliasSlug, sortBy, page, pageSize)
      .toPromise()
      .then((response: any) => {
        try {
          result = {
            raw: response,
            paging: this.pagingService.setPaging(page, pageSize, response.meta.total),
            treatments: [],
            locations: [],
            mainTreatment: null,
            mainLocation: null,
            aliases: response.res.search_criteria.aliases
          };

          this.updateQuery((result.aliases && result.aliases.length > 0) ? result.aliases[0].name_keyword : '');

        } catch (err) { throw err; }
      })
      .catch(error => { throw error; })
      .finally();

    return result;
  }

  getStaticSearchResult = async (params: Params, sort?: string, size?: number, page?: number, filter?: string): Promise<ISearchResult> => {
    const treatmentParam = this.getTreatmentFromUrlParams(params);
    const locationParam = this.getLocationFromUrlParams(params);
    const currentPageNumber  = page ? page : this.pagingService.getSearchPageNumberFromUrlParams(params);
    const pageNumberParam = currentPageNumber;
    const pageSize = size ? size : 10;
    const sortBy = sort ? sort : '';

    return await this.getStaticSearch(
      treatmentParam.slug,
      locationParam.country.slug,
      locationParam.province.slug,
      locationParam.city.slug,
      pageNumberParam,
      pageSize,
      sortBy,
      filter);
  }

  getPremiumContentTop10Clinics = async (treatmentSlug: string,
    countrySlug: string,
    provinceSlug: string,
    citySlug?: string): Promise<ISearchResult> => {
    return await this.getStaticSearch(treatmentSlug, countrySlug, provinceSlug, citySlug, 1, 10);
  }

  processSearchResult = (result: ISearchResult): IClinicSearchResultModel => {
    try {
      let output!: IClinicSearchResultModel;

      if (!result) {
        throw new Error('Error: Unknown search result');
      }

      const clinicFolder = this.env.site_id === 1 ? 'dentist' : 'clinic';

      // if (result.paging.total === 1) {
      //   const singleClinicUrl = `/${clinicFolder}/${result.raw.res.search_result[0].slug}`;
      //   this.logService.log(`There is only 1X clinic founded. Redirecting to ${singleClinicUrl}`);
      //   // this.pageService.responseRedirect(singleClinicUrl, 302);
      // } else {

      output = {
        clinics: result.raw.res.search_result,
        links: result.raw.links,
        meta: result.raw.meta,
        searchCriteria: result.raw.res.search_criteria,
        query: result.raw?.req?.query ?? '',
        pages: Math.ceil(result.raw.meta.total / result.raw.meta.rows_per_page),
        searchResult: result
      };

      // Set selected procedure
      const selectedProcedure = (output.searchResult.raw.res.search_criteria.procedures.length > 0)
        ? output.searchResult.raw.res.search_criteria.procedures[0]
        : null;
      if (selectedProcedure) {
        output.clinics.forEach(clinic => {
          const procedure = Object.assign(
            {
              currency: clinic['currency'],
              name: selectedProcedure.name,
              id: selectedProcedure.id
            },
            clinic['pricing'].find((row: any) => Number(row.procedureId) === selectedProcedure.id)
          );
          clinic['selectedProcedure'] = procedure;
        });
      }
      // }

      return output;
    } catch (err) { throw err; }
  }

  processStaticSearchResult = (result: ISearchResult, canRedirect = true): IClinicStaticSearchResultModel => {
    try {
      if (!result) {
        throw new Error('Error: Unknown static search result');
      }

      let output: IClinicStaticSearchResultModel = {
        clinicFolder: this.env.site_id === 1 ? 'dentist' : 'clinic',
        staticSearchFolder: this.env.site_id === 1 ? 'dentists' : 'clinics',
        clinics: [],
        latestReview: null,
        rankingLocation: result.raw.res.search_criteria.location[0] || null,
        searchResult: result,
        meta: result.raw.meta
      };

      if (output.searchResult.paging?.total === 0) {
        this.pageService.response404(null);
      } else if (output.searchResult.paging?.total === 1 && canRedirect) {
        const singleClinicUrl = `/${output.clinicFolder}/${output.searchResult.raw.res.search_result[0].slug}`;
        this.logService.log(`There is only 1X clinic founded. Redirecting to ${singleClinicUrl}`);
        this.pageService.responseRedirect(singleClinicUrl, 302);
      } else {
        output.clinics = output.searchResult.raw.res.search_result;

        // Set selected procedure
        const selectedProcedure = (output.searchResult.raw.res.search_criteria.procedures.length > 0)
          ? output.searchResult.raw.res.search_criteria.procedures[0]
          : null;
        if (selectedProcedure) {
          output.clinics.forEach(clinic => {
            const procedure = Object.assign(
              {
                currency: clinic['currency'],
                name: selectedProcedure.name,
                id: selectedProcedure.id
              },
              clinic['pricing'].find((row: any) => Number(row.procedureId) === selectedProcedure.id)
            );
            clinic['selectedProcedure'] = procedure;
          });
        }

        // Get latest reviews in the area
        if (output.searchResult?.mainLocation?.smallest) {
          this.apiService.reviewsLatestGET(output.searchResult?.mainLocation?.smallest?.id?.toString()).subscribe(
            (review) => {
              output.latestReview = review;
            },
            (error) => { throw error; }
          );
        }

        this.logService.log('Set SEO');

        const treatment = output.searchResult.raw.meta.treatment ? output.searchResult.raw.meta.treatment : '';
        const treatmentSlug = output.searchResult.raw.meta.treatment ? output.searchResult.raw.meta.treatment : 'treatments';
        const country = output.searchResult.locations[0].country ? output.searchResult.locations[0].country.name : '';
        const countrySlug = output.searchResult.locations[0].country ? output.searchResult.locations[0].country.slug : '';
        const province = output.searchResult.locations[0].province ? output.searchResult.locations[0].province.name : '';
        const provinceSlug = output.searchResult.locations[0].province ? output.searchResult.locations[0].province.slug : '';
        const city = output.searchResult.locations[0].city ? output.searchResult.locations[0].city.name : '';
        const citySlug = output.searchResult.locations[0].city ? output.searchResult.locations[0].city.slug : '';

        const _title = this.premiumContentService.generateStaticSearchTitle(treatment!, province!, city!, country!, 100);
        const _description = this.premiumContentService.generateStaticSearchDescription(treatment === '' ? 'Treatments' : treatment!, city!, country!);

        this.pageService.setTitle(_title);
        this.pageService.setDescription(_description);
        this.pageService.setOG(_title, _description, '');
        this.pagingService.setNavigationLink(output.searchResult.paging!);

        this.logService.log('Set Schema/Structured Data');
        const schema = this.schemaService.getStaticSearchSchema({
          url: [output.staticSearchFolder, treatment, country, province, city].filter(c => c !== '').join('/'),
          title: _title,
          description: _description,
          photo: (output.clinics.length > 0) && (output.clinics[0].photos) ? output.clinics[0].photos[0] : '',
          location: [country, province, city].filter(c => c !== '').join(', '),
          totalCount: output.searchResult.paging!.total!.toString(),
          list: output.clinics.map((c, i) => {
            return {
              position: i + 1,
              slug: `${output.staticSearchFolder}/${c.slug}`,
              name: c.name
            };
          })
        });
        this.pageService.setSchemaHeader(schema, 'static-search-schema');

        this.logService.log('Set breadcrumb');
        const breadcrumb: BreadcrumbItem[] = this.breadcrumbService.setStaticSearchBreadcrumb(output.staticSearchFolder, treatment, treatmentSlug, country!, countrySlug, province!, provinceSlug, city!, citySlug);

        this.pageService.setBreadcrumb(breadcrumb);
      }
      return output;
    } catch (err) { throw err; }
  }
  getSearchResultByClinicCategory = async (query: string, category: string) => {

    let result!: ISearchResult;
    const page = 1;
    const pageSize = 15;
    const sort = 'recommended';

    const searchUrl = `category-search?page=${page}&size=${pageSize}&query=${query}&sort=${sort}&category=${category}`;

    await (this.http.get<ISearchResultResponse>(this.staglKeendexApi + searchUrl
    )).toPromise()
      .then((response: ISearchResultResponse) => {
        result = this.processSearchResultResponse(response, page, pageSize);
      })
      .catch(error => { throw error; })
      .finally();

    return result;
  }

  getSearchResult = async (query: string, sort: string = '', pagingUrl?: string, pageSizeNo?: number | any, filter?: string): Promise<ISearchResult> => {
    let result!: ISearchResult;

    const page = 1;
    const pageSize = pageSizeNo ? pageSizeNo : 15;
    let device = '';
    if (pageSizeNo === 8 || pagingUrl?.includes(`size=8`)) {
      device = '&device=desktop';
    }
    if (pageSizeNo === 4 || pagingUrl?.includes(`size=4`)) {
      device = '&device=mobile';
    }

    const searchUrl = pagingUrl
      ? `${pagingUrl!.replace('/keendex/v1/', '')}`
      : `search?page=1&size=${pageSize}&query=${query}&sort=${sort}&filter=${filter}`;

    await (this.http.get<ISearchResultResponse>(this.staglKeendexApi + searchUrl + device
    )).toPromise()
      .then((response: ISearchResultResponse) => {
        result = this.processSearchResultResponse(response, page, pageSize);
      })
      .catch(error => { throw error; })
      .finally();

    return result;
  }

  checkIsCategoryFilter = (filter: string): boolean => this.filterValues.includes(filter.toLowerCase());


  private getStaticSearch = async (
    treatmentSlug: string,
    countrySlug: string,
    provinceSlug: string,
    citySlug?: string,
    page?: number,
    pageSize?: number,
    sortBy?: string,
    filter?: string): Promise<ISearchResult> => {
    let result!: ISearchResult;

    await this.searchStaticClinicGET(
      encodeURIComponent(treatmentSlug === 'treatments' ? '' : treatmentSlug),
      encodeURIComponent(countrySlug),
      encodeURIComponent(provinceSlug),
      encodeURIComponent(citySlug || ''),
      sortBy,
      page,
      pageSize,
      filter || ''
    ).toPromise()
      .then((response: ISearchResultResponse) => {
        result = this.processSearchResultResponse(response, page, pageSize);
      })
      .catch(error => { throw error; })
      .finally();

    return result;
  }

  private processSearchResultResponse = (
    response: ISearchResultResponse,
    page?: number,
    pageSize?: number): ISearchResult => {
    try {

      let result!: ISearchResult;

      result = {
        raw: response,
        paging: this.pagingService.setPaging(page!, pageSize!, response.meta.total),
        treatments: [],
        locations: [],
        mainTreatment: null,
        mainLocation: null,
        aliases: response.res.search_criteria.aliases
      };

      const metaTreatment = response.meta.treatment;

      response.res.search_criteria.procedures?.forEach(procedure => {
      });

      // result.mainTreatment = {
      //   id: 0,
      //   name: response.meta.treatment,
      //   slug: response.meta.treatment,
      //   i18n: {
      //     language: '',
      //     content: '',
      //     name: ''
      //   }
      // };

      // // treatments
      // // result.treatments.push(treatment);
      // if (result.treatments.length >= 1) {
      //   result.mainTreatment = result.treatments[0].alias
      //     ? result.treatments[0].alias
      //     : result.treatments[0].officialProcedure;

      //   // Set selected procedure price from
      //   if (result.raw.res.search_result.length > 0 && result.treatments[0]) {
      //     result.raw.res.search_result.forEach(clinic => {

      //       clinic.selectedProcedure = null;

      //       if (!clinic.selectedProcedure && result.treatments[0].alias) {
      //         clinic.selectedProcedure = clinic.pricing.find(price => price.procedureId === result.treatments[0].alias.id);
      //       }

      //       if (!clinic.selectedProcedure && result.treatments[0].officialProcedure) {
      //         clinic.selectedProcedure = clinic.pricing.find(price =>
      //           price.procedureId === result.treatments[0].officialProcedure.id);
      //       }

      //       // [OBSOLETED] Should be deprecated but require API to update first
      //       if (!clinic.selectedProcedure
      //         && result.raw.res.search_criteria.procedures
      //         && result.raw.res.search_criteria.procedures.length > 0) {
      //         clinic.selectedProcedure = clinic.pricing.find(price =>
      //           price.procedureId === result.raw.res.search_criteria.procedures[0].id);
      //       }

      //       if (!clinic.selectedProcedure) {
      //         clinic.selectedProcedure = null;
      //       }
      //     });
      //   }
      // }

      // treatments
      result.treatments = [];
      if (result.raw.res.search_criteria.aliases.length > 0
        && (result.raw.res.search_criteria.aliases[0].type === 303
          || result.raw.res.search_criteria.aliases[0].type <= 323)) {
        this.logService.log(result.raw.res.search_criteria.aliases[0].name_keyword);
      }

      // locations
      result.locations = [];
      const location: any = {
        country: result.raw.res.search_criteria.location.filter(c => c.type === 'country')[0],
        province: result.raw.res.search_criteria.location.filter(c => c.type === 'province')[0],
        city: result.raw.res.search_criteria.location.filter(c => c.type === 'city')[0],
        district: result.raw.res.search_criteria.location.filter(c => c.type === 'district')[0],
        subDistrict: result.raw.res.search_criteria.location.filter(c => c.type === 'subdistrict')[0],
        landmark: result.raw.res.search_criteria.location.filter(c => c.type === 'landmark')[0],
        smallest: null,
        fullName: ''
      };

      // set smallest
      location.smallest = (location.landmark) ? location.landmark
        : (location.subDistrict) ? location.subDistrict
          : (location.district) ? location.district
            : (location.city) ? location.city
              : (location.province) ? location.province
                : (location.country) ? location.country
                  : null;

      // set full name
      location.fullName = [
        location.landmark ? location.landmark.name : '',
        location.subDistrict ? location.subDistrict.name : '',
        location.district ? location.district.name : '',
        location.city ? location.city.name : '',
        location.province ? location.province.name : '',
        location.country ? location.country.name : ''].filter(Boolean).join(', ');

      result.locations.push(location);
      if (result.locations.length >= 1) {
        result.mainLocation = result.locations[0];
      }

      this.updateQuery(this.buildQuery(
        '',
        result.mainTreatment,
        result.mainLocation?.country,
        result.mainLocation?.province,
        result.mainLocation?.city,
        result.mainLocation?.district,
        result.mainLocation?.subDistrict,
        result.mainLocation?.landmark,
        null,
        null));

      return result;
    } catch (err) { throw err; }
  }
}
