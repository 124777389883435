import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonService } from '../../../core/src/lib/common/common.service';
import { LogService } from '../../../core/src/lib/features/log/log.service';
import { PageService } from '../../../core/src/lib/features/page/page.service';
import { SchemaService } from '../../../core/src/lib/features/schema/schema.service';
import { NavigationEnd, Router, Event } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AuthService } from '@lib/auth/auth.service';
import {
  AttachJsScriptsService,
} from '@lib/shared-core/helper/attach-js-scripts.service';
import { BODY_SCRIPTS, HEAD_SCRIPTS } from './constants/injectable-3rd-party-js.scripts.constant';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  whatsAppGeolocationLinks: {
    country: string,
    link: string
  }[] = [];

  constructor(
    public commonService: CommonService,
    private pageService: PageService,
    private schemaService: SchemaService,
    private logService: LogService,
    private router: Router,
    private authService: AuthService,
    private attachJsScriptsService: AttachJsScriptsService
    ) {

    router.events.pipe(
      filter((event: Event) => event instanceof NavigationEnd)
    ).subscribe((event) => {
      if (!authService.currentUserValue && authService.hasMedidToken) {
        authService.refreshCurrentUser();
      }
    });
  }

  ngOnInit() {
    this.attachJsScriptsService.triggerScripts(HEAD_SCRIPTS, BODY_SCRIPTS);

    this.logService.log('APP.init()');

    this.whatsAppGeolocationLinks = [
      // https://gitlab.com/mdcore/celsus/-/issues/3254
      // Number for all geographies +1(888)9302112
      { country: '', link: 'https://wa.link/fl05tq' }
    ];

    const organizationSchema = this.schemaService.getOrganizationSchema();
    this.pageService.setSchemaHeader(organizationSchema, 'organization-schema');

    const websiteSchema = this.schemaService.getWebsiteSchema();
    this.pageService.setSchemaHeader(websiteSchema, 'website-schema');
  }

  ngOnDestroy(): void {
    this.logService.log('APP.destroy()');
  }
}
