import {
  Component,
  EventEmitter, Inject,
  Input,
  Output,
  PLATFORM_ID
} from '@angular/core';
import { MenuItem as MenuItemPrime } from 'primeng/api/menuitem';
import { isPlatformBrowser } from '@angular/common';
import { SearchService } from '@lib/modules/search/search.service';

@Component({
  selector: 'app-options-header',
  templateUrl: './options-header.component.html',
  styleUrls: ['./options-header.component.scss']
})
export class OptionsHeaderComponent {

  private _menuPrimeItems: MenuItemPrime[] | any[] = [];
  @Input()
  set menuPrimeItems(menuPrimeItems: MenuItemPrime[] | any[]) {
    if (menuPrimeItems && !this.hasMenuPrimeItems) {
      const menuListsPrimeItems = [...[], ...this.iconMatcher(JSON.parse(JSON.stringify(menuPrimeItems)))];
      this._menuPrimeItems = menuListsPrimeItems.filter((data) => data.icon);
      this._menuPrimeItems = [...[], ...this._menuPrimeItems, ...this.otherMenuNewHeaders()];
      this.config = this.swiperConfig();
      this.hasMenuPrimeItems = true;
    }
  }

  hasMenuPrimeItems = false;
  get menuPrimeItems(): MenuItemPrime[] | any[] {
    return this._menuPrimeItems;
  }

  @Input()
  platform: 'dental' | 'medical' | any = '';

  @Output()
  onClickEvent: EventEmitter<any> = new EventEmitter<any>();

  responsiveOptions: any[] = [];
  config!: any;
  disabled: boolean = false;
  isReady: boolean = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private searchService: SearchService
  ) {
    if (isPlatformBrowser(platformId)) {
      this.config = this.swiperConfig();
      this.isReady = true;
    }
  }

  onClick(item: any) {
    this.onClickEvent.emit(item);
    this.config.direction = 'horizontal';
  }


  swiperConfig(): any {
    return {
      a11y: { enabled: true },
      updateOnWindowResize: true,
      direction: 'horizontal',
      slidesPerView: 10,
      keyboard: false,
      mousewheel: false,
      scrollbar: false,
      navigation: true,
      pagination: false,
      breakpoints: {
        120: {
          slidesPerView: 3.5,
          spaceBetween: 2
        },
        420: {
          slidesPerView: 4,
          spaceBetween: 2
        },
        640: {
          slidesPerView: 6,
          spaceBetween: 20
        },
        1024: {
          slidesPerView: 8,
          spaceBetween: 20
        },
        1670: {
          slidesPerView: 10,
          spaceBetween: 20
        }
      }
    };
  }


  otherMenuNewHeaders() {
    return [
      {
        icon: 'fa-snap-on-dentures',
        items: '',
        routerLink: '/dentists/snap-on-denture-supported-over-4-implants',
        visible: true,
        label: 'Snap-on Dentures'
      },
      {
        icon: 'fa-sinus',
        items: '',
        routerLink: '/dentists/sinus-lifting',
        visible: true,
        label: 'Sinus Lifting'
      },
      {
        icon: 'fa-z-implants',
        items: '',
        routerLink: '/dentists/zygomatic-implants',
        visible: true,
        label: 'Zygomatic Implants'
      },
      {
        icon: 'fa-clear-braces',
        items: '',
        routerLink: '/dentists/clear-braces',
        visible: true,
        label: 'Clear braces'
      },
      {
        name: `Root Canal`,
        slug: 'root-canal',
        visible: true,
        items: '',
        routerLink: '/dentists/root-canal',
        icon: 'fa-root-canal',
        label: 'Root Canal'
      },
      {
        name: `Whitening`,
        slug: 'whitening',
        items: '',
        routerLink: '/dentists/whitening',
        icon: 'fa-teeth-cleaning',
        label: 'Whitening'
      },
      {
        icon: 'fa-dental-fillings',
        items: '',
        routerLink: 'info/fillings',
        visible: true,
        label: 'Fillings'
      },
      {
        icon: 'fa-cleaning',
        items: '',
        routerLink: 'teeth-cleaning',
        visible: true,
        label: 'Teeth Cleaning'
      },
      {
        icon: 'fa-deep-cleaning',
        items: '',
        routerLink: '/dentists/deep-cleaning-scaling-root-planing-per-quadrant',
        visible: true,
        label: 'Deep Cleaning'
      },
      {
        icon: 'fa-tooth-extract',
        items: '',
        routerLink: '/dentists/tooth-extraction',
        visible: true,
        label: 'Extractions'
      },
      {
        icon: 'fa-bone-graft',
        items: '',
        routerLink: '/dentists/bone-graft-large',
        visible: true,
        label: 'Bone Graft'
      },
      {
        icon: 'fa-ct-scan',
        items: '',
        routerLink: '/dentists/ct-scan-3d-x-ray',
        visible: true,
        label: 'CT Scan'
      },
      {
        icon: 'fa-x-rays',
        items: '',
        routerLink: '/dentists/x-rays',
        visible: true,
        label: 'X-Ray'
      },
      {
        icon: 'fa-teeth-gas',
        items: '',
        routerLink: '/dentists/gas-sedation-nitrous-oxide',
        visible: true,
        label: 'Sedation (Nitrous)'
      },
    ];

  }
  iconMatcher(items: MenuItemPrime[] | any[]) {
    let menuPrimeItemsCloned: any[] = [];
    if (this.platform == 'dental') {
      menuPrimeItemsCloned = items.map((data: any, index: any) => {
        if (data.routerLink.toLowerCase().includes('/all-on-4'.toLowerCase())) {
          data.icon = 'fa-all-for-number';
        }
        if (data.routerLink.toLowerCase().includes('/all-on-6'.toLowerCase())) {
          data.icon = 'fa-all-for-six';
        }
        else if (data.routerLink.toLowerCase().includes('/crowns'.toLowerCase())) {
          data.icon = 'fa-dental-crowns';
        }
        else if (data.routerLink.toLowerCase().includes('/veneers'.toLowerCase())) {
          data.icon = 'fa-veneers';
        }
        else if (data.routerLink.toLowerCase().includes('/dental-implants'.toLowerCase())) {
          data.icon = 'fa-dental-implants';
        }
        else if (data.routerLink.toLowerCase().includes('/dentures'.toLowerCase())) {
          data.icon = 'fa-dentures';
        }
        else if (data.routerLink.toLowerCase().includes('/fillings'.toLowerCase())) {
          data.icon = '';
        }
        else if (data.routerLink.toLowerCase().includes('/whitening'.toLowerCase())) {
          data.icon = 'fa-teeth-cleaning';
        }
        return data;
      });
      return menuPrimeItemsCloned;
    }
    return items;
  }

  public toggleKeyboardControl(): void {
    this.config.keyboard = !this.config.keyboard;
  }

  public toggleMouseWheelControl(): void {
    this.config.mousewheel = !this.config.mousewheel;
  }

  public onIndexChange(index: number): void { }
  public onSwiperEvent(event: string): void { }

  resetSortAndFilter() {
    this.searchService.updateSort(null);
    this.searchService.updateFilter(null);
  }
}
