<div class="swiper-wrapper-carousel">
    <swiper-container #swiper class="swiper-container" *ngIf="'photo-only' == type || 'photo-modal-only' == type"
        [ngClass]="customWrapper" fxFlex="auto" navigation="true" customSwiper [config]="config" [disabled]="disabled"
        (activeindexchange)="onIndexChange($event)" (swipertransitionstart)="onSwiperEvent('transitionStart')"
        (swipertransitionend)="onSwiperEvent('transitionEnd')">
        <swiper-slide *ngFor="let item of items; let i = index;" class="swiper-slide">
          <div fxLayout="column" fxLayoutAlign="center center" fxFlexFill>
                <ng-container *ngIf="'photo-only' == type">
                    <div class="photo photo-wrapper" (click)='onClickEvent.emit(true)'>
                        <img ngSrc="{{item.image}}" alt="{{item.clinicName}}" fill [priority]="i === 0">
                    </div>
                </ng-container>
                <ng-container *ngIf="'photo-modal-only' == type">
                    <div class="photo" (click)='onClickEvent.emit(item.index)'
                        style="background-image: url({{item.image}});">
                    </div>
                </ng-container>
            </div>
        </swiper-slide>
    </swiper-container>
    <ng-container *ngIf="hasTotalSlide">
        <div class="total-slide-wrapper">
            {{currentIndex + 1}} / {{ items ? items.length : 0 }}
        </div>
    </ng-container>
</div>

<div [class]="customWrapper" *ngIf="'photo-only' != type && 'photo-modal-only' != type">
    <p-carousel [value]="items" [indicatorStyleClass]='"circular"' [showIndicators]="showIndicators"
        [showNavigators]="showNavigators" [numVisible]="1" [autoplayInterval]='autoPlayInterval' [numScroll]="1"
        [responsiveOptions]="responsiveOptions">
        <ng-container *ngIf="'photo' == type">
            <ng-template let-item pTemplate="items">
                <div class="photo" style="background-image: url({{item.image}});" (click)='onClickEvent.emit(true)'>
                </div>
            </ng-template>
        </ng-container>
        <!-- <ng-container *ngIf="'photo-modal-only' == type">
            <ng-template let-item let-i="index" pTemplate="items">
                <div class="photo" (click)='onClickEvent.emit(item.index)'
                    style="background-image: url({{item.image}});">
                </div>
            </ng-template>
        </ng-container> -->
        <ng-container *ngIf="'clinic' == type">
            <ng-template let-item pTemplate="items">
                <app-card-departures [clinic]="item" class="clinic"></app-card-departures>
            </ng-template>
        </ng-container>
    </p-carousel>
</div>
