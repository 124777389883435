import { IAttachJsScript } from '@lib/shared-core/helper/attach-js-scripts.service';

export const HEAD_SCRIPTS: Array<IAttachJsScript> = [
  {
    type: 'text/javascript',
    hasSrc: true,
    src: 'https://salesiq.zoho.com/widget',
    hasId: true,
    id: 'zsiqscript',
  },
  {
    type: 'text/javascript',
    hasSrc: true,
    src: '//4433.tctm.co/t.js',
  },
  {
    type: '',
    hasTextContent: true,
    textContent: `(function (w, d, s, l, i) {
  w[l] = w[l] || []; w[l].push({
    'gtm.start':
      new Date().getTime(), event: 'gtm.js'
  }); var f = d.getElementsByTagName(s)[0],
    j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
    'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
})(window, document, 'script', 'dataLayer', 'GTM-WQQ4DB');`
  },
  {
    type: 'text/javascript',
    hasSrc: true,
    src: 'https://accounts.google.com/gsi/client',
  },
  {
    type: 'text/javascript',
    hasSrc: true,
    src: 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js',
  }
];

export const BODY_SCRIPTS: Array<IAttachJsScript> = [
  {
    type: 'text/javascript',
    hasSrc: true,
    src: 'https://crm.zoho.com/crm/javascript/zcga.js',
  },
  {
    type: 'text/javascript',
    hasId: true,
    id: 'zsiqchat',
    hasTextContent: true,
    textContent: `var $zoho = $zoho || {}; $zoho.salesiq = $zoho.salesiq || { widgetcode: "6d9bfec226ae552c4d07bb4d3857e73e7e79dce73aa1e78ab48bbca6f686255dc1917e5fdca22c384739309bcf5f6aa1", values: {}, ready: function () { } }; var d = document; s = d.createElement("script"); s.type = "text/javascript"; s.id = "zsiqscript"; s.defer = true; s.src = "https://salesiq.zoho.com/widget"; t = d.getElementsByTagName("script")[0]; t.parentNode.insertBefore(s, t);`
  }
];
