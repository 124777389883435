import { Component, HostListener, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { NavigationStart, Router } from '@angular/router';
import { ILanguage } from '@lib/common/common.models';
import { MenuItem } from '@lib/models/menu-item.model';
import { IReview } from '@lib/shared-core/models/review.model';
import { IPromotionLayout } from '../promotions/promotions.component';
import { WhyDeparturesData } from '../why-departures/why-departures.component';
import { FOOTER_DISPLAY_STATE, PageLayoutService } from '@lib/features/page-layout/page-layout.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthService } from '@lib/auth/auth.service';
import { AccountService } from '@lib/features/account/account.service';
import { AccountAction } from '@lib/models/account.models';
import { LanguageService } from '@lib/features/language/language.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NewsletterService } from '@lib/features/newsletter/newsletter.service';
import { RecaptchaService } from '@lib/features/recaptcha/recaptcha.service';
import { RecaptchaActions } from '@lib/features/recaptcha/recaptcha.models';
import { MessageService } from 'primeng/api';

@UntilDestroy()
@Component({
    selector: 'app-footer-v2',
    templateUrl: './footer-v2.component.html',
    styleUrls: ['./footer-v2.component.scss']
})
export class FooterV2Component implements OnInit {


    @Input()
    subscriptionTitle = 'Subscribe to our newsletter';

    @Input()
    footerHeaderDataItems: FooterHeaderDataItems | undefined;

    @Input() promotions: IPromotionLayout[] = [];


    @Input() whyInfo: WhyDeparturesData[] = [];

    @Input() latestReview!: IReview;
    whyTitle: any = $localize`:@@whyUs:Why Dental Departures`;
    reviewsTitle: any = $localize`:@@latestReview:Latest Reviews`;
    links = [ $localize`:@@terms:terms`, $localize`:@@sitemaps:sitemap`,  $localize`:@@privacy:privacy`];

    isSubmitting: boolean = false;
    isScrollingDown = true;
    lastScrollPosition = 0;
    hasOtherFooters: boolean = false;
    isDentistPage: boolean = false;
    isLoadedDentistPage: boolean = false;
    user: any;
    accountActions = AccountAction;
    newsletterSignUpForm!: UntypedFormGroup;
    toggleFooter$!: Observable<boolean>;
    toggleSmallFooter$!: Observable<boolean>;
    toggleHasOtherFooter$!: Observable<boolean>;

    isBookingDetails: boolean = false;
    platformWindow = isPlatformBrowser(this.platformId) ? window : this.dom;

    currentYear = new Date().getFullYear();


  @HostListener('window:scroll', [])
    onWindowScroll() {
      const pageYOffset = isPlatformBrowser(this.platformId) ? window.pageYOffset : this.dom.defaultView?.pageYOffset;
      const currentScrollPosition = pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
      this.isScrollingDown = currentScrollPosition > this.lastScrollPosition;
      this.lastScrollPosition = currentScrollPosition;
      this.addPositioningOnZohoChat();
    }
  constructor(
      @Inject(PLATFORM_ID) private platformId: any,
      @Inject(DOCUMENT) private dom: Document,
      private router: Router,
      private pageLayoutService: PageLayoutService,
      private authService: AuthService,
      public accountService: AccountService,
      private languageService: LanguageService,
      private fb: UntypedFormBuilder,
      private newsletterService: NewsletterService,
      private recaptchaService: RecaptchaService,
      private messageService: MessageService
    ) {
    this.user = this.authService.currentUser.pipe((untilDestroyed(this))).subscribe((data)=> {
      this.user = data;
    });
    this.toggleFooter$ = pageLayoutService.toggleFooter$
      .pipe(map( s => {
        if (s !== FOOTER_DISPLAY_STATE.SHOW_FOOTER) {
          this.dom.body.style.minHeight = 'unset';
        }
        return s === FOOTER_DISPLAY_STATE.SHOW_FOOTER;
      }));

    this.toggleSmallFooter$ = pageLayoutService.toggleFooter$
      .pipe(map( s => s === FOOTER_DISPLAY_STATE.SHOW_ONLY_SMALL_FOOTER));
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.hasOtherFooters = false;
                this.isDentistPage = false;
                this.hasOtherFootersAvailable(event.url);
            }
        });
    }

    ngOnInit(): void {

      this.newsletterSignUpForm = this.fb.group({
        email: ['', [Validators.required, Validators.email]],
        refurl: [''],
        category: [''],
        'g-recaptcha-response': ['']
      });

      const hrefLocation = this.platformWindow.location.href;
      this.hasOtherFootersAvailable();
      this.pageLayoutService.toggleHasOtherFooter$.pipe(untilDestroyed(this)).subscribe((data)=> {
        this.hasOtherFooters = data;
      });
      this.pageLayoutService.toggleHasLoadedClinic$.pipe(untilDestroyed(this)).subscribe((data)=> {
        this.isLoadedDentistPage = false;
        if(data) {
          this.isLoadedDentistPage = true;
        }
      });
      this.addPositioningOnZohoChat();
    }

    addPositioningOnZohoChat() {
      try {
        const [ chatFloat ] = this.dom.getElementsByClassName('zsiq_floatmain') as any;
        if (this.isScrollingDown && !this.isDentistPage) {
          if (chatFloat) {
            chatFloat.classList.add('zsiq_floatmain-positioning');
          }
        } else {
          if (chatFloat) {
            chatFloat.classList.remove('zsiq_floatmain-positioning');
          }
        }

      } catch (e) {

      }
    }

    bookClinic() {
        this.pageLayoutService.setToggleBooking(true);
    }

    contactClinic() {
      this.pageLayoutService.setToggleContactClinic(true);
    }

    clickUrl(data: any) {
      if (data.isExternalLink) {
        isPlatformBrowser(this.platformId) ? window.open(data.link) : this.dom.defaultView?.open();
      } else {
        if ('path' in data) {
          this.languageService.changeLanguage(data.path);
        } else {
            this.router.navigate([data.link]);
        }
      }
    }

    hasOtherFootersAvailable(url?: any) {
      this.isBookingDetails = false;
      const hrefLocation = url ? url : this.platformWindow.location.pathname;
      this.hasOtherFooters = false;
      if (hrefLocation == '/' || this.platformWindow.location.pathname == '/') {
        this.hasOtherFooters = false;
        if (hrefLocation != '/') {
          this.hasOtherFooters = true;
        }
      } else if (!hrefLocation.includes('search')) {
        this.hasOtherFooters = true;
        if (!hrefLocation.includes('/dentists/treatments/') && hrefLocation.includes('dentist/') ){
          this.hasOtherFooters = false;
        }
        if (hrefLocation.includes('/dentist/')){
          this.hasOtherFooters = false;
        }
        if (hrefLocation.includes('contact') || (hrefLocation.includes('/info/'))) {
          this.hasOtherFooters = true;
        }
        else if (!hrefLocation.includes('/book/dentist/')) {
          this.hasOtherFooters = false;
        }
      }

      if (hrefLocation.includes('/book/dentist/') || hrefLocation.includes('/book/confirm/')) {
        this.isBookingDetails = true;
      }
      if (hrefLocation && (hrefLocation.includes('/clinic/'))) {
        this.isDentistPage = true;
        this.hasOtherFooters = false;
      }
      if (hrefLocation && (hrefLocation.includes('/dentist/'))) {
        this.isDentistPage = true;
        this.hasOtherFooters = false;
      }

      if (hrefLocation && (hrefLocation.includes('/dentists/'))) {
        this.isDentistPage = false;
        this.hasOtherFooters = false;
      }

      if (hrefLocation && (hrefLocation.includes('/search/'))) {
        this.isDentistPage = false;
        this.hasOtherFooters = false;
      }

      if (hrefLocation && (hrefLocation.includes('/about/faqs'))) {
        this.hasOtherFooters = true;
      }

      if (hrefLocation && (hrefLocation.includes('/about'))) {
        this.hasOtherFooters = true;
      }

      if (hrefLocation && (hrefLocation.includes('/contact'))) {
        this.hasOtherFooters = true;
      }

      if (hrefLocation && (hrefLocation.includes('/guarantee') || hrefLocation.includes('/privacy'))  || hrefLocation.includes('/terms')
      || hrefLocation.includes('/media-room') || hrefLocation.includes('/free-quote') || ( hrefLocation.includes('/treatments') && !hrefLocation.includes('/dentists/treatments/'))
      || hrefLocation.includes('/insurance') || hrefLocation.includes('/finance') || hrefLocation.includes('/global-patients-choice/')
      || hrefLocation.includes('/popular-countries') || hrefLocation.includes('/popular-cities')
      || hrefLocation.includes('/articles') || hrefLocation.includes('/promotions')) {
        this.hasOtherFooters = true;
      }

      this.isLoadedDentistPage = false;
    }

    navigateTo(isUserAccount?: boolean) {
      if (this.user) {
        if (isUserAccount) {
          this.platformWindow.location.href = this.authService.generateAccountLink() + '/profile';
        } else {
          this.platformWindow.location.href = this.authService.generateAccountLink() + '/favorites';
        }
      } else {
        this.accountService.updateCurrentAccountStateModal(this.accountActions.LOGIN);
      }
    }

    openLogin() {
      this.accountService.updateCurrentAccountStateModal(this.accountActions.LOGIN);
    }

    onSubmit() {

      if (!this.newsletterSignUpForm.valid) {
        return;
      }
      this.isSubmitting = true;
      this.recaptchaService.generateToken(RecaptchaActions.NewsletterSubscribe).subscribe((token: any) => {
        this.newsletterSignUpForm.patchValue({ 'g-recaptcha-response': token });
        this.newsletterService.subscribe(this.newsletterSignUpForm.value, token).subscribe(
          res => {
            this.isSubmitting = false;
            this.router.navigate(
              ['/mail/signup'],
              { queryParams: { token: res['email_id'], email: this.newsletterSignUpForm.value['email'] } }
            );
          }, err => {
            this.isSubmitting = false;
            alert($localize`:@@newsletterSubscribeErrors:`);
          }
        )
      });
    }

}

export interface FooterHeaderDataItems {
    logo: any;
    description: any;
    footerSiteName: any;
    awards: any[];
    items: FooterSection[];
    socialMenu?: MenuItem[];
    languageLists?: ILanguage[];
}

export interface FooterSection {
    title: any | string;
    footerItems: FooterItems[];
}


export interface FooterItems extends MenuItem {
}
