import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, PLATFORM_ID } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from '@lib/auth/auth.service';
import { Registration, RegistrationResponse, UserSignIn } from '@lib/models/user.model';
import { ApiErrorResponse } from '@lib/models/api-response.model';
import { EmailService } from '@lib/features/email/email.service';
import { CustomValidators } from '@lib/shared/components/form/custom-validators';
import { AccountAction, RegisterState } from '@lib/models/account.models';
import { AccountService } from '@lib/features/account/account.service';
import { UserActivities, UserActivityArg } from '@lib/constants/user-activities';
import { AppleUserInfo, GoogleSignInResponse } from '@lib/auth/auth.model';
import { AuthAppleService } from '@lib/auth/auth.apple.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FORM_CONSTANTS } from '@lib/shared-core/helper/form-helper/constants';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, OnDestroy {

  @Input() canSignInWithApple!: boolean;

  @Input()
  isInModalMode: boolean = false;

  @Output() userActivity: EventEmitter<UserActivityArg> = new EventEmitter<UserActivityArg>();

  isEmailRegistered = false;
  isExternalRegistered = false;
  registeredEmail = '';
  registerForm!: UntypedFormGroup;
  processing = false;
  signUpError!: string;

  source!: string;
  emailPattern = FORM_CONSTANTS.emailPattern;
  customValidators: CustomValidators;
  showPassword!: boolean;
  modalAccountAction = AccountAction;
  onClickAppleEvent$ = new Subject();
  private hasSubmitted = false;

  constructor(
    private authService: AuthService,
    private emailService: EmailService,
    private accountService: AccountService,
    private authAppleService: AuthAppleService,
    @Inject('env') private env: any,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
    this.customValidators = new CustomValidators();

  }

  ngOnInit() {
    this.authService.initializeGoogleAccountsId();
    this.emitUserActivity(
      {
        activePage: UserActivities.Register.Name,
        activeActivity: UserActivities.Register.Landed
      }
    );

    // Initialize form
    this.registerForm = new UntypedFormGroup({
      email: new UntypedFormControl('', [Validators.required,
      Validators.maxLength(256), Validators.pattern(this.emailPattern)]),
      social_email: new UntypedFormControl('', [Validators.email]),
      password: new UntypedFormControl('',
        [Validators.required, Validators.minLength(6),
        Validators.maxLength(256)]),
      first_name: new UntypedFormControl('',
        [Validators.required, Validators.maxLength(256), this.customValidators.checkWhiteSpace()]),
      last_name: new UntypedFormControl('',
        [Validators.required, Validators.maxLength(256), this.customValidators.checkWhiteSpace()]),
      provider: new UntypedFormControl(''),
      user_id: new UntypedFormControl(''),
      avatar: new UntypedFormControl(''),
      accepted_term_id: new UntypedFormControl(+this.env.api.accepted_term_id)
    });


    this.registerWithGoogleV2();

    /**
     * Subscribe Click Event for Apple ID Register
     * */
    this.authAppleService.$appleSignInEvent
      .pipe(takeUntil(this.onClickAppleEvent$))
      .subscribe(async (data: any) => {
        if (data && data === 'REGISTER') {
          this.authAppleService.setSignInEvent(null);
          await this.registerInWithApple();
        }
      });

  }

  registerWithGoogleV2() {
    this.authService.signInWithGoogleResponse
      .subscribe((res: GoogleSignInResponse | null) => {
        if (res && !this.processing) {
          // perform normal login
          const user: any = {
            email: res.email,
            provider: 'GOOGLE',
            id: res.sub,
            name: res.name,
            photoUrl: res.picture,
            firstName: res.given_name,
            lastName: res.family_name,
          } as any;
          this.fillInFormWithSocialUser(user, 'GOOGLE');
          this.onSubmit();
        }
      });
  }

  async registerInWithApple() {
    const appleUserInfo: AppleUserInfo | null = await this.authAppleService.appleSignIn();
    if (appleUserInfo) {
      const { success } = await this.emailService.isExists(appleUserInfo.email).toPromise();
      if (success) {
        const user: UserSignIn = {
          email: appleUserInfo.email,
          provider: 'APPLE',
          user_id: appleUserInfo.sub,
        };
      } else {
        this.fillInFormWithSocialUser(this.authAppleService.mapAppleUserToSocialUser(appleUserInfo), 'APPLE');
        this.onSubmit();
      }
    }
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }

  onSubmit() {
    this.processing = true;
    const value = this.registerForm.value;
    if (!value.email && value.social_email) {
      value.email = value.social_email;
      delete value.social_email;
    }
    this.hasSubmitted = true;

    const reg: Registration = value as Registration;

    this.authService.register(reg).subscribe(
      (res: RegistrationResponse) => {
        this.processing = false;
        if (res.success) {
          this.accountService.toggleRegisterState(RegisterState.SUCCESS);
          this.emitUserActivity(
            {
              activePage: UserActivities.Register.Name,
              activeActivity: UserActivities.Register.SubmittedRegister
            }
          );
          this.isEmailRegistered = true;
          if (reg.provider === '' && reg.user_id === '') {
            this.isExternalRegistered = false;
            this.registeredEmail = reg.email;
          } else {
            this.isExternalRegistered = true;
          }
        } else {
          // do something else.. need a case
        }
      },
      (err: ApiErrorResponse | any) => {
        this.emitUserActivity(
          {
            activePage: UserActivities.Register.Name,
            activeActivity: UserActivities.Register.ErrorSubmitting
          }
        );
        this.registerForm.reset();
        this.authService.resetGoogleResponse();
        this.signUpError = err && err.error ? err.error.error : 'Error processing data. Please try again later.';
        alert(err.error.error.message || err.error.error);
        this.processing = false;
      },
      () => { }
    );
  }

  private fillInFormWithSocialUser(user: any, provider: string) {
    (<UntypedFormControl>this.registerForm.get('email')).disable();
    this.registerForm.patchValue({
      email: user.email,
      social_email: user.email,
      first_name: user.firstName,
      last_name: user.lastName,
      provider,
      user_id: user.id,
      avatar: user.photoUrl,
      password: ''
    });
  }

  toggleActionState(state: AccountAction) {
    this.accountService.toggleAccountAction(state);
  }

  emitUserActivity(userActivityArg: UserActivityArg) {
    this.userActivity.emit(userActivityArg);
  }

  ngOnDestroy() {
    if (isPlatformBrowser(this.platformId) && !this.hasSubmitted) {
      this.emitUserActivity(
        {
          activePage: UserActivities.Register.Name,
          activeActivity: UserActivities.Register.NotSubmittedRegister
        }
      );
    } else {
      this.hasSubmitted = false;
    }

    this.onClickAppleEvent$.next();
    this.onClickAppleEvent$.complete();
  }



  updateAccountState(type: any) {
    if (this.isInModalMode) {
        this.accountService.updateCurrentAccountStateModal(type);
    } else {
        this.accountService.updateCurrentAccountState(type);
    }
}
}
