import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AccountService } from '@lib/features/account/account.service';
import { FavoriteService } from '@lib/features/favorite/favorite.service';
import { AccountAction } from '@lib/models/account.models';
import { DeleteUserFavorite, PostUserFavorite, PutUserFavorite, UserFavoritesCategoryDetails } from '@lib/models/favorite.model';
import { untilDestroyed } from '@ngneat/until-destroy';

@Component({
    selector: 'app-favorite-core',
    templateUrl: './favorite-core.component.html',
    styleUrls: ['./favorite-core.component.scss']
})
export class FavoriteCoreComponent implements OnInit {

    @Input()
    isFavorite: boolean = false;

    @Input()
    currentUser: any;

    @Input()
    hasLabel: boolean = false;

    @Input()
    iconOnly: boolean = false;

    private _favoriteClinicCore: FavoriteClinicCore | any;
    @Input()
    set favoriteClinicCore(favoriteClinicCore: FavoriteClinicCore) {
        this._favoriteClinicCore = favoriteClinicCore;
        if (favoriteClinicCore) {
            const { favorites, clinicData } = favoriteClinicCore;
            if (clinicData) {
                this.favorites = favorites;
                this.mapperFavoriteData(clinicData);
            }
        }
    }

    get favoriteClinicCore() {
        return this._favoriteClinicCore;
    }

    form!: UntypedFormGroup;

    isModalVisible: boolean = false;
    accountActions = AccountAction;
    newItem: boolean = false;
    favorites: any;
    favoriteData!: CoreFavorite;
    isLoadingUpdateFavorite: boolean = false;
    save = $localize`:@@save:Save`;
    saved = $localize`:@@saved:Saved`;

    constructor(private accountService: AccountService,
        private fb: UntypedFormBuilder,
        private favoriteService: FavoriteService) { }

    ngOnInit() {
        this.form = this.fb.group({
            name: ['', Validators.required]
        });
    }


    async toggleFavorite() {
        if (this.currentUser) {
            if (!this.isFavorite) {
                this.isModalVisible = true;
            } else {
                const { favorite_id, is_legacy_clinic, clinic_id } = this.favoriteData;
                const payload: DeleteUserFavorite = {
                    clinic_id: clinic_id,
                    favorite_id: favorite_id as any
                };
                if (is_legacy_clinic) {
                    payload.is_legacy_clinic = 1;
                }
                this.isFavorite = false;
                await this.favoriteService.deleteUserFavorites(payload).toPromise();
                await this.favoriteService.refreshFavorites();
            }
        } else {
            this.accountService.updateCurrentAccountStateModal(this.accountActions.LOGIN);
        }
    }


    async submit() {
        this.isLoadingUpdateFavorite = true;
        if (this.form.invalid) {
            return;
        }
        const { is_legacy_clinic, clinic_id } = this.favoriteData;
        const payload: PostUserFavorite = {
            name: this.form.value.name,
            clinic_id: [clinic_id]
        };

        if (is_legacy_clinic) {
            payload.is_legacy_clinic = is_legacy_clinic;
        }
        await this.favoriteService.postUserFavorites(payload).toPromise();
        await this.favoriteService.refreshFavorites();
        this.isFavorite = true;
        this.close();

    }

    async updateItem(fav: UserFavoritesCategoryDetails, categoryName: string) {
        this.isLoadingUpdateFavorite = true;
        const { is_legacy_clinic, clinic_id } = this.favoriteData;
        const payload: PutUserFavorite = {
            name: categoryName,
            favorite_id: fav.favorite_id,
            clinic_id: [clinic_id]
        };
        if (is_legacy_clinic) {
            payload.is_legacy_clinic = is_legacy_clinic ? true : false;
        }
        await this.favoriteService.putUserFavorites(payload).toPromise();
        await this.favoriteService.refreshFavorites();
        this.isFavorite = true;
        this.close();
    }


    close() {
        this.newItem = false;
        this.form.reset();
        this.isModalVisible = false;
        this.isLoadingUpdateFavorite = false;
    }

    mapperFavoriteData(clinicData: any) {
        if (!clinicData) { return; }
        const favoriteData = this.findIDFavorite(clinicData.id, null);
        if (favoriteData) {
            this.isFavorite = true;
            this.favoriteData = {
                clinic_id: this.hasLabel ? favoriteData.clinic_id : clinicData.id,
                favorite_id: favoriteData.favorite_id,
                is_legacy_clinic: clinicData.is_legacy_clinic || this.hasLabel ? true : false
            };
        } else {

            this.favoriteData = {
                clinic_id: clinicData.id,
                favorite_id: null,
                is_legacy_clinic: clinicData.is_legacy_clinic || this.hasLabel ? true : false
            };

            if (this.hasLabel) {
                const { info } = clinicData;
                if (info && info.new_clinic_id) {
                    const favoriteUsingClinic = this.findIDFavorite(clinicData.id, info.new_clinic_id);
                    if (favoriteUsingClinic) {
                        this.favoriteData = {
                            clinic_id: clinicData.id,
                            favorite_id: favoriteUsingClinic.favorite_id,
                            is_legacy_clinic: true
                        };
                    }
                }
            }
        }
    }

    findIDFavorite(id: any, favoriteClinicId: any) {
        let favoriteData: any;
        if (!(this.favorites && this.favorites.favorites)) { return; }
        Object.entries(this.favorites.favorites).forEach((data) => {
            const [key, lists] = data as any;
            if (lists) {
                let filteredData = lists.filter((res: any) => {
                    return res.clinic_id === id
                });
                if (favoriteClinicId) {
                    filteredData = lists.filter((res: any) => {
                        return res.clinic_id === favoriteClinicId
                    });
                }
                const isFound = filteredData && filteredData.length > 0;
                if (isFound) {
                    favoriteData = filteredData[0];
                }
            }
        });
        return favoriteData;
    }
}

export interface FavoriteClinicCore {
    favorites: any;
    clinicData: any;
}
export interface CoreFavorite {
    clinic_id: number;
    favorite_id: number | null;
    is_legacy_clinic: boolean;
}
