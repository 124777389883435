<div class="auto-search-wrapper flex justify-content-center" *ngIf="!isHome">
    <form [formGroup]="formGroup" (submit)="onSubmit()">
        <p-autoComplete delay="1000" formControlName="selectedItem" [suggestions]="suggestedItems"
            [placeholder]="searchPlaceholder" (completeMethod)="searchQuery($event)"
            field="label">
        </p-autoComplete>
        <p-button [type]="'submit'" label="Search" type="button" styleClass="p-button" class=" btn-custom-primary">
        </p-button>
    </form>
</div>
<div class="auto-basic-wrapper flex justify-content-center home " *ngIf="isHome">
    <form [formGroup]="formGroup" (submit)="onSubmit()">
        <div class="icon-search"  #searchWrapper >
            <i class="ri ri-search-line" (click)="onClick()"></i>
            <i class="custom-sort-icon ri-equalizer-line"
              *ngIf="hasFilter" (click)="$event.stopPropagation(); stateModal = true"></i>
            <div class="cursor-click" (click)="onClick()" [pTooltip]="searchPlaceholder" [appendTo]="searchWrapper"
            tooltipPosition="bottom">
                <span class=" p-autocomplete p-component cursor-click">
                  <input role="searchbox" class="p-autocomplete-input p-inputtext p-component cursor-click"
                  [(ngModel)]="formGroup.get('selectedItem') && formGroup.get('selectedItem').value
                  && formGroup.get('selectedItem').value.label" [ngModelOptions]="{standalone: true}"
                   type="text" placeholder="Search for destination, procedure, clinic or dentist">
                </span>
            </div>
        </div>
    </form>
</div>

<p-dialog [(visible)]="visible" [showHeader]="false" class="custom-dialog" [position]="'bottom'"
    [appendTo]="'body'" [styleClass]="'body-dialog'"
    [style]="{ width: '100%', height: 'calc(100% - 70px)' }" (onShow)="onAfterShow($event)">
    <div class="auto-dialog-wrapper flex justify-content-center" *ngIf="isHome">
        <div class="close-icon" (click)='close()'>
            <i class="ri-arrow-left-line"></i>
        </div>
        <form [formGroup]="formGroup" (submit)="onSubmit()" hiddenInput>
            <div class="icon-search">
                <i class="ri ri-search-line"></i>
                <p-autoComplete delay="1000" formControlName="selectedItem" (onClear)="onClear($event)"
                    #autoCompleteObject [placeholder]="searchPlaceholder"
                    (onFocus)="onFocusKeys(autoCompleteObject)"
                    (completeMethod)="searchQuery($event)" field="label">
                </p-autoComplete>
            </div>
        </form>
    </div>

    <div class="results-search">
        <ng-container *ngFor="let s of suggestedItems">
            <div (click)="navigateToitem(s)">
                <i class="ri-map-pin-line" *ngIf="s.type == 'location'"></i>
                <i class="ri-syringe-line" *ngIf="s.type == 'procedure'"></i>
                <i class="ri-home-heart-line" *ngIf="s.type == 'clinic'"></i>
                <i class="ri-nurse-line" *ngIf="s.type == 'doctor'"></i> {{ s.label}}
            </div>
        </ng-container>
        <ng-container *ngIf="isLoading">
            <ng-container *ngFor="let items of [].constructor(10);">
                <div style="pointer-events: none">
                    <p-skeleton></p-skeleton>
                </div>
            </ng-container>
        </ng-container>
    </div>
</p-dialog>


<p-dialog [(visible)]="stateModal" [showHeader]="false" class="custom-dialog custom-sort-dialog" [position]="'bottom'"
    [style]="{ width: '100%', height: 'calc(100% - 210px)' }" (onShow)="onAfterShow($event)">
    <div class="auto-dialog-wrapper flex justify-content-center" *ngIf="isHome">
        <div class="close-icon" (click)='closeStateModal()'>
            <i class="ri-close-line"></i>
        </div>
    </div>
    <div class="select-button">
      <div class="title">
        <p>Sort</p>
      </div>
    <p-selectButton [options]="sortOptions" [ngModel]="selectedSort" (ngModelChange)="sortChange($event)"
                    optionLabel="label" optionValue="value"></p-selectButton>
      <div class="divider">
        <hr>
      </div>
    </div>

    <div class="select-button mt-5">
      <div class="title">
        <p>Filter</p>
      </div>
      <p-selectButton [options]="filterOptions" [ngModel]="selectedFilter" (ngModelChange)="filterChange($event)"
        optionLabel="label" optionValue="value"></p-selectButton>
    </div>
</p-dialog>
