import { Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { Subscription } from 'rxjs';
import { SwUpdate } from '@angular/service-worker';
import { MessageService } from 'primeng/api';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ServiceWorkerVersionCheckerService {
  hasNewVersion: boolean = false;
  newVersionSub!: Subscription;

  constructor(
    private swUpdate: SwUpdate,
    private messageService: MessageService,
    private injector: Injector,
  ) {
    const platformId = this.injector.get(PLATFORM_ID);

    this.checkForUpdate();

    swUpdate.unrecoverable.subscribe( val => {
      console.log('unrecoverable: ', val);
      this.clearCachesAndUnregisterSW(true);
    });

    if (!isPlatformBrowser(platformId)) return;
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('message', event => {
        // Handle the message received from the service worker
        if (event.data && event.data.type === 'RELOAD_PAGE') {
          this.clearCachesAndUnregisterSW(true);
        }
      });
    }

  }

  checkForUpdate(): void {
    this.newVersionSub?.unsubscribe();
    if (!this.swUpdate.isEnabled) {
      return;
    }
    this.newVersionSub = this.swUpdate.versionUpdates.subscribe(evt => {
      switch (evt.type) {
        case 'VERSION_DETECTED':
          console.log(`App version detected '${evt.version.hash}`);
          break;
        case 'VERSION_READY':
          // this.hasNewVersion = true;
          console.log(`App latest version '${evt.latestVersion}`);
          console.log(`App current version '${evt.currentVersion}`);
          // this.clearCachesAndUnregisterSW(true);
          break;
        case 'VERSION_INSTALLATION_FAILED':
          console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
          // this.clearCachesAndUnregisterSW(true);
          break;
      }
    });
  }

  applyUpdate(canReload = false): void {
    // Reload the page to update to the latest version after the new version is activated
    this.swUpdate.activateUpdate()
      .then((res) => {
        if (canReload) {
          window.location.reload();
        }
      })
      .catch(error => console.error('Failed to apply updates test:', error));
  }

  clearCachesAndUnregisterSW(canReload: boolean) {
    // Clear Cache Storage for the current domain
    if ('caches' in window) {
      caches.keys().then(names => {
        names.forEach(name => {
          caches.delete(name);
        });
      });
    }

    // Unregister Service Workers for the current domain
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then(registrations => {
        console.log(`Unregister service worker`);
        for (const registration of registrations) {
          registration.unregister();
        }
      });
    }


    // Optional: Reload the page to apply changes
    if (canReload) {
        window.location.reload();
    }
  }
}
