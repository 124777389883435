import { Pipe, PipeTransform } from '@angular/core';
import { MenuItem as MenuItemPrime } from 'primeng/api/menuitem';

@Pipe({
  name: 'clinicUrl'
})
export class ClinicUrlPipe implements PipeTransform {

  transform(value: MenuItemPrime): string {
    if (!value) {
      return '/';
    }
    return value.icon === 'fa-dental-fillings' ? value.routerLink :
      `/dentists/${value.routerLink.replace('/info/', '').replace('/dentists/', '')}`;
  }

}
