<ng-container *ngIf="(this.commonService.common$ | async) as common">
  <app-service-worker-version-alert></app-service-worker-version-alert>
  <div class="page-body">
    <app-header [common]="common"></app-header><br/>
    <router-outlet></router-outlet>
    <app-spinner></app-spinner>
  </div>
   <app-footer [common]="common"></app-footer>
</ng-container>
<!-- 
<app-contact-point [facebookMessengerLink]="'https://m.me/dentaldepartures'"
                   [whatsAppLinks]="this.whatsAppGeolocationLinks"
                   [emailLink]="'care@dentaldepartures.com'"
                   [lineLink]="'https://line.me/R/ti/p/%40hvb5247n'">
</app-contact-point>

<app-integration></app-integration> -->
