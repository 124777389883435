import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class SiteIdInterceptor implements HttpInterceptor {
  constructor(@Inject('env') private env: any) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const url = req && req.url ? req.url : '';
    if (url.includes(this.env.api.core) || url.includes(this.env.api.staglCore)) {
      const contentType = req.headers.get('Content-Type');
      if (contentType === 'multipart/form-data') {
        return next.handle(
          req.clone({
            headers: req.headers
              .delete('Content-Type') // remove this content type in order to get proper BOUNDARY attribute
              .set('X-MD-Site-Id', this.env.site_id.toString())
          })
        );
      } else {
        return next.handle(
          req.clone({
            headers: req.headers
              .set('Content-Type', 'application/json')
              .set('X-MD-Site-Id', this.env.site_id.toString())
            // params: req.params.set('X-MD-Site-Id', '4')
            // params: req.params.set('site_id', environment.site_id.toString())
          })
        );
      }
    } else if (url.includes(this.env.api.keendex) || url.includes(this.env.api.staglKeendex)) {
      return next.handle(
        req.clone({
          headers: req.headers
            .set('Content-Type', 'application/json')
            .set('X-SITE-ID', this.env.site_id.toString())
        })
      );
    } else if (url.includes(this.env.api.medid)) {
      // const access_token =
      //   'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE1ODQ0OTQ5MzksImlhdCI6MTU1Mjk1ODkzOSwibmJmIjoxNTUyOTU4OTM5LCJpZGVudGl0eSI6Nzh9.wFCUmXU9FKlWjbKpFREGL_IZ_aPGzUGBIgsnnpjVbOk';

      // return next.handle(
      //   req.clone({
      //     headers: req.headers
      //       .set('Authorization', 'JWT' + ' ' + access_token)
      //       .set('Content-Type', 'application/json')
      //   })
      // );

      return next.handle(req);
    } else if (req.headers.get('Html-Sitemap') === 'True') { // allowed for reading of csv files over http for generating html sitemaps
      return next.handle(req);
    } else {
      return next.handle(req);
    }
  }
}
