<div class="options-header">
    <div class="container container-main" *ngIf="isReady">
        <swiper-container class="swiper-containers" navigation="true" customSwiper fxFlex="auto" [config]="config" [disabled]="disabled"
            (indexChange)="onIndexChange($event)" (swiperTransitionStart)="onSwiperEvent('transitionStart')"
            (swiperTransitionEnd)="onSwiperEvent('transitionEnd')">
            <swiper-slide *ngFor="let item of menuPrimeItems">
                <div class="swiper-slide swiper-options-header max-w-157" [class.resize-card-wm]="item.icon === 'fa-deep-cleaning' || item.label == 'Zygomatic Implants'
                    || item.icon === 'fa-clear-braces' || item.label === 'Teeth gas sedation' || item.label == ' Teeth bone graft'
                    || item.label == 'Teeth bone graft' || item.icon == 'fa-snap-on-dentures' "
                    [class.resize-card-ws]="item.icon == 'fa-dental-implants'
                    || item.label == 'Tooth Teeth Cleaning'"
                    >
                    <div fxLayout="column">
                        <a class="pointer" (click)="resetSortAndFilter()" [routerLink]="item | clinicUrl">
                        <div class="card text-center sub-header-icons">
                            <div class="icon">
                                <i class="fa-cust {{item.icon}}"></i>
                            </div>
                            <span>
                                {{ item.label }}
                            </span>
                        </div>
                      </a>
                    </div>
                </div>
            </swiper-slide>
            <div class="swiper-slide swiper-options-header"> <div class="column"></div></div>
            <div class="swiper-slide swiper-options-header"> <div class="column"></div></div>
            <div class="swiper-slide swiper-options-header mobile-only"> <div class="column"></div></div>
        </swiper-container>
    </div>
</div>
