import {CommonModule, TitleCasePipe} from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CurrencyQPipe } from '../../../../core/src/lib/pipes/currency-q.pipe';
import { GoogleMapQPipe } from '../../../../core/src/lib/pipes/google-map-q.pipe';
import { PriceTagPipe } from '../../../../core/src/lib/pipes/price-tag.pipe';
import { PricingPipe } from '../../../../core/src/lib/pipes/pricing.pipe';
import { SafeHtmlPipe } from '../../../../core/src/lib/pipes/safe-html.pipe';
import { SafeUrlPipe } from '../../../../core/src/lib/pipes/safe-url.pipe';
import { TruncatePipe } from '../../../../core/src/lib/pipes/truncate.pipe';
import { AccordionGroupComponent } from '../../../../core/src/lib/shared/components/accordion-group/accordion-group.component';
import { AccordionComponent } from '../../../../core/src/lib/shared/components/accordion/accordion.component';
import { AlertComponent } from '../../../../core/src/lib/shared/components/alert/alert.component';
import { BadgeCovidTrustShieldComponent } from '../../../../core/src/lib/shared/components/badge-covid-trust-shield/badge-covid-trust-shield.component';
import { BadgeGpcComponent } from '../../../../core/src/lib/shared/components/badge-gpc/badge-gpc.component';
import { BadgePremiumComponent } from '../../../../core/src/lib/shared/components/badge-premium/badge-premium.component';
import { BadgeVerifiedDoctorComponent } from '../../../../core/src/lib/shared/components/badge-verified-doctor/badge-verified-doctor.component';
import { BadgeVerifiedComponent } from '../../../../core/src/lib/shared/components/badge-verified/badge-verified.component';
import { BreadcrumbComponent } from '../../../../core/src/lib/shared/components/breadcrumb/breadcrumb.component';
import { ButtonBestPriceComponent } from '../../../../core/src/lib/shared/components/button-best-price/button-best-price.component';
import { ButtonTravelRestrictionsComponent } from '../../../../core/src/lib/shared/components/button-travel-restrictions/button-travel-restrictions.component';
import { ClinicExtrasComponent } from '../../../../core/src/lib/shared/components/clinic-extras/clinic-extras.component';
import { ContactPointComponent } from '../../../../core/src/lib/shared/components/contact-point/contact-point.component';
import { CovidBannerComponent } from '../../../../core/src/lib/shared/components/covid-banner/covid-banner.component';
import { NavMenuComponent } from '../../../../core/src/lib/shared/components/nav-menu/nav-menu.component';
import { PagingComponent } from '../../../../core/src/lib/shared/components/paging/paging.component';
import { RibbonPremiumComponent } from '../../../../core/src/lib/shared/components/ribbon-premium/ribbon-premium.component';
import { SpinnerComponent } from '../../../../core/src/lib/shared/components/spinner/spinner.component';
import { AsideLayoutComponent } from '../layouts/aside-layout/aside-layout.component';
import { BlankLayoutComponent } from '../layouts/blank-layout/blank-layout.component';
import { BlankWideLayoutComponent } from '../layouts/blank-wide-layout/blank-wide-layout.component';
import { PreviewLayoutComponent } from '../layouts/preview-layout/preview-layout.component';
import { SearchFormComponent } from '../modules/search/components/search-form/search-form.component';
import { SearchSuggestionComponent } from '../modules/search/components/search-suggestion/search-suggestion.component';
import { WidgetFreeQuoteComponent } from './components/widget-free-quote/widget-free-quote.component';
import { WidgetHowToBookComponent } from './components/widget-how-to-book/widget-how-to-book.component';
import { WidgetPopularLocationsComponent } from './components/widget-popular-locations/widget-popular-locations.component';
import { WidgetPopularTreatmentsComponent } from './components/widget-popular-treatments/widget-popular-treatments.component';
import { WidgetWhyComponent } from './components/widget-why/widget-why.component';
import { DashToSpacePipe } from '@lib/pipes/dash-to-space.pipe';
import { CancellationSurveyComponent } from '@lib/shared/components/cancellation-survey/cancellation-survey.component';
import { NgxIntlTelInputModule} from 'ngx-intl-tel-input';
import { FavoriteComponent } from '@lib/shared/components/favorite/favorite.component';
import { IntegrationComponent } from '@lib/shared/components/integration/integration.component';
import { AccountModalComponent } from '@lib/shared/components/accounts/account-modal/account-modal.component';
import { RegisterComponent } from '@lib/shared/components/accounts/register/register.component';
import { LoginComponent } from '@lib/shared/components/accounts/login/login.component';
import { CustomFormFieldComponent } from '@lib/shared/components/form/custom-form-field/custom-form-field.component';
import { CustomFormControlDirective } from '@lib/shared/directives/custom-form-control.directive';
import { ForgotPasswordComponent } from '@lib/shared/components/accounts/forgot-password/forgot-password.component';
import { SharedCoreModule } from '@lib/shared-core/shared.core.module';
import { SharedPrimeNgModule } from '@lib/shared-core/shared-prime-ng.module';
import { ConfirmAppointmentComponent } from '@lib/shared/components/confirm-appointment/confirm-appointment.component';

@NgModule({
  declarations: [
    AccordionComponent,
    AccordionGroupComponent,
    AsideLayoutComponent,
    BlankLayoutComponent,
    BlankWideLayoutComponent,
    PreviewLayoutComponent,
    BreadcrumbComponent,
    SearchFormComponent,
    SearchSuggestionComponent,
    SafeHtmlPipe,
    SafeUrlPipe,
    BadgePremiumComponent,
    BadgeVerifiedComponent,
    BadgeVerifiedDoctorComponent,
    BadgeCovidTrustShieldComponent,
    BadgeGpcComponent,
    ButtonBestPriceComponent,
    ButtonTravelRestrictionsComponent,
    RibbonPremiumComponent,
    WidgetWhyComponent,
    WidgetPopularTreatmentsComponent,
    WidgetPopularLocationsComponent,
    WidgetHowToBookComponent,
    WidgetFreeQuoteComponent,
    AlertComponent,
    TruncatePipe,
    PricingPipe,
    CurrencyQPipe,
    PriceTagPipe,
    GoogleMapQPipe,
    NavMenuComponent,
    AccordionComponent,
    SpinnerComponent,
    CovidBannerComponent,
    PagingComponent,
    ClinicExtrasComponent,
    ContactPointComponent,
    DashToSpacePipe,
    CancellationSurveyComponent,
    ConfirmAppointmentComponent,
    FavoriteComponent,
    IntegrationComponent,
    AccountModalComponent,
    RegisterComponent,
    LoginComponent,
    CustomFormFieldComponent,
    CustomFormControlDirective,
    ForgotPasswordComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    NgxIntlTelInputModule,
    SharedCoreModule,
    SharedPrimeNgModule
  ],
  exports: [
    AccordionComponent,
    AccordionGroupComponent,
    BreadcrumbComponent,
    SearchFormComponent,
    SearchSuggestionComponent,
    SafeHtmlPipe,
    SafeUrlPipe,
    BadgePremiumComponent,
    BadgeVerifiedComponent,
    BadgeVerifiedDoctorComponent,
    BadgeGpcComponent,
    BadgeCovidTrustShieldComponent,
    ButtonBestPriceComponent,
    ButtonTravelRestrictionsComponent,
    RibbonPremiumComponent,
    WidgetWhyComponent,
    WidgetPopularTreatmentsComponent,
    WidgetPopularLocationsComponent,
    WidgetHowToBookComponent,
    WidgetFreeQuoteComponent,
    TruncatePipe,
    PricingPipe,
    PriceTagPipe,
    CurrencyQPipe,
    GoogleMapQPipe,
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    NavMenuComponent,
    SpinnerComponent,
    CovidBannerComponent,
    PagingComponent,
    ClinicExtrasComponent,
    ContactPointComponent,
    DashToSpacePipe,
    CancellationSurveyComponent,
    ConfirmAppointmentComponent,
    AlertComponent,
    NgxIntlTelInputModule,
    FavoriteComponent,
    IntegrationComponent,
    AccountModalComponent,
    RegisterComponent,
    LoginComponent,
    CustomFormFieldComponent,
    CustomFormControlDirective,
    ForgotPasswordComponent,
    SharedCoreModule,
    SharedPrimeNgModule
  ],
  providers: [DashToSpacePipe, TitleCasePipe]
})
export class SharedModule { }
