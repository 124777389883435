import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpClient,
  HttpHeaders
} from '@angular/common/http';
import { Injectable, Inject, Optional, PLATFORM_ID } from '@angular/core';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { Observable } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class XForwardedForInterceptor implements HttpInterceptor {

  private ip: string = '';
  constructor(
    @Inject('env') private env: any,
    @Optional() @Inject('clientIPAddress') ipAddress: string,
    private http: HttpClient
  ) {
    this.ip = ipAddress;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const url = req && req.url ? req.url : '';
    if (url.includes(this.env.api.keendex) || url.includes(this.env.api.staglKeendex)) {
      if (!this.ip) return next.handle(req);

      const headers = req.headers.set('x-forwarded-for', this.ip || '');
      const clonedReq = req.clone({ headers });
      return next.handle(clonedReq);
    } else {
      return next.handle(req);
    }
  }
}
