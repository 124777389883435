import { DecimalPipe, isPlatformBrowser } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import {
  APP_ID,
  APP_INITIALIZER,
  Inject,
  NgModule,
  PLATFORM_ID,
  ErrorHandler,
  CUSTOM_ELEMENTS_SCHEMA, isDevMode
} from '@angular/core';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { RecaptchaService } from '../../../core/src/lib/features/recaptcha/recaptcha.service';
import { httpInterceptorProviders } from '../../../core/src/lib/interceptors';
import { CurrencySwitcherComponent } from '../../../core/src/lib/shared/components/currency-switcher/currency-switcher.component';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { HeaderComponent } from './layouts/header/header.component';
import { InternalErrorComponent } from './layouts/internal-error/internal-error.component';
import { PageNotFoundComponent } from './layouts/page-not-found/page-not-found.component';
import { HomeModule } from './modules/home/home.module';
import { SharedModule } from './shared/shared.module';
import { ServiceWorkerModule } from '@angular/service-worker';

declare global {
  interface Window { dataLayer: any[]; }
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    PageNotFoundComponent,
    CurrencySwitcherComponent,
    InternalErrorComponent
  ],
  imports: [
    environment.production ? BrowserAnimationsModule : NoopAnimationsModule,
    HttpClientModule,
    SharedModule,
    HomeModule,
    AppRoutingModule,
    ServiceWorkerModule.register('sw.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:5000'
    }),
  ],
  exports: [],
  providers: [
    httpInterceptorProviders,
    DecimalPipe,
    { provide: 'env', useValue: environment },
    { provide: APP_ID,  useValue: 'dental' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() { }
}
