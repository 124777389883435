

export const DENTAL_TREATMENTS: ReadonlyArray<any> = [
  {
    name: $localize`:@@treatmentAllOn4:All-on-4`,
    slug: 'all-on-4',
    children: [
      {
        name: $localize`:@@treatmentAllOn4:All-on-4`,
        slug: 'all-on-4'
      }
    ]
  },
  {
    name: $localize`:@@treatmentAllOn6:All-on-6`,
    slug: 'all-on-6',
    children: []
  },
  {
    name: $localize`:@@treatmentDentalImplants:Dental Implants`,
    slug: 'dental-implants',
    children: []
  },
  {
    name: $localize`:@@treatmentCrowns:Crowns`,
    slug: 'crowns',
    children: []
  },
  {
    name: $localize`:@@treatmentVeneers:Veneers`,
    slug: 'veneers',
    children: []
  },
  {
    name: $localize`:@@treatmentDentures:Dentures`,
    slug: 'dentures',
    children: []
  },
  {
    name: $localize`:@@treatmentFillings:Fillings`,
    slug: 'fillings',
    children: []
  },
];