import { Injectable, Injector, PLATFORM_ID } from "@angular/core";
import {
  HttpInterceptor,
  HttpHandler,
  HttpEvent,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { ServiceWorkerVersionCheckerService } from '@lib/shared-core/helper/service-worker-version-checker.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  ERROR_STATUSES = [400, 401, 403, 404, 408, 409, 417];

  // eg. https://xxxxx/main.1234567890123456.js
  SPECIFIC_URL_PATH_PATTERN = /\/\w+\.\w{16}\.js$/;
  constructor(
      private injector: Injector,
      private serviceWorkerVersionCheckerService: ServiceWorkerVersionCheckerService
    ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const platformId = this.injector.get(PLATFORM_ID);

    if (this.SPECIFIC_URL_PATH_PATTERN.test(req.url)) {

      return next.handle(req).pipe(tap( event => {
        if (event instanceof HttpResponse && this.ERROR_STATUSES.includes(event.status)) {

          if (isPlatformBrowser(platformId)) {
            this.serviceWorkerVersionCheckerService.clearCachesAndUnregisterSW(true);
          } else if (isPlatformServer(platformId)) {
            console.log('server side');
          }

        }
      }));
    }

    return next.handle(req);
  }
}
