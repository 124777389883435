export const environment = {
  production: true,
  site_id: 1,
  host: 'https://www.dentaldepartures.com',
  host_domain: 'dentaldepartures.com',
  api: {
    core: 'https://api.medicaldepartures.com/core/v1/',
    keendex: 'https://api.medicaldepartures.com/keendex/v1/',
    medid: 'https://api.medicaldepartures.com/medid/v1/',
    medid_v2: 'https://api.medicaldepartures.com/medid/v2/',
    staglCore: 'https://stagl-api.medicaldepartures.com/core/v1/',
    staglKeendex: 'https://stagl-api.medicaldepartures.com/keendex/v1/',
    jwt_prefix: 'JWT',
    accepted_term_id: 1
  },
  domains: {
    account: 'https://account.medicaldepartures.com',
    md: 'https://www.medicaldepartures.com'
  },
  url: {
    account_login: 'https://account.medicaldepartures.com/external-login',
    account_logout: 'https://account.medicaldepartures.com/external-logout',
    account_profile: 'https://account.medicaldepartures.com/external-edit-profile',
    amp_domain: 'https://amp.dentaldepartures.com',
    csv_sitemaps: 'https://cdn.dentaldepartures.com/sitemap'
  },
  folder: {
    staticSearch: 'dentists',
    clinic: 'dentist'
  },
  buckets: {
    static: 'https://md-global-static-staging.s3.amazonaws.com/'
  },
  links: {
    reviewcentre_write_review: 'http://www.reviewcentre.com/write-a-review-3603145.html',
    feefo: 'https://www.feefo.com/reviews/dental-departures'
  },
  recaptcha_site_key: '6LdOuLEUAAAAAGShYqw1CDiJpLkRSz79w-NDuqRR',
  recaptcha_enabled: true,
  schema: {
    name: 'Dental Departures',
    description: 'Looking for affordable dental care options? Get the dental treatment you need at a fraction of the cost.',
    logo: 'https://img.dentaldepartures.com/dd-logo-icon.png',
    url: 'https://www.dentaldepartures.com',
    sameAs: [
      'https://www.facebook.com/dentaldepartures/',
      'https://www.instagram.com/_dental_departures/',
      'https://twitter.com/DentalDeparture',
      'https://www.linkedin.com/company/dental-departures',
      'https://www.youtube.com/user/MyDentalDepartures',
      'https://www.pinterest.com/dentaldeparture/'
    ],
    email: 'info@dentaldepartures.com'
  },
  phones: {
    // in use
    ctm: '+1-888-504-2671',
    th: '+66-2508-8449',
    gb: '+44-800-011-9602',
    us: '+1-888-673-6134',
    au: '+61-1300-293-192',
    de: '+49 800 0000435',
    fr: '+33 805 22 06 63',
    es: '+34 900 998 462',
    // Not in use
    ca: '+1-888-673-6134',
    mx: '+52-81-4160-5467',
    nz: '+64-4-888-0127',
    sg: '+65-3158-9630',
    ph: '+63-2-2313363',
    my: '+60-11-170-40090'
  },
  social_app_id: {
    google: '224518026735-l50n55jto7h7vr6s6pmtv2f0oij5bh1v.apps.googleusercontent.com',
    facebook: '203605636796586'
  },
  apple_credentials: {
    clientId : 'com.medicaldepartures.bundle.backend',
    scope : 'name email',
    redirectURI : 'https://www.dentaldepartures.com',
    state : 'initial',
    usePopup : true
  },
  prerender_io_token: 'GCPfAgig6bd5YJ5VYpv5'
};
