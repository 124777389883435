import { Component, OnInit } from '@angular/core';
import { HELPFUL_LINKS } from '../../constants/links.constants';

@Component({
  selector: 'app-internal-error',
  templateUrl: './internal-error.component.html',
  styleUrls: ['./internal-error.component.scss']
})
export class InternalErrorComponent implements OnInit {

  helpFulLinks = HELPFUL_LINKS;

  constructor() { }

  ngOnInit() {
  }

}
