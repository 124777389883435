import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import {Observable, BehaviorSubject, of} from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {GoogleSignIn, GoogleSignInResponse, IMe, IUserProfile} from './auth.model';
import { map } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import {
  Registration,
  RegistrationResponse, User,
  UserLogin,
  UserLoginResponse,
  UserSignIn
} from '@lib/models/user.model';
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<IUserProfile | null>;
  public currentUser: Observable<IUserProfile | null>;
  loggedIn = new BehaviorSubject<boolean>(false);

  private userInfoKey = 'medid-token';
  private userLoginType = 'login-type';
  private sourceDomain = '';
  private readonly apiHost: string;
  public signInWithGoogleResponse: BehaviorSubject<GoogleSignInResponse | null> =
    new BehaviorSubject<GoogleSignInResponse | null>(null);


  constructor(
    @Inject(PLATFORM_ID) platformId: Object,
    @Inject('env') private env: any,
    private http: HttpClient
  ) {
    this.currentUserSubject = new BehaviorSubject<IUserProfile | null>(
      {...JSON.parse(localStorage.getItem(this.userInfoKey)!), ...{ login_type: localStorage.getItem(this.userLoginType) }});
    this.currentUser = this.currentUserSubject.asObservable();
    this.sourceDomain = '';
    this.apiHost = env.api.medid_v2;

    if (isPlatformBrowser(platformId)) {
      this.sourceDomain = encodeURI(`${window.location.protocol}//${window.location.hostname}`);
    }
  }

  public get currentUserValue(): IUserProfile | null {
    return this.currentUserSubject.value;
  }

  setCurrentUserValue(value: IUserProfile) {
    this.currentUserSubject.next(value);
    if(value && value.login_type) {
      localStorage.setItem(this.userLoginType, value.login_type);
    }
    this.currentUser = this.currentUserSubject.asObservable();
  }

  generateLogInLink(sourcePath: string = ''): string {
    // https://account-staging.medicaldepartures.com/external-login?source=https%3A%2F%2Fstaging.medicaldepartures.com%2F
    // source = encodeURI(source);
    return `${this.env.url.account_login}?source=${this.sourceDomain}&siteId=${this.env.site_id}`;
  }

  generateLogOutLink(sourcePath: string = ''): string {
    return `${this.env.url.account_logout}?source=${this.sourceDomain}`;
  }

  generateEditProfileLink(sourcePath: string = ''): string {
    return `${this.env.url.account_profile}?source=${this.sourceDomain}`;
  }

  generateAccountLink(): string {
    return `${this.env.domains.account}`;
  }

  private meGET(access_token: string): Observable<any> {
    return this.http.get<any>(this.env.api.medid_v2 + 'me', {
      headers: new HttpHeaders().set('Authorization', 'JWT ' + access_token)
    });
  }

  private storeUserInfo(data: IUserProfile) {
    localStorage.setItem(this.userInfoKey, JSON.stringify(data));
  }

  get hasMedidToken() {
    return localStorage.getItem(this.userInfoKey);
  }

  get loginUserType() {
    return localStorage.getItem(this.userLoginType);
  }

  refreshCurrentUser() {
    this.setCurrentUserValue(this.getUserAuthentication() as any);
  }

  getUserAuthentication(): UserLogin {
    let userLogin: UserLogin = undefined as any;
    const medidToken = localStorage.getItem(this.userInfoKey);
    if (medidToken) {
      userLogin = JSON.parse(medidToken);
    }

    if (userLogin) {
      return userLogin;
    } else {
      this.logout();
      return null as any;
    }

  }

  storeUserAuthentication(userToken: UserLogin) {
    localStorage.setItem(this.userInfoKey, JSON.stringify(userToken));
  }

  login(user: UserSignIn): Observable<UserLoginResponse> {
    const body = {...user};

    return this.http
      .post<UserLoginResponse>(this.apiHost + 'login', body)
      .pipe(
        map((c: UserLoginResponse) => {
          if (c.success) {
            this.loggedIn.next(true);
          }
          return c;
        })
      );
    // catchError(this.handleError(''))
  }

  register(registration: Registration): Observable<RegistrationResponse> {
    return this.http.post<RegistrationResponse>(
      this.apiHost + 'register',
      registration
    );
  }

  verifyLoginStatus() {
    if (this.getUserAuthentication()) {
      this.loggedIn.next(true);
    } else {
      this.loggedIn.next(false);
    }
  }

  // signInWithFacebook(): Promise<SocialUser> {
  //   return this.socialAuthService
  //     .signIn(FacebookLoginProvider.PROVIDER_ID)
  //     .then((user: SocialUser) => {
  //       if (user != null) {
  //         this.loggedIn.next(true);
  //       } else {
  //         this.loggedIn.next(false);
  //       }
  //       return user;
  //     });
  // }

  // signInWithGoogle(): Promise<SocialUser> {
  //   return this.socialAuthService
  //     .signIn(GoogleLoginProvider.PROVIDER_ID)
  //     .then((user: SocialUser) => {
  //       this.loggedIn.next(user != null);
  //       return user;
  //     });
  // }

  initializeGoogleAccountsId() {
    // @ts-ignore
    google.accounts.id.initialize({
      client_id: this.env.social_app_id.google,
      callback: this.handleCredentialResponse.bind(this),
      auto_select: false,
      cancel_on_tap_outside: true,

    });
    // @ts-ignore
    google.accounts.id.renderButton(
      // @ts-ignore
      document.getElementById('google-button'),
      { theme: 'filled_blue', size: 'medium', width: '100%',
        type: 'icon', ux_mode: 'popup'}
    );
    // @ts-ignore
    // google.accounts.id.prompt((notification: PromptMomentNotification) => {});
  }

  async handleCredentialResponse(response: GoogleSignIn) {
    // Here will be your response from Google.
    if (response && response.credential) {
      this.signInWithGoogleResponse.next(this.decodeJWT(response));
    } else {
      this.signInWithGoogleResponse.next(null);
    }
  }

  resetGoogleResponse() {
    this.signInWithGoogleResponse.next(null);
  }

  private decodeJWT(value: GoogleSignIn): GoogleSignInResponse {
    return jwt_decode<GoogleSignInResponse>(value.credential);
  }

  isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  logout() {
    localStorage.removeItem(this.userInfoKey);
    this.currentUserSubject.next(null);
    this.loggedIn.next(false);
  }
}
