<ng-container *ngIf="toggleFooter$ | async">
  <div class="container-main" *ngIf="hasOtherFooters">

    <app-why-departures [title]="whyTitle" [subtle]="''" [whyInfo]="whyInfo"></app-why-departures>
    <div class="hr-bottom"></div>
    <app-latest-reviews-card [title]="reviewsTitle" [isFooter]="true" [isReviewArray]="true"  [latestReview]="latestReview"></app-latest-reviews-card><br/>
    <div class="hr-bottom"></div>
    <app-how-to-book></app-how-to-book>
    <div class="hr-bottom"></div>
    <div class="title-only latest-promotion" i18n="@@latestPromotions">
      Latest Promotions
    </div>
    <app-promotions [title]="''" [promotions]="promotions"></app-promotions>
  </div>
  <div class="footer-wrapper container-fluid">
    <div class="row" *ngIf="footerHeaderDataItems">
      <div class="col-md-2"></div>
      <div class="col-md-8">
        <div class="row">
          <div class="col-12">
            <div class="sign-up-wrapper">
              <form class="newsletter-signup-form" [formGroup]="newsletterSignUpForm" (submit)="onSubmit()">
                <label class="newsletter-signup-form__email-input-label" for="email-to-subscribe"
                  i18n="@@formLabelNewsletterSignup">Sign up for great deals around the globe</label>
                <div class="row">
                  <div class="col-12 col-md-7">
                    <input pInputText type="text" id="email-to-subscribe" name="email" formControlName="email"
                      class="newsletter-signup-form__email-input wb-100" placeholder="E-mail address"
                      i18n-placeholder="@@formInputEmailPlaceholder" />

                    <input type="hidden" name="refurl" formControlName="refurl" />
                    <input type="hidden" name="category" formControlName="category" />
                  </div>
                  <div class="col-12 col-md-5">
                    <p-progressBar mode="indeterminate" *ngIf="isSubmitting" [style]="{ height: '3px' }"></p-progressBar>
                    <button type="submit" pButton class="btn btn-primary wb-100 subscribe-btn" [disabled]="isSubmitting"
                      i18n="@@formButtonSubscribe">Subscribe</button>
                  </div>
                </div>
              </form>
            </div><br />
            <hr class="hr" /><br />
          </div>
          <ng-container *ngFor="let fs of footerHeaderDataItems.items">
            <div class="col-md-4 menu">
              <h3>{{fs.title}}</h3>
              <ng-container *ngIf="fs.footerItems">
                <ul>
                  <ng-container *ngFor="let fi of fs.footerItems">
                    <li *ngIf="fi.isExternalLink">
                      <a [href]="fi.link" target="_blank"> {{fi.label}}</a>
                    </li>
                    <li *ngIf="!fi.isExternalLink">
                      <a [routerLink]="fi.link"> {{fi.label}}</a>
                    </li>
                  </ng-container>
                </ul>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="col-md-2"></div>
      <div class="col-12 text-center copyright-wrapper">
        <div>Copyright {{currentYear}}. {{ footerHeaderDataItems.footerSiteName }}
          <div class="language-lists">
            <ng-container *ngIf="footerHeaderDataItems && footerHeaderDataItems.languageLists">
              <ng-container *ngFor="let languges of footerHeaderDataItems.languageLists">
                <div (click)="clickUrl(languges)">
                  {{ languges.value }}
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="!isBookingDetails">
    <div class="footer-menu-mobile" *ngIf="!isScrollingDown && !isDentistPage">
      <ul *ngIf="!isDentistPage" class="list-footer-sticky">
        <li (click)="navigateTo()" class="cursor-pointer"><i class="ri-heart-line"></i> <span i18n="@@wishlists">
            Wishlists </span> </li>
        <li *ngIf="!user" (click)="openLogin()"><i class="ri-user-line"></i> <span i18n="@@login"> Login </span>
        </li>
        <li *ngIf="user" (click)="navigateTo(true)"><i class="ri-user-line"></i> <span i18n="@@profile"> Profile </span>
        </li>
      </ul>
    </div>
    <div class="footer-menu-mobile" *ngIf="isDentistPage && isLoadedDentistPage">
      <div class="dentistPageButton">
        <div class="row add-nopadding">
          <div class="col-6 add-right-padding">
            <button i18n="@@contactClinic" pButton class="wb-100 p-button-label-zero p-button-outlined p-button "
              (click)="contactClinic()">
              Contact clinic</button>
          </div>
          <div class="col-6 add-left-padding">
            <button pButton class="wb-100 p-button-label-zero p-button" i18n="@@bookAppointment" (click)="bookClinic()">
              Book appointment</button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
<ng-container *ngIf="toggleSmallFooter$ | async">
  <footer class="row small-footer-wrapper">
    <div class="col-12 col-md-8">© {{currentYear}}. {{ footerHeaderDataItems?.footerSiteName }}, Inc.
      <div class="other-links">
        <ng-container>
          <ng-container *ngFor="let link of links">
            <a class="pointer link" [href]="'https://www.dentaldepartures.com/' + link" target="_blank">
              {{ link | titlecase}}
            </a>
          </ng-container>
        </ng-container>
      </div>
    </div>
    <div class="col-4 small-footer-social text-center">
      <ul>
        <a href="https://www.facebook.com/dentaldepartures" target="_blank"><img src="assets/icons/black-facebook.svg"
            alt="facebook icon"></a>
        <a href="https://twitter.com/dentaldeparture" target="_blank"><img src="assets/icons/black-twitter.svg"
            alt="twitter icon"></a>
        <a href="https://www.instagram.com/dentaldepartures/" target="_blank"><img
            src="assets/icons/black-instagram.svg" alt="instagram icon"></a>
      </ul>
    </div>
  </footer>
</ng-container>
