<ng-container *ngIf="toggleHeader$ | async else onlyLogo">
  <div class="header">
    <div class="container container-main"
      *ngIf="isMobile && menuPrimengMobileLists && menuPrimengMobileLists.length > 0">
      <p-menubar [id]="'mobile'" appCustomSeoMenubarPatch [model]="menuPrimengMobileLists" [autoDisplay]="true" mobileActive="true">
        <ng-template pTemplate="start">
          <a class="header__brand" routerLink="/">
            <img class="header__brand-logo" ngSrc="{{logoImages.desktopUrl}}" alt="Dental Departures"
              title="Dental Departures" fill priority />
          </a>
          <a href="tel:{{ this.phoneNumber }}" class="tel-link callus">
            <span class="toll-free" i18n="@@tollFree">Toll Free</span>
            <i class="fas fa-phone"></i>
            <span class="callus">{{ this.phoneNumber }}</span>
          </a>
        </ng-template>
        <ng-template pTemplate="end">
          <p-menubar [model]="otherMenuLists" class="other-menu">
            <ng-template pTemplate="start">
              <a href="tel:{{ this.phoneNumber }}" class="tel-link-desktop callus">
                <span class="toll-free" i18n="@@tollFree">Toll Free</span>
                <i class="fas fa-phone"></i>
                <span class="callus">{{ this.phoneNumber }}</span>
              </a>
            </ng-template>
          </p-menubar>
        </ng-template>
      </p-menubar>
    </div>
    <div class="container container-main" *ngIf="!isMobile && menuPrimeNgLists ">
      <p-menubar appCustomSeoMenubarPatch [id]="'desktop'" [model]="menuPrimeNgLists" [autoDisplay]="true">
        <ng-template pTemplate="start">
          <a class="header__brand" routerLink="/">
            <img class="header__brand-logo" ngSrc="{{logoImages.desktopUrl}}" alt="Dental Departures"
              title="Dental Departures" fill priority />
          </a>
          <a href="tel:{{ this.phoneNumber }}" class="tel-link callus">
            <span class="toll-free" i18n="@@tollFree">Toll Free</span>
            <i class="fas fa-phone"></i>
            <span class="callus">{{ this.phoneNumber }}</span>
          </a>
        </ng-template>
        <ng-template pTemplate="end">
          <p-menubar [model]="otherMenuLists" class="other-menu">
            <ng-template pTemplate="start">
              <a href="tel:{{ this.phoneNumber }}" class="tel-link-desktop callus">
                <span class="toll-free" i18n="@@tollFree">Toll Free</span>
                <i class="fas fa-phone"></i>
                <span class="callus">{{ this.phoneNumber }}</span>
              </a>
            </ng-template>
          </p-menubar>
        </ng-template>
      </p-menubar>
    </div>

    <div class="other-headers" *ngIf="hasOtherHeaders">
      <div class="search-header">
        <div class="row">
          <div class="col-sm-2 col-md-3"></div>
          <div class="col-sm-8 col-md-6">
            <app-auto-complete-search [hasFilter]="hasFilter" [isHome]="true"></app-auto-complete-search>
          </div>
          <div class="col-sm-2 col-md-3"></div>
        </div>
      </div>
      <div class="sub-header" *ngIf="!isMobile">
        <app-options-header (onClickEvent)="clickRoute($event)" [platform]="headerDataItems.platform"
          [menuPrimeItems]="menuTreatmentLists"></app-options-header>
      </div>
    </div>
  </div>

  <div class="header-pusher" [class.noOtherHeaders]="!hasOtherHeaders">
  </div>
  <div class="sub-header" *ngIf="isMobile && hasOtherHeaders">
    <app-options-header (onClickEvent)="clickRoute($event)" [platform]="headerDataItems.platform"
      [menuPrimeItems]="menuTreatmentLists"></app-options-header>
  </div>

</ng-container>

<ng-template #onlyLogo>
  <div class="header">
    <div class="container container-main">
      <a class="header__brand" routerLink="/">
        <img class="header__brand-logo" src="{{logoImages.desktopUrl}}" alt="Dental Departures"
          title="Dental Departures" />
      </a>
    </div>
  </div>
</ng-template>
<!--
Scan the DOM element to change the Call Tracking Metric phone number base on user's location.
It is added in the ts and html file to ensure the script work
-->
<script>
  window.__ctm_loaded = window.__ctm_loaded || [];
  window.__ctm_loaded.push(function() {
    __ctm.main.start(document.querySelector(".callus"));
  });
</script>
<script src="//4433.tctm.co/f.js" async></script>
