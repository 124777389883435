<div class="review-wrapper" *ngIf="!isReviewModal">
    <div class="row title-header">
        <div class="col-md-8 title-header-stars">
            <i class="fa fa-star"></i> <span class="rating-average">{{clinicReview.rating | number: '1.2-2'}}</span>
            <i class="fa fa-circle"></i>
            <span class="rating-reviews" i18n="@@clinicReviews">{{ clinicReview.reviews }} reviews </span>
        </div>
        <div class="col-md-4">
            <button pButton (click)="onClickWriteAReview.emit(true)" i18n="@@writeReview"
                    class="wb-100 p-button-outlined p-button-label-zero p-button p-button-secondary">
              Write a review
            </button><br />
        </div>
    </div>
    <div class="row summary-report" *ngIf="summmaryReport">
        <ng-container *ngFor="let s of summmaryReport; let i= index">
            <div class="col-12 col-md-5">
                <div class="row">
                    <div class="col-6 col-md-6">
                        <div class="label">{{ s.label }}</div>
                    </div>
                    <div class="col-6 col-md-6 progress-bar-wrapper">
                        <div class="progress-bar">
                            <p-progressBar [showValue]="false" [value]="s.percentageValue"
                                [style]="{ height: '5px', width: '90%'}"></p-progressBar>
                        </div>
                        <span>{{ s.value }}</span>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-2" *ngIf="i == 0 || ((i+1)%2)"></div>
        </ng-container>
    </div>
    <div class="mobile-reviews-card">
        <app-latest-reviews-card [isReviewArray]="true" [latestReview]="latestReviews"
            (onClickReviewMore)="onClickReviewMore($event)"
            (onClickShowMore)="onClickShowMore.emit(true)"></app-latest-reviews-card>
    </div>
    <div class="row desktop-review-only">
        <div class="col-12"><br /><br /></div>
        <div class="col-12" *ngIf="latestReviews">
            <div class="row">
                <ng-container *ngFor="let l of latestReviews; let i=index">
                    <div class="col-12 col-md-5">
                        <div class="reviewer review-space">
                            <div class="name">
                                {{ l.reviewer.name }}
                                <span>{{ l.date | date:'MMMM y'}} </span>
                            </div>
                            <p class="comments">
                                {{ l.comment }}
                            </p>
                            <p class="showMore" (click)="choosenReview(l)">Show more <i
                                    class="ri-arrow-right-s-line"></i>
                            </p>
                        </div>
                    </div>
                    <div class="col-12 col-md-2" *ngIf="i == 0 || ((i+1)%2)"></div>
                </ng-container>
            </div>
        </div>
        <div class="col-md-2">
            <br />
            <button pButton i18n="@@showMoreReviews"
                    class="wb-100 p-button-outlined p-button-label-zero p-button p-button-secondary"
                (click)="onClickShowMore.emit(true)">
                Show more reviews
            </button><br />
        </div>
        <div class="col"></div>
    </div>
</div>

<div class="review-wrapper-modal" *ngIf="isReviewModal">
    <div class="row title-header">
        <div class="col-md-6">
            <i class="fa fa-star"></i> <span class="rating-average">{{clinicReview.rating | number: '1.2-2'}}</span>
            <i class="fa fa-circle"></i>
            <span class="rating-reviews" i18n="@@clinicReviews">{{ clinicReview.reviews }} reviews </span>
            <br />
        </div>
        <div class="col-md-6">
        </div>
    </div>
    <div class="row" *ngIf="summmaryReport">
        <div class="col-12 col-md-5">
            <div *ngFor="let s of summmaryReport" class='summary-report-modal'>
                <div class="row">
                    <div class="col-6 col-md-6">
                        <div class="label">{{ s.label }}</div>
                    </div>
                    <div class="col-6 col-md-4 progress-bar-wrapper">
                        <div class="progress-bar">
                            <p-progressBar [showValue]="false" [value]="s.percentageValue"
                                [style]="{ height: '5px', width: '90%'}"></p-progressBar>
                        </div>
                        <span>{{ s.value }}</span>
                    </div>
                    <div class="col col-md-2"></div>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-7">
            <div *ngIf="latestReviews">
                <div class="row">
                    <div class="col-12" *ngFor="let l of latestReviews">
                        <div class="reviewer">
                            <div class="name reviewer">
                                {{ l.reviewer.name }}
                                <span class="start-wrapper">
                                    <app-star-review-input-core [ratingSelected]="l.averageScore" [editable]=false>
                                    </app-star-review-input-core>
                                </span>
                                <span>{{ l.date | date:'MMMM y'}} </span>
                            </div>
                            <div class="photos" >
                                <br/>
                                <div class="carousel" *ngIf="l.photos">
                                    <swiper-container customSwiper navigation="true" class="swiper-containers" fxFlex="auto" [config]="config"
                                    slidesPerView="3" spaceBetween="50">
                                        <swiper-slide *ngFor="let photo of l.photos">
                                            <div class="swiper-slide swiper-options-header" style="width: 104px; margin-right: 10px;">
                                                <div fxLayout="column">
                                                    <div class="wrapper-review-images" [ngStyle]="{'background-image': 'url(' + photo + '?width=104px' + ')'}"
                                                    (click)="openImageGallery(photo)">
                                                    </div>
                                                </div>
                                            </div>
                                        </swiper-slide>
                                    </swiper-container>
                                </div>
                            </div>
                            <div class="comments">
                                {{ l.comment }}
                            </div>
                        </div>
                        <br />
                    </div>
                </div>
                <div class="button-show-more">
                    <button pButton i18n="@@showMore"
                            class="wb-100 p-button-text p-button-label-zero p-button p-button-secondary"
                        (click)="showMore()">
                        Show more <i class="ri-arrow-down-s-line"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>


<p-dialog [(visible)]="currentReview && isModalVisible" [modal]="true" [showHeader]="false"
    class="custom-clinic-dialog custom-review-dialog" [position]="'center'">
    <div class="close-action"><i class="ri-close-fill" (click)="isModalVisible=false;"></i></div>
    <div class="custom-clinic-dialog-wrapper custom-review-wrapper" *ngIf="currentReview">
        <div class="close-action-pusher"></div>
        <div class="card card-reviewer-modal">
            <p class="reviewer">{{currentReview.reviewer.name}}
                <span class="start-wrapper">
                    <app-star-review-input-core [ratingSelected]="currentReview.averageScore" [editable]=false>
                    </app-star-review-input-core>
                </span>
            </p>
            <p class="date">{{ currentReview.date | date: 'dd yyyy'}}</p>
            <br />
            <div class="row">
                <div class="col-12 col-md-6">
                    <div *ngFor="let s of summmaryReportSpecific">
                        <div class="row">
                            <div class="col-6 col-md-6">
                                <div class="label">{{ s.label }}</div>
                            </div>
                            <div class="col-6 col-md-6 progress-bar-wrapper-solo">
                                <div class="progress-bar">
                                    <p-progressBar [showValue]="false" [value]="s.percentageValue"
                                        [style]="{ height: '5px', width: '90%'}"></p-progressBar>
                                </div>
                                <span>{{ s.value }}</span>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <p style="margin-top: 30px;"> {{ currentReview.comment}}</p>
                </div>
            </div>
            <br />

        </div>
    </div>
</p-dialog>
