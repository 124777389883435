import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// import { SwiperOptions } from 'swiper';

@Component({
    selector: 'app-promotions',
    templateUrl: './promotions.component.html',
    styleUrls: ['./promotions.component.scss']
})
export class PromotionsComponent implements OnInit {

    @Input() hasPromotionButton: boolean = true;
    @Input() title = $localize`:@@promotions:Promotions`;

    private _promotions: IPromotionLayout[] | any;
    @Input()
    set promotions(promotions: IPromotionLayout[] | any) {
        this._promotions = promotions;
        this.config = this.swiperConfig();
    }
    get promotions() {
        return this._promotions;
    }

    config!: any;
    disabled: boolean = false;
    constructor(private router: Router) { }

    ngOnInit(): void {

    }

    swiperConfig(): any {
        return {
            a11y: { enabled: true },
            updateOnWindowResize: true,
            direction: 'horizontal',
            slidesPerView: 4,
            keyboard: false,
            mousewheel: false,
            scrollbar: false,
            navigation: true,
            pagination: false,
            breakpoints: {
                120: {
                    slidesPerView: 1.2,
                    spaceBetween: 20
                },
                640: {
                    slidesPerView: 2,
                    spaceBetween: 20
                },
                1024: {
                    slidesPerView: 4,
                    spaceBetween: 40
                }
            }
        };
    }

    public onIndexChange(index: number): void { }
    public onSwiperEvent(event: string): void { }

    navigateToUrl(url?: any) {
        this.router.navigate([url]);
    }

}

export interface IPromotionLayout {
    code: string;
    image: string;
    short_description: string;
    title: string;
}
