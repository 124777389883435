<div class="share-profile-wrapper">
    <h1>{{title}}</h1>
    <div class="mobile-only">
        <app-clinic-preview-card [clinic]="clinic"></app-clinic-preview-card>
        <br />
    </div>
    <div class="share-items mobile-only copy-link-wrapper">
        <div>
            <button pButton class="wb-100 p-button-outlined p-button-label-zero p-button p-button-secondary copy-link"
                (click)="copyClinicUrl()"> <i class="ri-check-line" *ngIf="isCopied"></i> <i class="far fa-copy"
                    *ngIf="!isCopied"></i> {{ !isCopied ? 'Copy link': 'Copied'}} </button>
        </div>
    </div>
    <div class="share-items">
        <div class="desktop-only">
            <button pButton class="wb-100 p-button-outlined p-button-label-zero p-button p-button-secondary copy-link"
                (click)="copyClinicUrl()"> <i class="ri-check-line" *ngIf="isCopied"></i> <i class="far fa-copy"
                    *ngIf="!isCopied"></i> {{ !isCopied ? 'Copy link': 'Copied'}} </button>
        </div>
        <div>
            <a href="mailto:?subject=Dental Departures&body={{currentUrl}}">
                <button pButton class="wb-100 p-button-outlined p-button-label-zero p-button p-button-secondary"><i
                        class="ri-mail-line"></i> Email</button>
            </a>
        </div>
        <div>
            <a href="sms:?&body={{currentUrl}}">
                <button pButton class="wb-100 p-button-outlined p-button-label-zero p-button p-button-secondary"><i
                        class="ri-mail-line"></i> Messages</button>
            </a>
        </div>
        <div>
            <a href="https://api.whatsapp.com/send?phone=6625088449" class="connect__link" target="_blank"><button
                    pButton class="wb-100 p-button-outlined p-button-label-zero p-button p-button-secondary"><i
                        class="ri-whatsapp-fill"></i> WhatsApp</button></a>
        </div>
        <div>
            <a target="_blank" href="https://www.facebook.com/messages/t/158009174215133/">
                <button pButton class="wb-100 p-button-outlined p-button-label-zero p-button p-button-secondary"><i
                        class="ri-messenger-fill"></i> Messenger</button></a>
        </div>
        <div>
            <a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u={{currentUrl}}&amp;src=sdkpreparse">
                <button pButton class="wb-100 p-button-outlined p-button-label-zero p-button p-button-secondary"><i
                        class="ri-facebook-circle-fill"></i> Facebook</button></a>
        </div>
        <div>
            <a target="_blank" href="https://twitter.com/intent/tweet?text={{currentUrl}}&hashtags=#dentalDepartures"
                target="_blank"><button pButton
                    class="wb-100 p-button-outlined p-button-label-zero p-button p-button-secondary"><i
                        class="ri-twitter-fill"></i>Twitter </button></a>
        </div>
        <div *ngIf="isEmbed">
            <a target="_blank" href="https://twitter.com/intent/tweet?text={{currentUrl}}&hashtags=#dentalDepartures"
                target="_blank">
                <button pButton class="wb-100 p-button-outlined p-button-label-zero p-button p-button-secondary"><i
                        class="ri-window-fill"></i>Embed </button></a>
        </div>
    </div>
</div>