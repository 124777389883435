import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output, PLATFORM_ID
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UserLoginResponse, UserResponse, UserSignIn } from '@lib/models/user.model';
import { ApiErrorResponse } from '@lib/models/api-response.model';
import { UserService } from '@lib/features/account/user.service';
import { FORM_CONSTANTS } from '@lib/shared/components/form/constants';
import { AppsIntegrationService } from '@lib/auth/apps-integration.service';
import { AuthService } from '@lib/auth/auth.service';
import { Subject, Subscription } from 'rxjs';
import { AccountService } from '@lib/features/account/account.service';
import { AcccountLoginType, AccountAction, RegisterState, ShowState } from '@lib/models/account.models';
import { UserActivities, UserActivityArg } from '@lib/constants/user-activities';
import { AppleUserInfo, GoogleSignInResponse } from '@lib/auth/auth.model';
import { AuthAppleService } from '@lib/auth/auth.apple.service';
import { takeUntil } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

    @Input()
    isInModalMode: boolean = false;

    @Output() userActivity: EventEmitter<UserActivityArg> = new EventEmitter<UserActivityArg>();

    source = '';
    loginForm!: UntypedFormGroup;

    loginSubscription!: Subscription;
    fetchUserSubscription!: Subscription;
    emailPattern = FORM_CONSTANTS.emailPattern;
    processing = false;
    showPassword!: boolean;
    modalAccountAction = AccountAction;
    private hasSubmitted = false;
    loginError!: string;

    onClickAppleEvent$ = new Subject();

    constructor(
        private authService: AuthService,
        private userService: UserService,
        private appsIntegrationService: AppsIntegrationService,
        private accountService: AccountService,
        private authAppleService: AuthAppleService,
        @Inject('env') private env: any,
        private fb: UntypedFormBuilder,
        @Inject(PLATFORM_ID) private platformId: any,
    ) {
        // Initiate the form
        this.loginForm = fb.group({
            email: new UntypedFormControl('', [Validators.required,
            Validators.maxLength(256), Validators.pattern(this.emailPattern)]),
            password: new UntypedFormControl('', Validators.required)
        });
    }


    ngOnInit() {
        this.accountService.toggleRegisterState(RegisterState.IDLE);
        setTimeout(() => {
            this.authService.initializeGoogleAccountsId();

        }, 1000);
        this.emitUserActivity(
            {
                activePage: UserActivities.Login.Name,
                activeActivity: UserActivities.Login.Landed
            }
        );
        this.signInWithGoogleV2();
        this.source = '';

        /**
         * Subscribe Click Event for Apple ID Sign in
         *  */
        this.authAppleService.$appleSignInEvent
            .pipe(takeUntil(this.onClickAppleEvent$))
            .subscribe(async (data: any) => {
                if (data && data === 'LOGIN') {
                    this.authAppleService.setSignInEvent(null);
                    await this.signInWithApple();
                }
            });

    }

    toggleShowPassword() {
        this.showPassword = !this.showPassword;
    }

    onSubmit() {
        const formValue = this.loginForm.value as UserSignIn;
        this.signIn(formValue);
    }

    signInWithGoogleV2() {
        this.authService.signInWithGoogleResponse.subscribe((res: GoogleSignInResponse | null) => {
            this.loginError = '';
            if (this.accountService.accountAction === AccountAction.LOGIN && res && !this.processing) {
                // perform normal login
                const user: UserSignIn = {
                  email: res.email,
                  provider: 'GOOGLE',
                  user_id: res.sub,
                  name: res.name,
                  photoUrl: res.picture,
                  firstName: res.given_name,
                  lastName: res.family_name,
                };
                this.signIn(user);
            }
        });
    }


    private signIn(user: UserSignIn) {
        this.hasSubmitted = true;
        this.processing = true;
        this.loginError = '';
        this.loginSubscription = this.authService.login(user).subscribe(
            (res: UserLoginResponse) => {
                if (res.success) {
                    this.emitUserActivity(
                        {
                            activePage: UserActivities.Login.Name,
                            activeActivity: UserActivities.Login.SubmittedLogin
                        }
                    );
                    // Store entire UserLogin object into localStorage and redirect user to edit profile page
                    this.authService.storeUserAuthentication(res.data);
                    this.appsIntegrationService.setDirectAuth(true);

                    this.fetchUserSubscription = this.userService
                        .fetchUserProfile()
                        .subscribe(
                            (userRes: UserResponse) => {
                                if (userRes.success) {
                                    console.log(user);
                                    let userLoginType = AcccountLoginType.EMAIL;

                                    if(user.provider) {
                                        switch(user.provider) {
                                          case 'GOOGLE' : userLoginType = AcccountLoginType.GOOGLE; break;
                                          case 'APPLE': userLoginType = AcccountLoginType.APPLE; break;
                                          default: break;
                                        }
                                    }

                                    this.accountService.toggleShowState(ShowState.NO_SHOW);
                                    let currentUser = ({...userRes.data as any, ...{login_type: userLoginType}})
                                    this.authService.setCurrentUserValue(currentUser);
                                    this.authService.resetGoogleResponse();
                                    this.updateAccountState(null);
                                    // location.reload();
                                }
                            },
                            (err: ApiErrorResponse) => {
                                this.loginError = $localize`:@@loginError:An error occurred`;
                            },
                            () => { }
                        );
                }
            },
            (err: ApiErrorResponse) => {
                this.loginError = err?.error.error as any || $localize`:@@loginError:An error occurred`;
                this.emitUserActivity(
                    {
                        activePage: UserActivities.Login.Name,
                        activeActivity: UserActivities.Login.ErrorSubmitting
                    }
                );
                this.processing = false;
            },
            () => {
                this.processing = false;
            }
        );
    }

    emitUserActivity(userActivityArg: UserActivityArg) {
        this.userActivity.emit(userActivityArg);
    }


    ngOnDestroy() {
        if (isPlatformBrowser(this.platformId) && !this.hasSubmitted) {
            this.emitUserActivity(
                {
                    activePage: UserActivities.Login.Name,
                    activeActivity: UserActivities.Login.NotSubmittedLogin
                }
            );
        } else {
            this.hasSubmitted = false;
        }
        this.onClickAppleEvent$.next();
        this.onClickAppleEvent$.complete();
    }

    toggleActionState(state: AccountAction) {
        this.accountService.toggleAccountAction(state);
    }

    async signInWithApple() {
        const appleUserInfo: AppleUserInfo | null = await this.authAppleService.appleSignIn();
        if (appleUserInfo) {
            const user: UserSignIn = {
              email: appleUserInfo.email,
              provider: 'APPLE',
              user_id: appleUserInfo.sub,
              name: appleUserInfo.name ?? '',
            };
            this.signIn(user);
        }
    }


    updateAccountState(type: any) {
        if (this.isInModalMode) {
            this.accountService.updateCurrentAccountStateModal(type);
        } else {
            this.accountService.updateCurrentAccountState(type);
        }
    }

}
