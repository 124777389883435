import { AfterViewInit, Directive, ElementRef, HostListener, Inject, Input, PLATFORM_ID } from '@angular/core';
import { SwiperContainer } from 'swiper/element';
import SwiperOptions from 'swiper';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Directive({
  selector: '[customSwiper]'
})
export class CustomSwiperDirective implements AfterViewInit {


  constructor(
      @Inject(PLATFORM_ID) private platformId: object,
      @Inject(DOCUMENT) private document: Document,
      private el: ElementRef<SwiperContainer>,
    ) { }

  @Input() config?: Partial<SwiperOptions>;

  ngAfterViewInit(): void {
    this.initSwiper();
  }

  initSwiper() {
    /** check if config is undefined */
    if (this.config) {
      /** Fix exception of swiper not handling properly false data from these keys
       *  Not able to find it on the dom and recognizing that these keys are available in the dom does it returns an exception
       */
      if ('scrollbar' in this.config && !this.config['scrollbar'])
        delete this.config.scrollbar;
      if ('pagination' in this.config && !this.config['pagination'])
        delete this.config.pagination;
      /** End patch */


      /** Accessing Shadow Dom style since current swiper uses shadow dom root already */
      const shadowRoot = this.el.nativeElement.shadowRoot;
      if ((shadowRoot !== null) && (shadowRoot !== undefined)) {
        const style = this.document.createElement('style');
        style.textContent = this.styleCssButtons();
        shadowRoot?.appendChild(style);
      }

      /** Assign new config patch to the element */
      Object.assign(this.el.nativeElement, this.config);

      try {
        /** Use initialize based on SwiperContainer instead of init from SwiperJS */
        this.el.nativeElement.initialize();
      } catch (e) {
        // console.log(e)
      }
    }
  }

  /** Copying Style from the Custom.css since the current :host ::ng-deep :root is not applying globally in shadow dom doesn't work */
  styleCssButtons() {
    return `
      svg { display: none; }
      .max-w-157 {  max-width: 157px!important; }
      .swiper-container {
          z-index: 0;
          .resize-card-ws {
              width: 131px!important;
              max-width: 131px!important;
          }
          .resize-card-wm {
              width: 155px!important;
              max-width: 155px!important;
          }
          .resize-card-wl {
              width: 158px!important;
              max-width: 158px!important;
          }
      }
      .swiper-button-next, .swiper-button-prev {
          width: 2rem;
          height: 2rem;
          color: #71717a;
          border: 0 none;
          background: transparent;
          border-radius: 50%;
          transition: none;
          margin: 0.5rem;
          position: absolute;
          color: black;
          background-color: white;
          border: 1px solid var(--gray-200);
          opacity: 0;
          animation: fadeOut 0.5s;
          animation-fill-mode: forwards;
      }
      .swiper-horizontal:hover .swiper-button-next, .swiper-horizontal:hover .swiper-button-prev {
          animation: fadeIn 0.5s;
          animation-fill-mode: forwards;
    }
      .swiper-button-next, .swiper-button-prev {
          width: 2rem!important;
          height: 2rem!important;
          &::after {
              font-family: "remixicon" !important;
              color: #71717a;
              font-size: 13px!important;
              font-weight: 900;
        }
    }
      .swiper-button-next::after {
          content: "\\ea6e" !important;
    }
      .swiper-button-prev::after {
          content: "\\ea64" !important;
    }
      .swiper-button-disabled, .swiper-button-prev[hidden], .swiper-button-next[hidden]{display: none!important;}
      .swiper-pagination-handle { margin: 0px;
          .swiper-pagination-bullet-first, .swiper-pagination-bullet-last { border: 0px!important; }
          .swiper-pagination-bullet {
              width: 5px;
              height: 5px; }
          .swiper-pagination-bullet-active {
              background: white; }
      }
      @keyframes fadeIn {
          from { opacity: 0;}
          to { opacity: 1; }
      }
      @keyframes fadeOut {
          to { opacity: 0; }
          from { opacity: 1; }
      }
      .swiper-button-prev:hover, .swiper-button-next:hover{
          color: #18181b;
          border-color: transparent;
          background: #f4f4f5;
      }
      .swiper-button-prev:focus, .swiper-button-next:focus{
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 1px var(--primary-hover-color);
      }
    `;
  }

}
