import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IClinicPhoto } from '@lib/modules/clinic/clinic.models';
// import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image-gallery.component.scss']
})
export class ImageGalleryComponent implements OnInit {

    @Input()
    isVisible: boolean = false;

    @Input()
    allowFullScreen: boolean = true;


    private _images: IClinicPhoto[] = [];
    @Input()
    set images(images: IClinicPhoto[]) {
        this._images = images;
        if(images && images.length > 0) {
            this.config = this.swiperConfig();
        }
    }
    get images() {
        return this._images;
    }

    private _activeIndex: number | any;
    @Input()
    set activeIndex(activeIndex: number) {
        this._activeIndex = activeIndex;
        this.config = this.swiperConfig();
    }
    get activeIndex() {
        return this._activeIndex;
    }

    @Input()
    showThumbnailNavigators: boolean = false;

    @Input()
    autoPlay: boolean = false;

    @Input()
    numVisible: number = 1;

    @Input()
    thumbnailsPosition: string = 'bottom';

    @Input()
    verticalThumbnailViewPortHeight: string = '20px';

    @Input()
    showThumbnails: boolean = false;

    @Input()
    showIndicators: boolean = false;

    @Input()
    showItemNavigators: boolean = true;

    @Input()
    containerStyle: any = {'max-width': '100%'};

    @Output()
    onChangeVisible: EventEmitter<boolean> = new EventEmitter<boolean>();

    config!: any;

    constructor() { }

    ngOnInit(): void {
    }


    swiperConfig(): any {
        return {
            a11y: { enabled: true },
            updateOnWindowResize: true,
            direction: 'horizontal',
            slidesPerView: 1,
            keyboard: false,
            mousewheel: false,
            scrollbar: false,
            navigation: true,
            pagination: false,
            initialSlide: this.activeIndex ? this.activeIndex : 0,
            500: {
                slidesPerView: 1,
                spaceBetween: 10,
            },
            1024: {
                slidesPerView: 1,
                spaceBetween: 10,
            }
        };
    }

    isVisibleChange(event: any) {
        this.onChangeVisible.emit(event);
    }
}
